import React, { FC } from 'react';
import styles from './acknowledgement.module.scss';
import Loading from "../../components/Loading/Loading";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {Viewer, Worker} from "@react-pdf-viewer/core";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";

interface TCompareModalProps {
    generatedFileUrl: string;
    processedFileUrl: string;
    loading: boolean;
    closeModal: () => void;
    orderNumber: string;
    onConfirmProcessedFile: (orderNumber: string) => void;
    confirmProcessedFileLoading: boolean;
}

const CompareModal: FC<TCompareModalProps> = ({
generatedFileUrl,
processedFileUrl,
loading,
closeModal,
orderNumber,
onConfirmProcessedFile,
confirmProcessedFileLoading,
}) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className={styles.compareModalOverlay}>
            <div className={styles.modal}>
                <button className={styles.closeBtn} onClick={closeModal}>
                    <i className="bi bi-x" />
                </button>
                {
                    loading ? (
                        <div className={styles.loadingWrapper}>
                            <Loading />
                        </div>
                    ) : (
                        <>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
                                <div className={styles.previewCol}>
                                    <p className={styles.colLabel}>Processed file</p>
                                    <div className={styles.frameWrapper}>
                                        <Viewer fileUrl={processedFileUrl} plugins={[defaultLayoutPluginInstance]} />
                                    </div>
                                </div>
                                <div className={styles.previewCol}>
                                    <p className={styles.colLabel}>Generated file</p>
                                    <div className={styles.frameWrapper}>
                                        <Viewer fileUrl={generatedFileUrl} plugins={[defaultLayoutPluginInstance]}/>
                                    </div>
                                    <div className={styles.acceptRow}>
                                        <ButtonPrimary
                                            label="Accept"
                                            onClick={() => onConfirmProcessedFile(orderNumber)}
                                            disabled={confirmProcessedFileLoading}
                                        />
                                    </div>
                                </div>
                            </Worker>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default CompareModal;
