import React, { FC } from 'react';
import * as Yup from "yup";
import { InputTextarea } from 'primereact/inputtextarea';
import {Controller, useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from "../shared.module.scss";
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {SubmitHandler} from "react-hook-form";
import {TLateElectionFormValues} from "./types";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";

const validationSchema = Yup.object().shape({
    justificationText: Yup.string()
        .required('Justification is required')
        .min(10, 'Justification must be at least 10 characters long')
        .max(500, 'Justification must not exceed 500 characters'),
});

interface TLateElectionFormProps {
    onSubmit: SubmitHandler<TLateElectionFormValues>;
    initialValues: TLateElectionFormValues;
    onPrev: () => void;
}
const LateElectionForm:FC<TLateElectionFormProps> = ({
onSubmit,
initialValues,
onPrev,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TLateElectionFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
    });

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fieldsHolder}>
                <div className={styles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <LabelValue
                            label="Justification message"
                            required={true}
                            fieldName="justificationText"
                            comment=""
                        >
                            <Controller
                                name="justificationText"
                                control={control}
                                render={({field}) => (
                                    <InputTextarea
                                        {...field}
                                        id="justificationText"
                                        style={{ width: '100%' }}
                                        className={`p-inputtext ${errors.justificationText ? 'p-invalid' : ''}`}
                                        rows={5}
                                        cols={30}
                                        placeholder="Enter message"
                                    />
                                )}
                            />
                            {errors.justificationText && (
                                <small className="p-error">{errors.justificationText.message}</small>
                            )}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={styles.actionButtonHolder}>
                <div className={styles.actionButtonsGrid}>
                    <ButtonPrimary
                        styleType="secondaryOutline"
                        style={{width: '100%'}}
                        onClick={onPrev}
                        type="button"
                        label="Back"
                    />
                    <ButtonPrimary
                        style={{width: '100%'}}
                        type="submit"
                        label="Next"
                    />
                </div>
            </div>
        </form>
    )
}

export default LateElectionForm;
