import { classNames } from 'primereact/utils';
import React from 'react';

import styles from '../styles.module.scss';

const FileLoadingHighlight = () => {
  return (
    <div className={styles.fileLoadingHighlight}>
      <div className={styles.card}>
        <div
          className={classNames(styles.titlePlaceholder, styles.placeholder)}
        />
        {[...new Array(25)].map((_, index) => {
          return (
            <div
              key={index}
              className={classNames(
                {
                  [styles.textPlaceholder]: true,
                },
                styles.placeholder,
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FileLoadingHighlight;
