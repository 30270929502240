import React, { FC } from 'react';
import { Password } from 'primereact/password';
import styles from "../authentication.module.scss";
import {Controller, useForm} from "react-hook-form";
import FieldWrapper from "../../../uiComponents/FieldWrapper/FieldWrapper";
import {InputText} from "primereact/inputtext";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {NavLink} from "react-router-dom";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMutation} from "@tanstack/react-query";
import {requests} from "../../../services/http-common";
import {AxiosError} from "axios";
import googleIcon from '../../../assets/icons/google-icon.png';
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";
import {useToast} from "../../../hooks/useToast";

type TFieldName = 'email' | 'password';
export type TSignInValues = Record<TFieldName, string>;
type TFieldConfig = {
    fieldName: TFieldName;
    label: string;
    placeholder: string;
    type: 'text' | 'password';
};

const fieldsConfig: TFieldConfig[] = [
    {
        fieldName: 'email',
        label: 'Email',
        placeholder: 'example@gmail.com',
        type: 'text',
    },
    {
        fieldName: 'password',
        label: 'Password',
        placeholder: '',
        type: 'password',
    }
];

interface TSignInFormProps {
    isLoading: boolean;
    onSubmit: (values: TSignInValues) => void;
    email?: string;
    formError: string;
    clearFormError: () => void;
}

const SignInForm:FC<TSignInFormProps> = ({
 isLoading,
 onSubmit,
 email,
 formError,
 clearFormError,
}) => {
    const { show } = useToast();
    const formSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });
    const form = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            email: email || '',
            password: '',
        },
    });
    const { control, handleSubmit } = form;
    const formErrors = form.formState.errors;

    const onChangeAnyField = () => {
        if (formError) {
            clearFormError();
        }
    }

    const redirectToGooglePage = useMutation({
        mutationFn: async () => {
            return await requests.get(`/api/auth/google`);
        },
        onSuccess: (res) => {
            window.open(res?.url, '_self');
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: redirectToGooglePage.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const onSingleSignIn = () => {
        redirectToGooglePage.mutate();
    }
    return (
        <form className={styles.authForm} onSubmit={handleSubmit(onSubmit)}>
            <p className={styles.headTxt}>Sign In</p>
            <div className={styles.fields}>
                {fieldsConfig.map((fieldConfig: TFieldConfig) => {
                    const {fieldName, placeholder, label, type} = fieldConfig;
                    const fieldError = formErrors[fieldName]?.message;
                    if (type === 'password') {
                        return (
                            <div className={styles.fieldItem} key={fieldName}>
                                <Controller
                                    name={fieldName as TFieldName}
                                    control={control}
                                    render={({field}) => {
                                        return (
                                            <FieldWrapper
                                                className={styles.fieldWide}
                                                label={label}
                                                errorMessage={fieldError}
                                                required
                                            >
                                                <Password
                                                    className={styles.fieldWide}
                                                    value={field.value as string}
                                                    name={fieldName}
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onChangeAnyField();
                                                        field.onChange(e.target.value);
                                                    }}
                                                    autoComplete="new-password"
                                                    feedback={false}
                                                    toggleMask
                                                />
                                            </FieldWrapper>
                                        );
                                    }}
                                />
                            </div>
                        );
                    }
                    return (
                        <div className={styles.fieldItem} key={fieldName}>
                            <Controller
                                name={fieldName as TFieldName}
                                control={control}
                                render={({field}) => {
                                    return (
                                        <FieldWrapper
                                            className={styles.fieldWide}
                                            label={label}
                                            errorMessage={fieldError}
                                            required
                                        >
                                            <InputText
                                                className={styles.fieldWide}
                                                value={field.value as string}
                                                name={fieldName}
                                                placeholder={placeholder}
                                                onChange={(e) => {
                                                    onChangeAnyField();
                                                    field.onChange(e.target.value);
                                                }}
                                            />
                                        </FieldWrapper>
                                    );
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={styles.rememberRow}>
                <div className={styles.checkboxHolder}></div>
                <NavLink to="/recover-password" className={styles.fp}>
                    Forgot password
                </NavLink>
            </div>
            {
                formError && (
                    <div className={styles.errorRow}>{formError}</div>
                )
            }
            <ButtonPrimary
                label="Sign in"
                className={styles.actionBtn}
                type="submit"
                disabled={isLoading}
            />
            <button type="button" onClick={onSingleSignIn} className={styles.googleBtn}>
                <span>Sign in with Google</span>
                <img src={googleIcon} alt="" />
            </button>
            <div className={styles.haveAccountRow}>
                <p className={styles.label}>Don’t have an account?</p>
                <NavLink to="/sign-up" className={styles.link}>
                    Sign up
                </NavLink>
            </div>
        </form>
    )
}

export default SignInForm;
