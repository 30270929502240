import { useQuery } from '@tanstack/react-query';
import React, {FC, useState} from 'react';
import {useLocation, NavLink, useNavigate} from 'react-router-dom';

import successImage from '../../assets/images/check-circle.svg';
import exclamationIcon from '../../assets/images/warning.png';
import Loading from '../../components/Loading/Loading';
import { requests } from '../../services/http-common';

import styles from './styles.module.scss';
import { stepsKeys } from './types';
import StepsControl from "./components/StepsControl/StepsControl";
import SingIn from "../../pages/Authentication/SignIn";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import SignUp from "../../pages/Authentication/SignUp";

interface TDocGenerationSuccessProps {
  userLogIn: () => void;
  loggedIn: boolean;
}

const DocGenerationSuccess: FC<TDocGenerationSuccessProps> = ({ userLogIn, loggedIn }) => {
  const [accountCreated, setAccountCreated] = useState<boolean>(false);
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const orderNumber = urlParams.get('orderNumber');
  const navigate = useNavigate();

  const { data: docData, isLoading: docDataLoading } = useQuery({
    queryKey: ['document-filing-info'],
    queryFn: async () => {
      return requests.get(`/api/public/file83b/${orderNumber}`);
    },
    gcTime: 0,
    refetchOnWindowFocus: true,
    refetchInterval: 10000,
  });
  const isSigned = Boolean(docData?.statuses?.FULLY_SIGNED);
  const hasSpouse = docData?.hasSpouse;
  if (docDataLoading || !docData) {
    return (
        <div style={{ display: 'flex' }}>
          <Loading horizontalSpacing={300} />
        </div>
    );
  }

  const handleSignInRedirect = () => {
    navigate('/filing?companyId=3');
  }

  const onSignedUpSuccess = () => {
    setAccountCreated(true);
  }

  return (
      <div className={styles.docGeneration}>
        <div className={styles.docGenerationSuccessBody}>
          <StepsControl
              step={stepsKeys[isSigned ? 'confirmation' : 'signature']}
              isPublic
              {...(isSigned ? { isFLowCompleted: true } : {})}
          />
          <div className={styles.successBody}>
            {isSigned ? (
                <div className={styles.quickLoginFormWrapper}>
                  <div className={styles.successInfos}>
                    <img src={successImage} alt="" className={styles.successIcon}/>
                    <p className={styles.successText_1}>
                      Your order is confirmed
                    </p>
                    {orderNumber && (
                        <p className={styles.successText_2}>
                          Order number:<span>{` ${orderNumber}`}</span>
                        </p>
                    )}
                    <NavLink
                        type="button"
                        className={styles.trackOrder}
                        to={
                          orderNumber
                              ? `/search83b?orderNumber=${orderNumber}`
                              : `/search83b`
                        }
                    >
                      Track your order
                    </NavLink>
                  </div>
                  {
                    loggedIn ? (
                        <div>
                          <ButtonPrimary
                              label="View filings"
                              onClick={handleSignInRedirect}
                              className={styles.goToFiling}
                          />
                        </div>
                    ) : (
                        <>
                          {
                            accountCreated ? (
                                <SingIn userLogIn={userLogIn} isQuickUse />
                            ) : (
                                <SignUp onSignedUpSuccess={onSignedUpSuccess} isQuickUse />
                            )
                          }
                        </>
                    )
                  }
                </div>
            ) : (
                <>
                  <img src={exclamationIcon} alt="" style={{ width: '200px' }} />
                  <p className={styles.text_1}>
                    {
                      hasSpouse ?
                          'You and your spouse need to sign your document to complete your order.' :
                          'You need to sign your document to complete your order.'
                    }
                  </p>
                  <p className={styles.text_3} style={{ margin: '24px 0' }}>
                    {
                      `${hasSpouse ? 'You and your spouse will be' : 'You’ll be'} 
                      receiving an email from signwelldocs@signwell.com ("Team Corpora via Signwell") to electronically sign your document.`
                    }
                  </p>
                  <ol className={styles.listWrapper}>
                    <li>Access your document</li>
                    <li>Review and sign the cover letter and 83(b) election</li>
                    <li>Click “Accept & Finish”</li>
                  </ol>
                  <p className={styles.text_3} style={{ marginTop: '24px' }}>
                    {`You will automatically be redirected to the order confirmation
                    page ${hasSpouse ? 'once you and your spouse sign.' : 'once you sign.'} `}
                    <span style={{ fontWeight: 900, textDecoration: 'underline' }}>
                      {
                        hasSpouse ? 'Your order will not be placed until you and your spouse both sign.' : ''
                      }
                    </span>
                  </p>
                </>
            )}
          </div>
        </div>
      </div>
  );
};

export default DocGenerationSuccess;
