import { classNames } from 'primereact/utils';
import React, { FC, ReactNode } from 'react';

import sharedStyles from '../../assets/sharedStyles.module.scss';

interface TTdElementProps {
  value?: string;
  children?: ReactNode;
  className?: string;
  dataHolderClassName?: string;
  labelClassName?: string;
  isRowActive?: boolean;
  clickHandler?: () => void;
  colSpan?: number;
  noWrap?: boolean;
  noBorder?: boolean;
}
const TdElement: FC<TTdElementProps> = ({
  value,
  children,
  className,
  dataHolderClassName,
  labelClassName,
  isRowActive,
  clickHandler,
  colSpan,
  noWrap,
  noBorder,
}) => {
  return (
    <td
      className={classNames(sharedStyles.tableData, className, {
        [sharedStyles.rowActive]: isRowActive,
        [sharedStyles.clickable]: Boolean(clickHandler),
        [sharedStyles.noBorder]: noBorder,
      })}
      onClick={clickHandler}
      colSpan={colSpan}
    >
      <div
        className={classNames(sharedStyles.tableDataRow, dataHolderClassName)}
      >
        {children ? (
          children
        ) : (
          <p
            className={classNames(sharedStyles.dataLabel, labelClassName, {
              [sharedStyles.noWrap]: noWrap,
            })}
          >
            {value || '-'}
          </p>
        )}
      </div>
    </td>
  );
};

export default TdElement;
