import React, { FC } from 'react';
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";
import {InputText} from "primereact/inputtext";
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import styles from "../shared.module.scss";
import {TSignerInformationFormValues} from "./types";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Title is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
});

interface TSignerInformationFormProps {
    onSubmit: SubmitHandler<TSignerInformationFormValues>;
    initialValues: TSignerInformationFormValues;
    onPrev: () => void;
}
const SignerInformationForm: FC<TSignerInformationFormProps> = ({ onSubmit, initialValues, onPrev }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TSignerInformationFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues || {
            name: '',
            title: '',
            email: '',
        }
    });
    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fieldsHolder}>
                <div className={styles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <LabelValue
                            label="Name"
                            required={true}
                            fieldName="name"
                            comment=""
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.name ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.name && <small className="p-error">{errors.name.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Title"
                            required={true}
                            fieldName="title"
                            comment=""
                        >
                            <Controller
                                name="title"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.title ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.title && <small className="p-error">{errors.title.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Email"
                            required={true}
                            fieldName="email"
                            comment=""
                        >
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.email ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.email && <small className="p-error">{errors.email.message}</small>}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={styles.actionButtonHolder}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={onPrev}
                    type="button"
                    label="Back"
                />
                <ButtonPrimary
                    type="submit"
                    label="Next"
                />
            </div>
        </form>
    )
}

export default SignerInformationForm;
