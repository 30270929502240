import { DocumentStatus, FilterQueryParams } from "./types";

export const documentQueryKeys = {
  documents: (params: FilterQueryParams) =>
    [{ scope: "documents", entity: "search", params }] as const,
  generatedPDFPreview: (orderNumber: string) =>
    [
      { scope: "document-details", entity: "file-preview", orderNumber },
    ] as const,
  generatedPDFDetails: (orderNumber: string) =>
    [
      {
        scope: "document-details",
        entity: "file-details",
        orderNumber,
      },
    ] as const,
  filePreview: ({
    orderNumber,
    docStatus,
  }: {
    orderNumber: string;
    docStatus: DocumentStatus;
  }) => [
    {
      scope: "document",
      entity: "file-preview",
      orderNumber,
      docStatus,
    },
  ],
};
