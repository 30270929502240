import React, {FC, useEffect, useState} from 'react';
import styles from '../questionnaire.module.scss';
import {Checkbox} from "primereact/checkbox";
import {Nullable} from "primereact/ts-helpers";

const message = 'Unfortunately, we currently do not support S corporation elections with the tax year that you have selected.';
interface TQuestion1Props {
    setQuestionState: (questionKey: string, value: number | string) => void;
    initialValue: Nullable<number>;
}
const Question1:FC<TQuestion1Props> = ({ setQuestionState, initialValue }) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(initialValue || 0);
    const [blockerMessage, setBlockerMessage] = useState('');
    const isQuestion_1_selected = selectedIndex === 1;
    const isQuestion_2_selected = selectedIndex === 2;
    const isQuestion_3_selected = selectedIndex === 3;
    const isQuestion_4_selected = selectedIndex === 4;

    const onCheck = (checkboxIndex: number) => {
        setSelectedIndex(checkboxIndex);
        setQuestionState('question_1', checkboxIndex);
        if (checkboxIndex !== 2 && checkboxIndex !== 4) {
            setBlockerMessage('');
        }
    }

    useEffect(() => {
        if (isQuestion_2_selected || isQuestion_4_selected) {
            setBlockerMessage(message);
        }
    }, [isQuestion_2_selected, isQuestion_4_selected]);
    return (
        <div className={styles.questionFrame}>
            <p className={styles.question}>
                Question 1: What is the selected tax year of the S corporation?
            </p>
            <div className={styles.options}>
                <div className={styles.optionRow}>
                    <Checkbox
                        inputId="input_1"
                        name="terms"
                        onChange={() => onCheck(1)}
                        checked={isQuestion_1_selected}
                    />
                    <label className={styles.label} htmlFor="input_1">
                        Calendar year
                    </label>
                </div>
                <div className={styles.optionRow}>
                    <Checkbox
                        inputId="input_2"
                        name="terms"
                        onChange={() => onCheck(2)}
                        checked={isQuestion_2_selected}
                    />
                    <label className={styles.label} htmlFor="input_2">
                        Fiscal year ending (month and day)
                    </label>
                </div>
                <div className={styles.optionRow}>
                    <Checkbox
                        inputId="input_3"
                        name="terms"
                        onChange={() => onCheck(3)}
                        checked={isQuestion_3_selected}
                    />
                    <label className={styles.label} htmlFor="input_3">
                        52-53-week year ending with reference to the month of December
                    </label>
                </div>
                <div className={styles.optionRow}>
                    <Checkbox
                        inputId="input_4"
                        name="terms"
                        onChange={() => onCheck(4)}
                        checked={isQuestion_4_selected}
                    />
                    <label className={styles.label} htmlFor="input_4">
                        52-53-week year ending with reference to the month of
                    </label>
                </div>
            </div>
            {
                Boolean(blockerMessage) && (
                    <p className={styles.blockerMessage}>{blockerMessage}</p>
                )
            }
            <div className={styles.bottomControl}>
            </div>
        </div>
    )
}

export default Question1;