import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import PrivateFile83b from "./pages/PrivateFile83b/PrivateFile83b";

interface TFile83bDashboardProps {
    companyId: number;
    templateCode: string;
    templateSpouseCode?: string;
    email: string;
    fullName: string;
    userId: number;
    paymentEnabled: boolean;
    filingCreationEnabled: boolean;
    isEveryCompany: boolean;
}
const File83bDashboard: FC<TFile83bDashboardProps> = ({
  companyId,
  templateCode,
  templateSpouseCode,
  email,
  fullName,
  userId,
  paymentEnabled,
  filingCreationEnabled,
  isEveryCompany,
}) => {
  return (
      <Routes>
          <Route path="/" element={
              <Dashboard
                companyId={companyId}
                userId={userId}
                filingCreationEnabled={filingCreationEnabled}
              />
          } />
          <Route path="/file-83b" element={
              <PrivateFile83b
                  companyId={companyId}
                  templateCode={templateCode}
                  templateSpouseCode={templateSpouseCode}
                  email={email}
                  fullName={fullName}
                  paymentEnabled={paymentEnabled}
                  isEveryCompany={isEveryCompany}
                  filingCreationEnabled={filingCreationEnabled}
              />
            }
          />
      </Routes>
  );
};

export default File83bDashboard;
