import {useState} from 'react';

const useBulkSelect = () => {
    const [bulkSelectMode, setBulkSelectMode] = useState<boolean>(false);
    const [bulkSelectedIds, updateSelectedIds] = useState<number[]>([]);

    const onBulkSelect = (id: number) => {
        updateSelectedIds((prev) => {
            const isAlreadySelected = Boolean(prev.find(i => i === id));
            if (isAlreadySelected) {
                const arrCopy = [...prev];
                return arrCopy.filter(i => i !== id);
            } else {
                return [...prev, id];
            }
        });
    }

    const toggleBulkMode = () => {
        if (bulkSelectMode) {
            updateSelectedIds([]);
        }
        setBulkSelectMode(!bulkSelectMode);
    }

    const resetBulkState = () => {
        setBulkSelectMode(false);
        updateSelectedIds([]);
    }

    return {
        bulkSelectMode,
        setBulkSelectMode,
        bulkSelectedIds,
        updateSelectedIds,
        onBulkSelect,
        toggleBulkMode,
        resetBulkState,
    }
}

export default useBulkSelect;
