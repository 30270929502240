import React, {useState, useRef} from 'react';
import {Document} from "../../../types";
import {Button} from "primereact/button";
import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {DocumentService} from "../../../DocumentService";
import {useToast} from "../../../../../hooks/useToast";
import {transportTypes} from "../../../../../config/config";
import FilePreviewOverlay from "../../../../../uiComponents/FilePreviewOverlay/FilePreviewOverlay";
import {classNames} from "primereact/utils";

type PropTypes = {
    documentData: Document;
};

const DownloadLabelBodyTemplate = ({ documentData }: PropTypes) => {
    const [fileUrl, setFileUrl] = useState<string>('');
    const flags = useRef({ isPreview: false });
    const { show } = useToast();

    const downloadFile = (fileName: string, url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const downloadLabel = useMutation({
        mutationFn: DocumentService.downloadLabel,
        onSuccess: (response: any) => {
            if (flags.current.isPreview) {
                setFileUrl(response.data.url);
                flags.current.isPreview = false;
            } else {
                downloadFile(response.data.fileName, response.data.url);
            }
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: downloadLabel.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const getFileData = (isPreview: boolean) => {
        if (isPreview) {
            flags.current.isPreview = true;
        }
        downloadLabel.mutate(documentData.orderNumber);
    }
    return (
        <>
            {
                fileUrl && (
                    <FilePreviewOverlay
                        preview={fileUrl}
                        closePreview={() => setFileUrl('')}
                        hideDownloadButton
                    />
                )
            }
            <Button
                disabled={!documentData.orderNumber || documentData.deliveryService !== transportTypes.FEDEX}
                className={classNames("p-button-rounded p-button-text")}
                onClick={() => getFileData(true)}
                icon="pi pi-file-pdf"
            />
            <Button
                className={"p-button-rounded p-button-text"}
                icon="pi bi-download"
                onClick={() => getFileData(false)}
                disabled={!documentData.orderNumber || documentData.deliveryService !== transportTypes.FEDEX}
            />
        </>
    )
}

export default DownloadLabelBodyTemplate;
