import { Placement } from '@popperjs/core';
import { classNames } from 'primereact/utils';
import React, { FC, ReactElement, useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

import useOutsideClick from '../../hooks/useOutsideClick';

import styles from './multiSelectWrapper.module.scss';
import {Nullable} from "primereact/ts-helpers";

type TOptions = {
    value: string;
    label: string;
    disabled?: boolean;
};
interface IDropdownWrapperProps {
    value: string[];
    options: TOptions[];
    onSelect: (value: string[], name?: string) => void;
    children: ReactElement;
    className?: string;
    placement?: Placement;
    disabled?: boolean;
    name?: string;
    showFooter?: boolean;
}
const MultiSelectWrapper: FC<IDropdownWrapperProps> = ({
options,
onSelect,
children,
className,
placement,
disabled,
value = [],
name,
showFooter = false,
}) => {
    const [isOpened, setOpenedState] = useState(false);
    const targetRef = useRef(null);
    const [referenceEl, setReferenceEl] =
        useState<Nullable<HTMLDivElement>>(null);
    const [popperEl, setPopperEl] = useState<Nullable<HTMLDivElement>>(null);
    const { styles: popperStyles } = usePopper(referenceEl, popperEl, {
        placement: placement || 'bottom',
        modifiers: [
            {
                name: 'eventListeners',
                options: { scroll: false }
            }
        ],
    });

    const handleOutsideClick = () => {
        setOpenedState(false);
    };
    useOutsideClick(targetRef, handleOutsideClick);

    const handleMultiSelect = (key: string) => {
        const isExist = value.find(str => str === key);
        if (isExist) {
            const newValue = value.filter(str => str !== key);
            onSelect(newValue, name);
        } else {
            const newValue = [...value, key];
            onSelect(newValue, name);
        }
    }

    return (
        <div ref={targetRef} className={styles.multiSelectWrapper}>
            <div
                className={styles.childrenWrapper}
                role="presentation"
                onClick={() => setOpenedState(!isOpened)}
                ref={disabled ? () => null : (ref) => setReferenceEl(ref)}
            >
                {children}
            </div>
            {isOpened && !disabled && (
                <div className={styles.dropdownWrapper} style={popperStyles.popper}>
                    <div className={classNames(styles.dropdown, className)} ref={(ref) => setPopperEl(ref)}>
                        {options.map((dropdownOption, index) => {
                            const {label, value: itemValue, disabled} = dropdownOption;
                            const isChecked = Boolean(value.find((s) => s === itemValue));
                            return (
                                <div
                                    key={`${value}${index}`}
                                    className={classNames(styles.dropItem, {
                                        [styles.disabled]: disabled,
                                    })}
                                >
                                    <Checkbox
                                        onChange={() => handleMultiSelect(itemValue)}
                                        checked={isChecked}>
                                    </Checkbox>
                                    <p className={styles.dropLabel}>{label}</p>
                                </div>
                            );
                        })}
                    </div>
                    {
                        showFooter && (
                            <div className={styles.dropdownFooter}>
                                <Button
                                    icon="pi pi-times"
                                    rounded
                                    text
                                    severity="danger"
                                    aria-label="clear"
                                    title="Clear filters"
                                    onClick={() => onSelect([])}
                                />
                                <Button
                                    onClick={() => setOpenedState(false)}
                                    icon="pi pi-check"
                                    rounded text aria-label="Filter"
                                />
                            </div>
                        )
                    }
                </div>
            )}
        </div>
    );
};

export default MultiSelectWrapper;
