import { classNames } from "primereact/utils";
import { Document, DocumentStatus } from "../../../types";
import styles from "./statusBodyTemplate.module.scss";
import { FilePreviewAction } from "./FilePreviewAction";
import {documentStatusesLabels} from "../../../../../config/config";

type PropTypes = {
  document: Document;
};

const OrderedStatuses = [
  DocumentStatus.PENDING,
  DocumentStatus.PARTIALLY_SIGNED,
  DocumentStatus.FULLY_SIGNED,
  DocumentStatus.MAILED,
  DocumentStatus.DELIVERED,
  DocumentStatus.ACKNOWLEDGED,
];

const StatusLabels: Partial<Record<DocumentStatus, string>> = {
  [DocumentStatus.FULLY_SIGNED]: documentStatusesLabels[DocumentStatus.FULLY_SIGNED],
  [DocumentStatus.MAILED]: documentStatusesLabels[DocumentStatus.MAILED],
  [DocumentStatus.DELIVERED]: documentStatusesLabels[DocumentStatus.DELIVERED],
  [DocumentStatus.ACKNOWLEDGED]: documentStatusesLabels[DocumentStatus.ACKNOWLEDGED],
  [DocumentStatus.PARTIALLY_SIGNED]: documentStatusesLabels[DocumentStatus.PARTIALLY_SIGNED],
  [DocumentStatus.PENDING]: documentStatusesLabels[DocumentStatus.PENDING],
};

export const StatusBodyTemplate = ({ document }: PropTypes) => {
  return (
    <div className={styles.container}>
      {OrderedStatuses.map((docStatus) => {
        const statusDate = document.statuses[docStatus];
        return (
          <div key={docStatus} className={styles.docStatus}>
            <div
              className={classNames(styles.badge, {
                [styles.active]: !!statusDate,
              })}
            ></div>
            <div className={styles.label}>{StatusLabels[docStatus]}</div>
            {statusDate ? (
              <FilePreviewAction
                orderNumber={document.orderNumber}
                docStatus={docStatus}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
