import React, { FC } from 'react';
import styles from '../bulkUploadUsers.module.scss';
import {classNames} from "primereact/utils";
import {THeaderConfigItem} from "./TableHead";

interface TTableBodyProps {
    data: Record<string, string>[];
    order: THeaderConfigItem[];
}

const TableBody: FC<TTableBodyProps> = ({ data = [], order = [] }) => {
    return (
        <tbody>
        {
            data.map((item, index) => {
                return (
                    <tr key={index}>
                        {
                            order.map((config: THeaderConfigItem, index: number) => {
                                const { key, name } = config;
                                const val = item[name];
                                return (
                                    <td key={index} className={classNames(styles.tableData, {
                                        [styles.typeOfRestriction]: key === null || key === 'TYPE_OF_RESTRICTION',
                                    })}>
                                        {val}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })
        }
        </tbody>
    )
}

export default TableBody;
