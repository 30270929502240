import React, { FC, useMemo } from 'react';

import styles from './avatarFrame.module.scss';

const colors: string[] = [
    '#92D3CC',
    // '#C80000',
    '#006EFF',
    '#FF9E45',
    '#1D7458',
    '#FF4F4D',
    '#F5603E',
    '#01A056',
    '#6566F1',
    // '#848484',
    '#80C272',
    '#5BACCF',
    '#2E67A0',
    // '#496892',
    '#2E67A0',
    '#A27927',
    '#E4D278',
    '#48743C',
    '#E1425F',
    '#A42183',
    '#7FDF00',
    '#631FA2',
    '#409F9E',
    '#F18D9C',
    // '#585437',
    '#745D4C',
    '#AA6C78',
    '#F4D302',
    '#E460C6',
];

interface TAvatarFrameProps {
  fullName: string;
  frameSize?: number;
}
const AvatarFrame: FC<TAvatarFrameProps> = ({ fullName = '', frameSize }) => {
  const firstLetter = fullName.charAt(0).toLowerCase();
  const customSizeStyles = useMemo(() => {
    if (!frameSize) return {};
    const sizePx = `${frameSize}px`;
    return {
      width: sizePx,
      minWidth: sizePx,
      maxWidth: sizePx,
      height: sizePx,
      minHeight: sizePx,
      maxHeight: sizePx,
    }
  }, [frameSize]);

  const hashCode = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  const getColorForName = (fullName: string) => {
    const hash = hashCode(fullName);
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
  };
  const color = getColorForName(fullName);
  return (
    <div className={styles.avatarFrame} style={{ backgroundColor: color || `#1D7458`, ...customSizeStyles }}>
      {firstLetter}
    </div>
  );
};

export default AvatarFrame;
