import { classNames } from 'primereact/utils';
import React, { FC, ReactElement } from 'react';

import styles from './radioCheckboxLabel.module.scss';

interface TRadioCheckboxLabelProps {
  htmlFor: string;
  children?: ReactElement;
  label?: string;
  disabled?: boolean;
}
const RadioCheckboxLabel: FC<TRadioCheckboxLabelProps> = ({
  htmlFor,
  children,
  label,
  disabled,
}) => {
  return (
    <label
      className={classNames(styles.radioCheckboxLabel, {
        [styles.disabled]: disabled,
      })}
      htmlFor={htmlFor}
    >
      {label || children}
    </label>
  );
};

export default RadioCheckboxLabel;
