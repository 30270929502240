import React, {FC, useEffect, useRef, useState} from 'react';
import DocumentGeneration from "../../../../components/DocumentGeneration/DocumentGeneration";
import {useMutation, useQuery} from "@tanstack/react-query";
import {requests} from "../../../../services/http-common";
import { getYupDynamicScheme } from "../../../../components/DocumentGeneration/helpers/yupValidationHelpers";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../hooks/useToast";
import Draft83bFilledModal from "../../../../components/Draft83bFilledModal/Draft83bFilledModal";

interface TPrivateFile83bProps {
    companyId: number;
    templateCode: string;
    paymentEnabled: boolean;
    isEveryCompany: boolean;
    templateSpouseCode?: string;
    email?: string;
    fullName?: string;
    filingCreationEnabled: boolean;
}

type TPreFilledFormValuesProps = {
    templateId?: string;
}

const PrivateFile83b:FC<TPrivateFile83bProps> = ({
 companyId,
 templateCode,
 templateSpouseCode,
 email = '',
 fullName = '',
 paymentEnabled,
 isEveryCompany,
 filingCreationEnabled,
}) => {
    const [templateId, setTemplateId] = useState('');
    const [isSupposeExists, setSuppose] = useState<boolean>(false);
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const fileId = urlParams.get('fileId') || '';
    const file83bUpdate = Boolean(fileId);
    const templateIdNotSet = templateId === '';
    const editPrefix = file83bUpdate ? `/edit` : '';
    const signAfterEmbeddedPaymentOrderNumber = urlParams.get('rdn');
    const preFilledTemplateIdSetRef = useRef(false);
    const [preFilledFormValues, setPreFilledFormValues] = useState<TPreFilledFormValuesProps>({});
    const [alreadyFilledModal, showAlreadyFilledModal] = useState<boolean>(false);
    const { show } = useToast();

    const getPreFilledFormValues = useMutation({
        mutationFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/file83b/${fileId}`,
            );
        },
        onSuccess: (response) => {
            setPreFilledFormValues(response);
        },
        onError: (error: AxiosError<{ message: string, response: { status: number } }>, variables) => {
            if (error?.response?.status === 400) {
                showAlreadyFilledModal(true);
            } else {
                const errorData = {
                    error: error,
                    variables: variables,
                    retryFn: getPreFilledFormValues.mutate,
                    show: show,
                };
                extractErrorMessage(errorData);
            }
        },
    });
    const preFilledFormValuesLoading = getPreFilledFormValues?.isPending;

    const { data: fieldsConfiguration, isLoading: requiredFieldsLoading } = useQuery({
        queryKey: ['required-fields', isSupposeExists, companyId],
        queryFn: async () => {
            return requests.get(
                `/api/companies/${companyId}/file83b/getPlaceholdersByTemplateCodeDb/${templateId}${editPrefix}`,
            );
        },
        gcTime: 0,
        enabled: !templateIdNotSet && !signAfterEmbeddedPaymentOrderNumber,
    });

    const { data: docAsHtml, isLoading: docAsHtmlLoading } = useQuery({
        queryKey: ['doc-as-html', isSupposeExists, companyId],
        queryFn: async () => {
            return requests.get(`/api/companies/${companyId}/file83b/exportDocAsHtml/${templateId}`);
        },
        gcTime: 0,
        enabled: !templateIdNotSet && !signAfterEmbeddedPaymentOrderNumber,
    });

    const setSupposeHandler = (isExist: boolean) => {
        const newTemplateId = isExist ? templateSpouseCode : templateCode;
        setSuppose(isExist);
        setTemplateId(newTemplateId as string);
    }
    const onCloseModal = () => {
        showAlreadyFilledModal(false);
    }
    useEffect(() => {
        if (file83bUpdate && !signAfterEmbeddedPaymentOrderNumber) {
            getPreFilledFormValues.mutate();
        }
    }, []);
    useEffect(
        () => {
            if (preFilledFormValues?.templateId && fileId && !preFilledTemplateIdSetRef.current) {
                const isSupposeExist = preFilledFormValues?.templateId === templateSpouseCode;
                preFilledTemplateIdSetRef.current = true;
                setSupposeHandler(isSupposeExist);
            }
        }, [preFilledFormValues?.templateId]
    );
    const formSchema = fieldsConfiguration?.configs ? getYupDynamicScheme(fieldsConfiguration?.configs) : undefined;
    return (
        <div>
            {
                alreadyFilledModal && (<Draft83bFilledModal onClose={onCloseModal} filingCreationEnabled={filingCreationEnabled} />)
            }
            <DocumentGeneration
                companyId={companyId}
                email={email}
                fullName={fullName}
                isSupposeExists={isSupposeExists}
                setSuppose={setSupposeHandler}
                template={templateId}
                data={fieldsConfiguration}
                requiredFieldsLoading={requiredFieldsLoading}
                docAsHtml={docAsHtml}
                docAsHtmlLoading={docAsHtmlLoading}
                preFilledFormValues={preFilledFormValues}
                file83bUpdate={file83bUpdate}
                preFilledFormValuesLoading={preFilledFormValuesLoading}
                fileId={fileId}
                showSelectTemplate={Boolean(templateSpouseCode)}
                signAfterEmbeddedPaymentOrderNumber={signAfterEmbeddedPaymentOrderNumber}
                paymentEnabled={paymentEnabled}
                formSchema={formSchema}
                isEveryCompany={isEveryCompany}
            />
        </div>
    )
}

export default PrivateFile83b;
