import React, { FC } from 'react';
import {classNames} from "primereact/utils";
import styles from "./htmlEditor.module.scss";
import "./index.css";

interface TEditorProps {
    content: string;
    isEditing?: boolean;
}
const EditorResult: FC<TEditorProps> = ({
content,
isEditing,
}) => {
    return (
        <div className={classNames(styles.editorPreview, { [styles.editingMode]: isEditing } )}>
            <div className={classNames('html-editor', styles.editorContentHolder)}>
                <div
                    dangerouslySetInnerHTML={{__html: content}}
                    className={classNames('content-styles-holder', styles.contentStylesHolder)}
                />
            </div>
        </div>
    )
}

export default EditorResult;
