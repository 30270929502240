import { useQuery } from "@tanstack/react-query";
import { useFilters } from "./useFilters";
import { DocumentService } from "../../DocumentService";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTableStateEvent } from "primereact/datatable";

export const useDocuments = () => {
  const { filters, setFilters } = useFilters(
      { page: 0 }
  );
  const { data, error, isLoading } = useQuery({
    queryKey: DocumentService.documents.queryKey({
      ...filters,
      statuses: filters.statuses.join(","),
      sort: filters.sort.join(","),
      grantDateStart: filters.from,
      grantDateEnd: filters.to,
      signWellViewed: filters.signWellViewed === 'YES' ? filters.signWellViewed : 'NONE',
      paid: filters.paid === 'YES' ? filters.paid : 'NONE',
    }),
    queryFn: DocumentService.documents.queryFn,
  });
  const onPage = (event: PaginatorPageChangeEvent | DataTableStateEvent) => {
    setFilters((currVal: any) => ({
      ...currVal,
      page: event.page,
      size: event.rows,
    }));
  };

  return {
    setQuery: (query: string) =>
      setFilters((prevFilters) => ({ ...prevFilters, page: 0, query })),

    maxCount: data?.totalElements ?? 0,
    documents: data?.content,
    isLoading,
    failed: !!error,
    filters,
    setFilters,
    onPage,
  };
};
