import React,{ FC, useState } from 'react';
import styles from './styles.module.scss';

import modalImage from '../../../../assets/images/submission-created.png';
import {useNavigate} from "react-router-dom";
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";

interface TSubmissionCreatedModalProps {
    isSupposeExists: boolean;
    showSubmissionSuccessModal: boolean;
}
const delay = 1500;
const SubmissionCreatedModal: FC<TSubmissionCreatedModalProps> = ({ isSupposeExists, showSubmissionSuccessModal }) => {
    const [delayLoading, setDelayLoading] = useState(false);
    const navigate = useNavigate();
    const redirectWithDelay = () => {
        setDelayLoading(true);
        setTimeout(() => {
            navigate('/filing');
        }, delay);
    }
    if (!showSubmissionSuccessModal) return null;
    return (
        <div className={styles.submissionCreatedOverlay}>
            <div className={styles.modal}>
                <img src={modalImage} className={styles.modalImage} alt="" />
                <p className={styles.headerTitle}>
                    Submission created
                </p>
                <div className={styles.texts}>
                    {
                        isSupposeExists ? (
                            <>
                                <p>
                                    Your spouse will receive an email to sign the document.  Please note that your
                                    spouse will have <span>two days to sign</span>; otherwise the document will expire, your draft
                                    will be voided, and you will have to prepare a new form.
                                </p>
                            </>
                        ) : (
                            <>
                                <p>Your 83(b) election has been successfully signed. You can track your updates through your account.</p>
                            </>
                        )
                    }

                </div>
                <div className={styles.modalFooter}>
                    <ButtonPrimary
                        label="Track your order"
                        styleType="primaryOutline"
                        onClick={redirectWithDelay}
                        disabled={delayLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default SubmissionCreatedModal;
