import React, { FC } from 'react';
import styles from './dashboard.module.scss';
import Activities from "./ActivitiesAndDocuments/Activities";
import Documents from "./ActivitiesAndDocuments/Documents";
import {TActivityItem, TDocumentItem} from "../../@types/global";

interface TRowDetailsProps {
    activities: TActivityItem[];
    documents: TDocumentItem[];
    companyId: number;
}

const RowDetails: FC<TRowDetailsProps> = ({ activities, documents, companyId }) => {
    return (
        <div className={styles.rowDetails}>
            <div className={styles.col}>
                <Activities
                    {...(activities ? { data: activities } : {} )}
                />
            </div>
            <div className={styles.col}>
                <Documents
                    {...(documents ? { data: documents } : {} )}
                    companyId={companyId}
                />
            </div>
        </div>
    )
}

export default RowDetails;
