import React, {FC, useMemo, useState} from 'react';

import styles from '../styles.module.scss';
import {stepsKeys, TDataItem, TStep } from '../types';
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {classNames} from "primereact/utils";

interface IReviewStepProps {
  data: Record<string, string>;
  fieldsConfig: TDataItem[];
  setStep: React.Dispatch<React.SetStateAction<TStep>>;
}

type TKeys = 'basicInfoOpened' | 'companyInfoOpened';

const ReviewStep: FC<IReviewStepProps> = ({ data, fieldsConfig, setStep }) => {
  const [toggled, setToggled] = useState<Record<TKeys, boolean>>({
    basicInfoOpened: true,
    companyInfoOpened: true,
  });
  const reorderBasicInfo = (basicInfo: TDataItem[], basicInfoOrder: string[]) => {
    const orderedItems: TDataItem[] = [];
    const remainingItems: TDataItem[] = [];
    basicInfo.forEach(item => {
      if (basicInfoOrder.includes(item.configName)) {
        orderedItems.push(item);
      } else {
        remainingItems.push(item);
      }
    });

    orderedItems.sort((a, b) => {
      const indexA = basicInfoOrder.indexOf(a.configName);
      const indexB = basicInfoOrder.indexOf(b.configName);
      return indexA - indexB;
    });
    return [...orderedItems, ...remainingItems];
  }
  const toggle = (sectionName: TKeys) => {
    setToggled((prev) => {
      return {
        ...prev,
        [sectionName]: !prev[sectionName],
      }
    })
  }
  const { basicInfoOpened, companyInfoOpened } = toggled;
  const getFieldValue = (fieldName: string) => {
    let value = 'N/A';
    if (Object.prototype.hasOwnProperty.call(data, fieldName)) {
      value = data[fieldName];
    }
    if (fieldName === 'state' && Boolean(value)) {
      value = value.replace(', ', '');
    }
    if (fieldName === 'address_line2' && value === ' ') {
      value = 'N/A';
    }
    const formatWithUSDSignFields = {
      price_per_share: true,
      price_paid: true,
      FMV: true,
      Total_Paid: true,
      Income: true,
    };
    if (
      Object.prototype.hasOwnProperty.call(formatWithUSDSignFields, fieldName)
    ) {
      value = `$${value}`;
    }
    return <span>{value || 'N/A'}</span>;
  };
  const configGroups = useMemo(() => {
    const separatedFields = [
        'price_per_share',
        'price_paid',
    ];
    const separatedGroupAKeys = [
        'price_per_share',
        'FMV',
        'price_paid',
        'Total_Paid',
    ];
    const separatedGroupBKeys = [
        'Income',
    ];
    const basicInfo = fieldsConfig.filter((item) => (item.step === 1 && !item.action));
    const basicInfoOrder = [
        'taxpayer_name',
        'spouse_name',
        'taxpayer_id',
        'spouse_id',
        'country',
        'address',
        'city',
        'state',
        'zip_code',
        'taxable_year',
        'firstRecipientEmail',
        'secondRecipientEmail',
    ];
    const basicInfoOrdered = reorderBasicInfo(basicInfo, basicInfoOrder);

    const companyInfo = fieldsConfig.filter((item) => {
      const { configName } = item;
      const isSeparatedField = separatedFields.find((sepF) => sepF === configName);
      return (item.step === 2 && !item.action && !isSeparatedField);
    });
    const computedFields = fieldsConfig.filter((item) => item.action !== null);

    const separatedGroupA: (TDataItem | undefined)[] = separatedGroupAKeys.map((key) => fieldsConfig.find((config) => config.configName === key));
    const separatedGroupB: (TDataItem | undefined)[] = separatedGroupBKeys.map((key) => fieldsConfig.find((config) => config.configName === key));
    const separatedGroupAFiltered = separatedGroupA.filter(item => Boolean(item));
    const separatedGroupBFiltered = separatedGroupB.filter(item => Boolean(item));
    return {
      basicInfo,
      companyInfo,
      computedFields,
      separatedGroupA: separatedGroupAFiltered,
      separatedGroupB: separatedGroupBFiltered,
      basicInfoOrdered,
    }
  }, [fieldsConfig]);
  const { companyInfo, separatedGroupA, separatedGroupB, basicInfoOrdered } = configGroups;
  return (
    <div className={styles.reviewWidget}>
      <p className={styles.reviewLabel}>Make sure your data is correct</p>
      <div className={styles.dataWrapper}>
        <div className={styles.collapseRow}>
          <div className={styles.rowHead} onClick={() => toggle('basicInfoOpened')}>
            <i className={classNames('bi bi-chevron-down', {[styles.toggled]: basicInfoOpened})}></i>
            <span>Personal info</span>
          </div>
          {
            basicInfoOpened && (
                  <div className={styles.infoGrid}>
                    {basicInfoOrdered.map((item) => {
                      const {label, configName} = item;
                      return (
                          <div key={configName} className={styles.infoRow}>
                            <span>{label}</span>
                            {getFieldValue(configName)}
                          </div>
                      );
                    })}
                  </div>
              )
          }
        </div>
        <div className={styles.collapseRow}>
          <div className={styles.rowHead} onClick={() => toggle('companyInfoOpened')}>
            <i className={classNames('bi bi-chevron-down', {[styles.toggled]: companyInfoOpened})}></i>
            <span>Equity info</span>
          </div>
          {
              companyInfoOpened && (
                  <div className={styles.companyInfoWrapper}>
                    <div className={styles.companyInfoGrid}>
                      {companyInfo.map((item) => {
                        const {label, configName} = item;
                        return (
                            <div key={configName} className={classNames(styles.infoRow, {
                              [styles.fullWidth]: configName === 'type_of_restriction'
                            })}>
                              <span>{label}</span>
                              {getFieldValue(configName)}
                            </div>
                        );
                      })}
                    </div>
                    {
                        (separatedGroupA.length > 0 || separatedGroupB.length > 0) && (
                            <div className={styles.separatedInfo}>
                              <div className={styles.sepInfoMain}>
                                {
                                  separatedGroupA.map((item) => {
                                    if (!item) return null;
                                    const {label, configName} = item;
                                    return (
                                        <div key={configName} className={styles.sepInfoRow}>
                                          <span>{label}</span>
                                          {getFieldValue(configName)}
                                        </div>
                                    );
                                  })
                                }
                              </div>
                              <div className={styles.sepInfoFooter}>
                                {
                                  separatedGroupB.map((item) => {
                                    if (!item) return null;
                                    const {label, configName} = item;
                                    const isBold = configName === 'Total_Paid';
                                    return (
                                        <div key={configName} className={classNames(styles.sepInfoRow, {
                                          [styles.bold]: isBold,
                                        })}>
                                          <span>{label}</span>
                                          {getFieldValue(configName)}
                                        </div>
                                    );
                                  })
                                }
                              </div>
                            </div>
                        )
                    }
                  </div>
              )
          }
        </div>
      </div>
      <div className={styles.commonFooter}>
        <ButtonPrimary
            label="Back to edit"
            styleType="secondaryOutline"
            type="button"
            onClick={() => setStep(stepsKeys.filling)}
        />
        <ButtonPrimary
            type="button"
            onClick={() => setStep(stepsKeys.terms)}
            label="Next"
        />
      </div>
    </div>
  );
};

export default ReviewStep;
