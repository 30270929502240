import React,{FC} from 'react';
import styles from './confirmDialogCustom.module.scss';
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

interface TConfirmDialogCustomProps {
    header: string;
    acceptLabel: string;
    rejectLabel: string;
    message: string;
    visible: boolean;
    onHide: () => void;
    accept: () => void;
    iconSrc?: string;
}

const ConfirmDialogCustom: FC<TConfirmDialogCustomProps> = ({
header,
acceptLabel = 'Accept',
rejectLabel = 'Cancel',
message,
visible,
onHide,
accept,
iconSrc,
}) => {
    if (!visible) {
        return null;
    }
    const onAccept = () => {
        accept();
        onHide();
    }
    return (
        <div className={styles.dialogOverlay}>
            <div className={styles.modal}>
                <div className={styles.modalBody}>
                    {iconSrc && (<img src={iconSrc} alt="" className={styles.icon} />)}
                    <p className={styles.header}>{header}</p>
                    <p className={styles.message}>{message}</p>
                </div>
                <div className={styles.modalFooter}>
                    <ButtonPrimary
                        styleType="secondaryOutline"
                        label={rejectLabel}
                        onClick={onHide}
                    />
                    <ButtonPrimary
                        label={acceptLabel}
                        onClick={onAccept}
                    />
                </div>
            </div>
        </div>
    );
}

export default ConfirmDialogCustom;
