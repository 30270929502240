import { classNames as cx } from 'primereact/utils';
import { useRef, useState } from 'react';

import ADD_FILE_LOGO from '../../assets/icons/upload_icon.svg';

import classes from './Drag&DropFile.module.scss';
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";

type DragDropFileProps = {
  onChange: (files: any) => void;
  multiple?: boolean;
  label?: string;
  allowedExtensions?: string;
};

export const DragDropFile = ({
  onChange,
  multiple = true,
  label = "Drag and drop the document or documents you'd like to add.",
  allowedExtensions = '.pdf, .docx, .doc, .csv',
}: DragDropFileProps) => {
  // ref
  const inputRef = useRef(null);
  // drag state
  const [dragActive, setDragActive] = useState(false);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (inputRef.current) {
        onChange(e.target.files);
        //@ts-ignore
        inputRef.current.value = null;
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    //@ts-ignore
    inputRef.current.click();
  };

  return (
    <form
      className={classes.form}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        className={classes.inputFile}
        multiple={multiple}
        onChange={handleChange}
        accept={allowedExtensions}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={cx(classes.fileUploadLabel, {
          [classes.dragActive]: dragActive,
        })}
      >
        <div className={classes.actionContent}>
          <div>
            <img src={ADD_FILE_LOGO} alt="add file"/>
          </div>
          <p>{label}</p>
          <div>
            <ButtonPrimary
                label="Choose File"
                onClick={onButtonClick}
                styleType="primaryOutline"
            />
          </div>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          className={classes.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};
