import React from 'react';
import {Navigate} from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import TermsVersions from "./TermsVersions";
import TermsUpdate from "./TermsUpdate";
import styles from './termsUpdate.module.scss';

enum tabRoutes {
    '' = 0,
    'update/:policyType' = 1,
}

const TermsAdminRoutes = () => {
    return (
        <div className={styles.termsRoutes}>
            <Routes>
                <Route path={tabRoutes[`${0}`]} element={<TermsVersions />} />
                <Route path={tabRoutes[`${1}`]} element={<TermsUpdate />} />
                <Route path="/*" element={<Navigate to={tabRoutes[`${0}`]} replace />} />
            </Routes>
        </div>
    )
}

export default TermsAdminRoutes;
