import React, {ReactElement, FC, useRef} from 'react';
import {Calendar, CalendarChangeEvent} from 'primereact/calendar';
import styles from './dashboard.module.scss';
import { Placement } from '@popperjs/core';
import {Button} from "primereact/button";

export type TRangeCalendarValue = string | Date | Date[] | null | undefined;

interface TCalendarFilterProps {
    name: string;
    children: ReactElement;
    dates: TRangeCalendarValue;
    handleDateChange: (name: string, newValue: TRangeCalendarValue) => void;
    placement?: Placement;
    isOpenedOutside?: boolean;
    handleClick: (name: string, value?: string) => void;
    onClose: () => void;
}
const CalendarFilter: FC<TCalendarFilterProps> = ({
name,
children,
dates,
handleDateChange,
isOpenedOutside,
handleClick,
onClose,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const handleCalendarChange = (event: CalendarChangeEvent) => {
        handleDateChange(name, event.value);
    };
    const handleClear = () => {
        handleDateChange(name, null);
        onClose();
    }
    const setSingleDate = () => {
        // @ts-ignore
        const date1 = dates?.[0] ?? null;
        // @ts-ignore
        const date2 = dates?.[1] ?? null;
        if (date1 && !date2) {
            handleDateChange(name, [date1, date1]);
        }
    }
    const handleAccept = () => {
        setSingleDate();
        onClose();
    }
    const calendarControlOnHide = () => {
        setSingleDate();
        onClose();
    }
    return (
        <div className={styles.calendarFilter} ref={ref}>
            <div
                role="presentation"
                onClick={() => handleClick(name)}
                className={styles.childrenWrapper}
            >
                {children}
            </div>
            {
                isOpenedOutside && (
                    <div className={styles.calendarHolder}>
                        <Calendar
                            className={styles.calendarElement}
                            value={dates}
                            onChange={handleCalendarChange}
                            onHide={calendarControlOnHide}
                            placeholder="Pick range"
                            selectionMode="range"
                            showButtonBar
                            readOnlyInput
                        />
                        <Button
                            onClick={handleClear}
                            icon="pi pi-times"
                            rounded text aria-label="Filter"
                        />
                        <Button
                            onClick={handleAccept}
                            icon="pi pi-check"
                            rounded text aria-label="Filter"
                        />
                    </div>
                )
            }
        </div>
    )
}

export default CalendarFilter;
