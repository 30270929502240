// import { UserRole } from './pages/Settings/tabs/UserManagement/User.model';

export type TUserRole =
  | "ROLE_ATTORNEY"
  | "ROLE_STARTUP_REPRESENTATIVE"
  | "ROLE_INVESTOR"
  | "ROLE_USER";

export const UserRoleLabels: Record<TUserRole, string> = {
  ROLE_ATTORNEY: "Attorney",
  ROLE_STARTUP_REPRESENTATIVE: "Startup representative",
  ROLE_INVESTOR: "Investor",
  ROLE_USER: "User",
};

export const rowOptions = [5, 10, 25, 50];
