import React, { FC } from 'react';
import styles from './public83b.module.scss';
import DocumentGeneration from "../../components/DocumentGeneration/DocumentGeneration";
import Loading from "../../components/Loading/Loading";
import {useQuery} from "@tanstack/react-query";
import {TemplatesService} from "./TemplateService";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import {getYupDynamicScheme} from "../../components/DocumentGeneration/helpers/yupValidationHelpers";

interface TPublicOAIFile83bProps {
    disableFiling?: boolean;
}
const PublicOAIFile83b: FC<TPublicOAIFile83bProps> = ({ disableFiling }) => {
    const { data: initialInfo } = useQuery({
        queryKey: TemplatesService.fetchInitialInfo.queryKey,
        queryFn: () => TemplatesService.fetchInitialInfo.queryFn(),
    });
    const { templateCode, templateSpouseCode, price } = initialInfo || {};
    const template = templateCode;
    const isPublicOpenAi = true;

    const { data: fieldsConfiguration, isLoading: requiredFieldsLoading } = useQuery({
        queryKey: [...TemplatesService.fetchTemplateConfiguration.queryKey],
        queryFn: () => TemplatesService.fetchTemplateConfiguration.queryFn(template),
        enabled: Boolean(templateCode),
        gcTime: 0,
    });

    const { data: docAsHtml, isLoading: docAsHtmlLoading } = useQuery({
        queryKey: [...TemplatesService.exportDocAsHtml.queryKey],
        queryFn: () => TemplatesService.exportDocAsHtml.queryFn(template),
        enabled: Boolean(templateCode),
        gcTime: 0,
    });

    const formSchema = fieldsConfiguration?.configs ? getYupDynamicScheme(fieldsConfiguration?.configs, isPublicOpenAi) : undefined;
    return (
        <div className={styles.public83b}>
            {
                disableFiling && (
                    <div className={styles.disabledFilingModalOverlay}>
                        <div className={styles.modal}>
                            <div className={styles.modalHeader}>
                                <i className="bi bi-info-circle" />
                                <p className={styles.heading}>
                                    Batch Closed
                                </p>
                            </div>
                            <p className={styles.description}>
                                The 21-day window for submitting 83(b) election orders for the most recent batch of grants is currently closed.  Please check back in later for the next batch.
                            </p>
                        </div>
                    </div>
                )
            }
            {
                ((!templateCode)) ? (
                    <div className={styles.loadingWrapper}>
                        <Loading horizontalSpacing={250} />
                    </div>
                ) : (
                    <>
                        <PublicHeader />
                        <DocumentGeneration
                            isSupposeExists={false}
                            setSuppose={() => null}
                            template={template}
                            data={fieldsConfiguration}
                            requiredFieldsLoading={requiredFieldsLoading}
                            docAsHtml={docAsHtml}
                            docAsHtmlLoading={docAsHtmlLoading}
                            isPublic
                            price={price}
                            showSelectTemplate={Boolean(templateSpouseCode)}
                            formSchema={formSchema}
                            isPublicOpenAi
                            disableFiling={disableFiling}
                        />
                    </>
                )
            }
        </div>
    )
}

export default PublicOAIFile83b;
