import { useState } from "react";

type TParams = {
  page: number;
  size: number;
  sort: string[];
};

const initialOptions = {
  page: 0,
  size: 10,
  sort: [],
};

const usePagination = () => {
  const [params, setOptions] = useState<TParams>(initialOptions);
  const changePage = (newPage: number) => {
    setOptions((prev) => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const changePerPage = (newPerPage: number) => {
    setOptions((prev) => {
      return {
        ...prev,
        page: 0,
        size: newPerPage,
      };
    });
  };

  const onSort = (columnName: string) => {
    const sortKeys = { asc: 'asc', desc: 'desc' };
    if (!columnName) return;
    setOptions((prev) => {
      let isSorted = false;
      let isAsc = false;
      let sortedStr = '';
      (prev.sort || []).forEach((searchStr) => {
        const sortFieldArr = (searchStr || '').split(',');
        const sortFieldName = sortFieldArr[0];
        const sortFieldOrder = sortFieldArr[1];
        if (columnName === sortFieldName) {
          isSorted = true;
          sortedStr = searchStr;
        }
        if (sortFieldOrder === sortKeys.asc) {
          isAsc = true;
        }
      });
      if (isSorted) {
        const filtered = (prev.sort || []).filter((str) => str !== sortedStr);
        if (isAsc) {
          return {
            ...prev,
            sort: [...filtered, `${columnName},${sortKeys.desc}`]
          }
        } else {
          return {
            ...prev,
            sort: filtered,
          }
        }
      } else {
        return {
          ...prev,
          sort: [...prev.sort, `${columnName},${sortKeys.asc}`],
        }
      }
    });
  };

  const isSorted = (columnName: string, sort: string[]): { isAscSorted: boolean, isDescSorted: boolean } => {
    let isAscSorted = false;
    let isDescSorted = false;
    (sort || []).forEach((sortStr) => {
      if (`${columnName},asc` === sortStr) {
        isAscSorted = true;
      }
      if (`${columnName},desc` === sortStr) {
        isDescSorted = true;
      }
    });
    return {
      isAscSorted,
      isDescSorted,
    };
  };

  return {
    params,
    changePage,
    changePerPage,
    onSort,
    isSorted,
  };
};

export default usePagination;
