import {TDocumentStatuses} from "../@types/global";

export const errorToastLife = 5000;
export const successToastLife = 5000;

export const DocumentStatusKeys = {
  PARTIALLY_SIGNED: 'PARTIALLY_SIGNED',
  FULLY_SIGNED: 'FULLY_SIGNED',
  MAILED: 'MAILED',
  DELIVERED: 'DELIVERED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
  UNABLE_TO_PROCESS: 'UNABLE_TO_PROCESS',
  NONE: 'NONE',
};

export const documentStatusesLabels: Record<TDocumentStatuses, string> = {
  [DocumentStatusKeys.PARTIALLY_SIGNED]: 'Partially signed',
  [DocumentStatusKeys.FULLY_SIGNED]: 'Fully signed',
  [DocumentStatusKeys.MAILED]: 'Sent',
  [DocumentStatusKeys.DELIVERED]: 'Delivered',
  [DocumentStatusKeys.ACKNOWLEDGED]: 'Acknowledged',
  [DocumentStatusKeys.EXPIRED]: 'Expired',
  [DocumentStatusKeys.PENDING]: 'Pending',
  [DocumentStatusKeys.UNABLE_TO_PROCESS]: 'Unable to process',
  [DocumentStatusKeys.NONE]: 'None',
}

export const statusesConfig: Record<string, { label: string }> = {
  PENDING: {
    label: 'Requested',
  },
  FULLY_SIGNED: {
    label: 'Signed',
  },
  MAILED: {
    label: 'Sent',
  },
  DELIVERED: {
    label: 'Delivered',
  },
  ACKNOWLEDGED: {
    label: 'Acknowledged',
  },
};

export const DocumentStatusesOptions = [
  {
    value: DocumentStatusKeys.PARTIALLY_SIGNED,
    label: documentStatusesLabels[DocumentStatusKeys.PARTIALLY_SIGNED],
  },
  {
    value: DocumentStatusKeys.FULLY_SIGNED,
    label: documentStatusesLabels[DocumentStatusKeys.FULLY_SIGNED],
  },
  {
    value: DocumentStatusKeys.MAILED,
    label: documentStatusesLabels[DocumentStatusKeys.MAILED],
  },
  {
    value: DocumentStatusKeys.DELIVERED,
    label: documentStatusesLabels[DocumentStatusKeys.DELIVERED],
  },
  {
    value: DocumentStatusKeys.ACKNOWLEDGED,
    label: documentStatusesLabels[DocumentStatusKeys.ACKNOWLEDGED],
  },
  {
    value: DocumentStatusKeys.EXPIRED,
    label: documentStatusesLabels[DocumentStatusKeys.EXPIRED],
  },
  {
    value: DocumentStatusKeys.PENDING,
    label: documentStatusesLabels[DocumentStatusKeys.PENDING],
  },
  {
    value: DocumentStatusKeys.NONE,
    label: documentStatusesLabels[DocumentStatusKeys.NONE],
  },
]

export const perPageOptions = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
];

export const UserRoleKeys = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
}

export const UserRoleLabels = {
  [UserRoleKeys.ADMIN]: 'Admin',
  [UserRoleKeys.EMPLOYEE]: 'Employee',
};

export const userRoleOptions = [
  { label: UserRoleLabels[UserRoleKeys.ADMIN], value: UserRoleKeys.ADMIN },
  { label: UserRoleLabels[UserRoleKeys.EMPLOYEE], value: UserRoleKeys.EMPLOYEE },
];

export const transportTypes = {
  USPS: 'USPS',
  FEDEX: 'FEDEX',
};


