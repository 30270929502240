import { useMutation, useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { extractErrorMessage } from "../../../../helpers/extractErrorMessage";
import { useToast } from "../../../../hooks/useToast";

import { DocumentService } from "../../DocumentService";
import FilePreviewOverlay from "../../../../uiComponents/FilePreviewOverlay/FilePreviewOverlay";
import React from "react";

type Props = {
  orderNumber: string;
  onHide: () => void;
};

export const FilePreviewPopup = ({ orderNumber, onHide }: Props) => {
  const { show } = useToast();

  const { data: fileUrl, isLoading } = useQuery({
    queryKey: DocumentService.generatedPDFPreview.queryKey(orderNumber),
    queryFn: DocumentService.generatedPDFPreview.queryFn,
    staleTime: 0,
  });

  const submitDocumentObj =
    useMutation({
      onError: (error: AxiosError<{ message: string }>, variables) => {
        const errorData = {
          error: error,
          variables: variables,
          retryFn: submitDocumentObj.mutate,
          show: show,
        };
        extractErrorMessage(errorData);
      },
      onSuccess: () => {
        show({ detail: "Document submitted successfully!" });
        onHide();
      },
      mutationFn: DocumentService.generatedPDFDetails.sendSCM,
    });

  return (
      <FilePreviewOverlay
          preview={fileUrl}
          closePreview={onHide}
          isLoading={isLoading}
          hideDownloadButton
      />
  )
};
