export const ADD_LOADER = 'SW-Embedder.addLoader'
export const CLICK_AWAY = 'SW-Embedder.clickAway'
export const CLICKED_CONTINUE_BTN = 'SW-document.clickedContinueBtn'
export const DOCUMENT_CLOSED = 'SW-document.closed'
export const DOCUMENT_ERROR = 'SW-document.error'
export const DOCUMENT_COMPLETE_SELF_SIGNING = 'SW-document.completeSelfSigning'
export const DOCUMENT_COMPLETED = 'SW-document.completed'
export const DOCUMENT_DECLINED = 'SW-document.declined'
export const DOCUMENT_LOADED = 'SW-document.documentLoaded'
export const NOTIFY_REDIRECTION_ERROR = 'SW-Embedder.notifyRedirectionError'
export const NOTIFY_REDIRECTION_START = 'SW-Embedder.notifyRedirectionStart'
export const REMOVE_LOADER = 'SW-Embedder.removeLoader'
export const RESIZING = 'SW-Embedder.resizing'
export const SET_ATTR = 'SW-Embedder-SetAttr' // iframeLoaded
export const SET_ID = 'SW-document.setId'

export const EMBEDDED_DOCUMENT_LOADED_EVENT = 'embedded_document_loaded'
export const EMBEDDED_ERROR_EVENT = 'embedded_document_error'
export const EMBEDDED_IFRAME_LOADED_EVENT = 'embedded_iframe_loaded'

export default {
  ADD_LOADER,
  CLICK_AWAY,
  CLICKED_CONTINUE_BTN,
  DOCUMENT_CLOSED,
  DOCUMENT_COMPLETE_SELF_SIGNING,
  DOCUMENT_COMPLETED,
  DOCUMENT_DECLINED,
  DOCUMENT_ERROR,
  DOCUMENT_LOADED,
  EMBEDDED_DOCUMENT_LOADED_EVENT,
  EMBEDDED_ERROR_EVENT,
  EMBEDDED_IFRAME_LOADED_EVENT,
  NOTIFY_REDIRECTION_ERROR,
  NOTIFY_REDIRECTION_START,
  REMOVE_LOADER,
  RESIZING,
  SET_ATTR,
  SET_ID
}
