import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {Nullable} from "primereact/ts-helpers";

export type TConfigType = 'EMAIL' | 'NUMERIC' | 'STRING' | 'ENUM' | 'DATE' | 'TEXTAREA' | 'DECIMAL' | 'SSN';
export type TValidationSchemeBaseItem = {
  required: boolean;
  configType: TConfigType;
  configName: string;
  step: number;
};

export type TDataItem = {
  comment: string;
  configName: string;
  configType: TConfigType;
  label: string;
  step: number;
  action: Nullable<string>;
  options: Nullable<{ label: string; value: string }[]>;
  value: Nullable<string>;
  defaultValue: Nullable<string>;
  configGroup: Nullable<string>;
  required: boolean;
  readOnly: boolean;
};

export type TFormStep = {
  active: number;
  total: number;
}

export interface IFormProps {
  fieldsConfig: TDataItem[];
  addressGroupFields: Record<string, TDataItem[]>;
  onSubmit: (
    values: Record<string, string | number | Date | undefined>,
  ) => void;
  negativeValueWarning: boolean;
  isSupposeExists: boolean;
  setSuppose: (isExists: boolean) => void;
  captureFirstRecipientEmailAfterEmailFieldOnBlur: () => void;
  isAi?: boolean;
  form: UseFormReturn<Record<string, string | number | Date | undefined>>;
  active: number;
  total: number;
  setFormStep: React.Dispatch<React.SetStateAction<TFormStep>>;
  isPublic?: boolean;
  showSelectTemplate: boolean;
  disableFiling?: boolean;
  isPublicDraft?: boolean;
}

export const AI_PREFIX = 'ai-';
export const templateCodes = {
  '83-b-election': 'L7P1T1',
  '83-b-election-spouse': 'L7P1T2',
  [`${AI_PREFIX}83-b-election`]: 'L7P1T3',
  [`${AI_PREFIX}83-b-election-spouse`]: 'L7P1T4',
};

export type TStep = 'filling' | 'review' | 'terms' | 'finish' | 'completed' | 'signature' | 'confirmation' | 'pay';

export const stepsKeys: Record<TStep, TStep> = {
  filling: 'filling',
  review: 'review',
  terms: 'terms',
  finish: 'finish',
  completed: 'completed',
  signature: 'signature',
  confirmation: 'confirmation',
  pay: 'pay',
};

export type TStepConfigItem = {
  name: TStep;
  label: string;
  iconClassName: string;
  subLabel?: string;
};

export const stepsConfig: TStepConfigItem[] = [
  {
    name: stepsKeys.filling,
    label: 'Draft',
    iconClassName: 'pi pi-file-edit',
  },
  {
    name: stepsKeys.review,
    label: 'Review',
    iconClassName: 'pi pi-search',
  },
  {
    name: stepsKeys.terms,
    label: 'Terms',
    iconClassName: 'pi pi-file',
  },
  {
    name: stepsKeys.finish,
    label: 'Sign',
    iconClassName: 'pi pi-pencil',
  }
];

export const stepsConfigWithEmbeddedSignature: TStepConfigItem[] = [
  {
    name: stepsKeys.filling,
    label: 'Draft',
    iconClassName: 'pi pi-file-edit',
  },
  {
    name: stepsKeys.review,
    label: 'Review',
    iconClassName: 'pi pi-search',
  },
  {
    name: stepsKeys.terms,
    label: 'Terms',
    iconClassName: 'pi pi-file',
  },
  {
    name: stepsKeys.pay,
    label: 'Pay',
    iconClassName: 'pi pi-money-bill',
    // subLabel: '(paid service only)',
  },
  {
    name: stepsKeys.finish,
    label: 'Sign',
    iconClassName: 'pi pi-pencil',
  }
];

export const publicStepsConfig: TStepConfigItem[] = [
  {
    name: stepsKeys.filling,
    label: 'Draft',
    iconClassName: 'pi pi-file-edit',
  },
  {
    name: stepsKeys.review,
    label: 'Review',
    iconClassName: 'pi pi-search',
  },
  {
    name: stepsKeys.terms,
    label: 'Terms',
    iconClassName: 'pi pi-file',
  },
  {
    name: stepsKeys.finish,
    label: 'Select Service',
    iconClassName: 'pi pi-pencil',
  },
  {
    name: stepsKeys.pay,
    label: 'Pay',
    iconClassName: 'pi pi-money-bill',
    subLabel: '(paid service only)',
  },
  {
    name: 'signature',
    label: 'Sign',
    iconClassName: 'pi pi-user-edit',
    subLabel: '(paid service only)',
  },
  {
    name: 'confirmation',
    label: 'Confirmation',
    iconClassName: 'pi pi-check',
  },
];

export const publicOpenAIStepsConfig: TStepConfigItem[] = [
  {
    name: stepsKeys.filling,
    label: 'Draft',
    iconClassName: 'pi pi-file-edit',
  },
  {
    name: stepsKeys.review,
    label: 'Review',
    iconClassName: 'pi pi-search',
  },
  {
    name: stepsKeys.terms,
    label: 'Terms',
    iconClassName: 'pi pi-file',
  },
  {
    name: stepsKeys.finish,
    label: 'Service Details',
    iconClassName: 'pi pi-pencil',
  },
  {
    name: stepsKeys.pay,
    label: 'Pay',
    iconClassName: 'pi pi-money-bill',
  },
  {
    name: 'signature',
    label: 'Sign',
    iconClassName: 'pi pi-user-edit',
  },
  {
    name: 'confirmation',
    label: 'Confirmation',
    iconClassName: 'pi pi-check',
  },
];

export const monthsConfig: Record<string, string> = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};
