
export enum TermsTypeKeys {
    TERMS_FOR_83B = 'TERMS_FOR_83B',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
}

export const TermsKeysLabels = {
    [TermsTypeKeys.TERMS_FOR_83B]: 'Terms for 83b',
    [TermsTypeKeys.PRIVACY_POLICY]: 'Privacy Policy',
    [TermsTypeKeys.TERMS_OF_SERVICE]: 'Terms of Service',
}

export type TTermTypes = TermsTypeKeys.TERMS_FOR_83B | TermsTypeKeys.PRIVACY_POLICY | TermsTypeKeys.TERMS_OF_SERVICE;

export type TPolicyVersions = {
    currentPolicyVersion: string;
    currentTermsVersion: string;
    forceAccept: boolean;
    userPolicyVersion: string;
    userTermsVersion: string;
}