import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

interface TokenData {
  id: number;
  username: string;
  "cognito:groups": string[]; // Adding the "cognito:groups" key
}

export const useIsSuperAdmin = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const storedToken =
      localStorage.getItem("accessToken");
    if (storedToken) {
      const decoded: TokenData = jwtDecode(storedToken);
      const cognitoGroup = decoded?.['cognito:groups'] || [];
      setIsSuperAdmin(cognitoGroup.includes("Admin"));
    }
  }, []);

  return isSuperAdmin;
};
