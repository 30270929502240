import React, { FC, useMemo } from 'react';
import styles from './stepsControl.module.scss';
import {classNames} from "primereact/utils";

export type TStep =
    'BUSINESS_INFORMATION' |
    'LATE_ELECTION_JUSTIFICATION' |
    'SHAREHOLDER_INFORMATION' |
    'SIGNER_INFORMATION' |
    'PROCESS';

export const stepsKeys: Record<TStep, TStep> = {
    BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
    LATE_ELECTION_JUSTIFICATION: 'LATE_ELECTION_JUSTIFICATION',
    SHAREHOLDER_INFORMATION: 'SHAREHOLDER_INFORMATION',
    SIGNER_INFORMATION: 'SIGNER_INFORMATION',
    PROCESS: 'PROCESS',
};

interface TStepsControlProps {
    currentStep: TStep,
    isLateElection: boolean;
}
const StepsControl: FC<TStepsControlProps> = (
{
    currentStep,
    isLateElection,
}) => {
    const steps = useMemo(() => {
        return (
            [
                {
                    name: stepsKeys.BUSINESS_INFORMATION,
                    label: 'Business',
                    iconClassName: 'pi pi-file',
                },
                ...(
                    isLateElection ? [{
                        name: stepsKeys.LATE_ELECTION_JUSTIFICATION,
                        label: 'Late election',
                        iconClassName: 'pi pi-file',
                    }] : []
                ),
                {
                    name: stepsKeys.SHAREHOLDER_INFORMATION,
                    label: 'Shareholder',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.SIGNER_INFORMATION,
                    label: 'Signer',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.PROCESS,
                    label: 'Process',
                    iconClassName: 'pi pi-file',
                },
            ]
        )
    }, [isLateElection])
    return (
        <div className={styles.stepsControl}>
            {
                steps.map((item, index) => {
                    const { label, iconClassName, name } = item;
                    const isCurrent = name === currentStep;
                    const currentStepIndex = steps.findIndex(item => item.name === currentStep);
                    const progressFilled = currentStepIndex >= index - 1;
                    const progressGreen = currentStepIndex >= index;
                    const isCompleted = currentStepIndex > index;
                    return (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <div className={styles.lineItem}>
                                    <span className={styles.track}>
                                        <span className={classNames(styles.bar, {
                                            [styles.barInProgress]: progressFilled,
                                            [styles.barCompleted]: progressGreen,
                                        })}
                                        />
                                    </span>
                                </div>
                            )}
                            <div className={classNames(styles.stepItem, {
                                [styles.current]: !isCompleted && isCurrent,
                                [styles.completed]: isCompleted,
                            })}>
                                <div className={styles.stepWidget}>
                                    <div className={styles.iconHolder}>
                                        <i className={isCompleted ? 'pi pi-check' : iconClassName} />
                                    </div>
                                </div>
                                <div className={styles.labels}>
                                    <p>{`STEP ${index + 1}`}</p>
                                    <p>{label}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

export default StepsControl;
