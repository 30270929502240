import React, { FC } from 'react';
import styles from './draft83bFilledModal.module.scss';
import {useNavigate} from "react-router-dom";

interface TDraft83bFilledModalProps {
    onClose: () => void;
    filingCreationEnabled: boolean;
}

const Draft83bFilledModal: FC<TDraft83bFilledModalProps> = ({ onClose, filingCreationEnabled }) => {
    const navigate = useNavigate();
    const goToDashboard = () => {
        navigate('/admin-dashboard');
    }
    const goToNewFiling = () => {
        navigate('/filing/file-83b');
        onClose();
    }
    return (
        <div className={styles.draft83bFilledModalOverlay}>
            <div className={styles.modal}>
                <p className={styles.modalMessage}>You have already submitted this 83(b) election. What would you like to do next?</p>
                <div className={styles.listWrapper}>
                    <ul>
                        <li>
                            <button type="button" onClick={goToDashboard}>Redirect to the dashboard</button>
                        </li>
                        {
                            filingCreationEnabled && (
                                <li>
                                    <button type="button" onClick={goToNewFiling}>Create a new form</button>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Draft83bFilledModal;
