import React, { FC } from 'react';

import styles from './header.module.scss';
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Account from "./Account";
interface THeaderProps {
    userLogOut: () => void;
    fullName: string;
    email: string;
}
const Header: FC<THeaderProps> = ({ userLogOut, fullName, email }) => {
  return (
      <div className={styles.header}>
        <Breadcrumbs />
        <Account
            userLogOut={userLogOut}
            fullName={fullName}
            email={email}
        />
      </div>
  );
};

export default Header;
