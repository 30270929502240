import React, { FC } from 'react';
import styles from './fileItem.module.scss';
import {TDocumentStatuses, TFile83bData} from "../../../../../@types/global";
import {formatToStandardDateUTC} from "../../../../../helpers/convertDates";
import Activities from "../../../../AdminDashboard/ActivitiesAndDocuments/Activities";
import Documents from "../../../../AdminDashboard/ActivitiesAndDocuments/Documents";
import {useNavigate} from "react-router-dom";
import StatusPill from "../../../../../uiComponents/StatusPill/StatusPill";

interface TFileItemProps {
    data: TFile83bData;
    companyId: number;
}

const FileItem:FC<TFileItemProps> = ({ data, companyId }) => {
    const {
        activities,
        deadLine,
        grantDate,
        status,
        documents,
        trackingNumber,
        updateDateTime,
        cutOffPast,
        deadlinePast,
    } = data;
    const navigate = useNavigate();
    const onContinueToFilling = () => {
        navigate(`/filing/file-83b?fileId=${data.id}`);
    }
    return (
        <div className={styles.fileItem}>
            <div className={styles.itemHeader}>
                <div className={styles.itemValue}>
                    <span>Grant Date:</span>
                    <span>{formatToStandardDateUTC(grantDate, ' ')}</span>
                </div>
                <div className={styles.itemValue}>
                    <span>Deadline:</span>
                    <span>{formatToStandardDateUTC(deadLine, ' ')}</span>
                </div>
                <div className={styles.itemValue}>
                    <span>Status:</span>
                    <span>
                        <StatusPill
                            status={status as TDocumentStatuses}
                            styleType="secondary"
                        />
                    </span>
                </div>
                <div className={styles.itemValue}>
                    <span>Latest update:</span>
                    <span>{formatToStandardDateUTC(updateDateTime)}</span>
                </div>
                <div className={styles.itemValue}>
                    <span>Tracking Number:</span>
                    <span>{trackingNumber || '-'}</span>
                </div>
            </div>
            <div className={styles.rowDetails}>
                <div className={styles.col}>
                    <Activities data={activities}/>
                </div>
                <div className={styles.col}>
                    <Documents
                        data={documents}
                        companyId={companyId}
                        status={status}
                        onContinueToFilling={onContinueToFilling}
                        cutOffPast={cutOffPast}
                    />
                </div>
            </div>
        </div>
    )
}

export default FileItem;
