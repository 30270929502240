import React, {FC, useMemo} from 'react';
import styles from '../shared.module.scss';
import LateElectionForm from "./LateElectionForm";
import {SubmitHandler} from "react-hook-form";
import {TLateElectionFormValues} from "./types";
import {useToast} from "../../../../../hooks/useToast";
import {useMutation} from "@tanstack/react-query";
import {Form2553Service} from "../../../Service";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {stepsKeys, TStep} from "../../StepsControl/StepsContro";
import {TForm2553Information} from "../../CreationControl/types";

interface TLateElectionProps {
    companyId: number;
    formId: string;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    form2553Information: TForm2553Information;
    refetch2553Data: () => void;
}

const LateElection: FC<TLateElectionProps> = ({
companyId,
formId ,
setStep,
form2553Information,
refetch2553Data,
}) => {
    const { show } = useToast();
    const postLateElectionJustification = useMutation({
        mutationFn: Form2553Service.postLateElectionJustification,
        onSuccess: () => {
            refetch2553Data();
            setStep(stepsKeys.SHAREHOLDER_INFORMATION);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postLateElectionJustification.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit: SubmitHandler<TLateElectionFormValues> = (values) => {
        postLateElectionJustification.mutate({ params: values, companyId, formId });
    }

    const lateJustificationFormInitialValues = useMemo(
        () => {
            const justificationText = form2553Information?.lateElectionJustification?.justificationText;
            return {
                justificationText: justificationText || '',
            }
        }, [form2553Information]
    );

    const onPrev = () => {
        setStep(stepsKeys.BUSINESS_INFORMATION);
    }

    return (
        <div className={styles.stepSection}>
            <div className={styles.formColumn}>
                <LateElectionForm
                    onSubmit={onSubmit}
                    initialValues={lateJustificationFormInitialValues}
                    onPrev={onPrev}
                />
            </div>
        </div>
    )
}

export default LateElection;
