import React, { FC } from 'react';
import {TDraftUser} from "../DraftUsers";
import styles from '../draftUsers.module.scss';
import TableDataHolder from "./TableDataHolder";
import {TReactQueryReFetch} from "../../../../@types/global";
import ConfirmDialogCustom from "../../../../uiComponents/ConfirmDianlogCustom/ConfirmDialogCustom";

const labelObj = {
    header: `Deleting a draft record`,
    message: 'You are about to delete this draft record. Do you wish to continue?',
    successLabel: 'Success delete',
    confirmLabel: 'Delete',
}

interface TTableBodyProps {
    draftUsersList: TDraftUser[];
    columnsConfig: { key: string; label: string }[];
    companyId: number;
    refetchDraftUsers: TReactQueryReFetch;
    setTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    deleteUserId: number | null;
    setDeleteUserId: React.Dispatch<React.SetStateAction<number | null>>;
    onDeleteSingleUser: (rowId: number) => void;
}
const TableBody:FC<TTableBodyProps> = ({
draftUsersList = [],
columnsConfig = [],
companyId,
refetchDraftUsers,
setTableLoading,
deleteUserId,
setDeleteUserId,
onDeleteSingleUser,
}) => {
    const onAcceptUserDeletion = () => {
        if (deleteUserId) {
            setTableLoading(true);
            onDeleteSingleUser(deleteUserId);
        } else {
            setDeleteUserId(null);
        }
    }
    return (
        <tbody>
        {
            draftUsersList.map((item) => {
                const {
                    id,
                } = item;
                return (
                    <tr key={id} className={styles.tableRow}>
                        {
                            columnsConfig.map((configItem) => {
                                const { key, label } = configItem;
                                return (
                                    <TableDataHolder
                                        key={key}
                                        columnName={key}
                                        rowData={item}
                                        label={label}
                                        rowId={id as number}
                                        refetchDraftUsers={refetchDraftUsers}
                                        companyId={companyId}
                                        setTableLoading={setTableLoading}
                                    />
                                )
                            })
                        }
                        <td className={styles.tableData}>
                            <button type="button" className={styles.actionBtn} onClick={() => setDeleteUserId(id as number)}>
                                <i className="bi bi-x"></i>
                            </button>
                        </td>
                    </tr>
                )
            })
        }
        <ConfirmDialogCustom
            header={labelObj.header}
            acceptLabel={labelObj.confirmLabel}
            rejectLabel="Cancel"
            message={labelObj.message}
            visible={Boolean(deleteUserId)}
            onHide={() => setDeleteUserId(null)}
            accept={() => onAcceptUserDeletion()}
        />
        </tbody>
    )
}

export default TableBody;