import React, { FC } from 'react';
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {useMutation} from "@tanstack/react-query";
import {requests} from "../../../../services/http-common";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../hooks/useToast";
import {TReactQueryReFetch} from "../../../../@types/global";

interface TAddRowManuallyProps {
    columnHeaders: { key: string; label: string }[];
    companyId: number;
    refetchDraftUsers: TReactQueryReFetch;
    className?: string;
    successCb?: () => void;
}

const AddRowManually: FC<TAddRowManuallyProps> = ({
columnHeaders,
companyId,
refetchDraftUsers,
className,
successCb,
}) => {
    const { show } = useToast();
    const manuallyAddRow = useMutation({
        mutationFn: async (params: Record<string, string>) => {
            return await requests.post(
                `api/companies/${companyId}/file83b-draft`,
                params,
            );
        },
        onSuccess: () => {
            if (successCb) {
                successCb();
            }
            refetchDraftUsers();
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: manuallyAddRow.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const rowAdditionInProgress = manuallyAddRow?.isPending;
    const onAddRow = () => {
        const params: Record<string, string> = {};
        columnHeaders.forEach(({ key }) => {
            params[key] = '';
        });
        manuallyAddRow.mutate(params);
    }
    return (
        <ButtonPrimary
            label="+ Add row manually"
            onClick={onAddRow}
            disabled={rowAdditionInProgress || columnHeaders.length === 0}
            styleType="secondaryOutline"
            className={className}
        />
    )
}

export default AddRowManually;
