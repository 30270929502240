import React from 'react';
import styles from './sidebar.module.scss';
import { classNames } from 'primereact/utils';

const SidebarUiPlaceholder = () => {
  return (
    <div className={styles.sidebarPlaceholder} style={{ opacity: `0.3` }}>
      <div className={classNames(styles.w2)} />
      <div className={styles.w3}>
        <span>
          <span className={classNames(styles.itemItem)}/>
          <span className={classNames(styles.itemItem)}/>
        </span>
        <span>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
        </span>
        <span>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
        </span>
        <span>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
        </span>
        <span>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
        </span>
        <span>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
          <span className={classNames(styles.itemItem, styles.shimmer)}/>
        </span>
      </div>
      {/*<div className={styles.w4}/>*/}
      {/*<div className={styles.w3}>*/}
      {/*  <span>*/}
      {/*    <span className={classNames(styles.itemItem, styles.shimmer)}/>*/}
      {/*    <span className={classNames(styles.itemItem, styles.shimmer)} />*/}
      {/*  </span>*/}
      {/*  <span>*/}
      {/*    <span className={classNames(styles.itemItem, styles.shimmer)} />*/}
      {/*    <span className={classNames(styles.itemItem, styles.shimmer)} />*/}
      {/*  </span>*/}
      {/*</div>*/}
    </div>
  )
}

export default SidebarUiPlaceholder;
