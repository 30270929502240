import React, { FC } from 'react';
import styles from '../draftUsers.module.scss';

interface TTableHeadProps {
    columnsConfig: { key: string; label: string }[];
}
const TableHead:FC<TTableHeadProps> = ({ columnsConfig }) => {
    return (
        <thead>
        <tr>
            {
                columnsConfig.map((item, index) => {
                    return (
                        <th key={index} className={styles.tableHead}>{item.label}</th>
                    )
                })
            }
            <th className={styles.tableHead} />
        </tr>
        </thead>
    )
}

export default TableHead;
