import { Button } from "primereact/button";
import { useState } from "react";
import { UploadDocPopup } from "../../UploadDocPopup/UploadDocPopup";
import { Document } from "../../../types";

type PropTypes = {
  document: Document;
};

export const UploadDocBodyTemplate = ({ document }: PropTypes) => {
  const [showUploadDocPopup, setShowUploadDocPopup] = useState(false);
  return (
    <>
      <Button
        className={"p-button-rounded p-button-text"}
        icon="pi pi-upload"
        onClick={() => setShowUploadDocPopup(true)}
        disabled={!document.trackingNumber}
      />
      {showUploadDocPopup && (
        <UploadDocPopup
          orderNumber={document.orderNumber}
          taxpayerName={document.purchaserName}
          onHide={() => setShowUploadDocPopup(false)}
        />
      )}
    </>
  );
};
