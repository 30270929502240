import { Button } from "primereact/button";
import { Document, FilterQueryParams } from "../../../types";
import { useToast } from "../../../../../hooks/useToast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { extractErrorMessage } from "../../../../../helpers/extractErrorMessage";
import { DocumentService } from "../../../DocumentService";
import ConfirmDialogCustom from "../../../../../uiComponents/ConfirmDianlogCustom/ConfirmDialogCustom";
import React, {useState} from "react";
import deleteIcon from '../../../../../assets/images/delete-icon.svg';

type PropTypes = {
  document: Document;
};

export const DeleteDocBodyTemplate = ({ document }: PropTypes) => {
  const queryClient = useQueryClient();
  const { show } = useToast();
  const [isDeleteModalOpened, toggleDeleteModal] = useState<boolean>(false);

  const removeDocument = useMutation({
    onError: (error: AxiosError<{ detail: string }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: removeDocument.mutate,
        show: show,
      };
      extractErrorMessage(errorData);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: DocumentService.documents.queryKey({} as FilterQueryParams),
      });
      show({ detail: "Document deleted successfully!" });
    },
    mutationFn: DocumentService.document.delete,
  });

  const handleDeleteDocument = () => {
    removeDocument.mutate(document.id)
  };

  return (
      <>
        <ConfirmDialogCustom
            header="Deleting the document"
            acceptLabel="Confirm"
            rejectLabel="Cancel"
            message="Are you sure you want to delete the document?"
            visible={Boolean(isDeleteModalOpened)}
            onHide={() => toggleDeleteModal(false)}
            accept={handleDeleteDocument}
            iconSrc={deleteIcon}
        />
        <Button
            className={"p-button-rounded p-button-text"}
            icon="pi pi-trash"
            severity="danger"
            onClick={() => toggleDeleteModal(true)}
        />
      </>
  );
};
