import { Button } from "primereact/button";
import styles from "./uploadDocPopup.module.scss";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { DocumentStatus, FilterQueryParams } from "../../types";
import { ChangeEvent, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DocumentService } from "../../DocumentService";
import {documentStatusesLabels, successToastLife} from "../../../../config/config";
import { useToast } from "../../../../hooks/useToast";
import { AxiosError } from "axios";
import Loading from "../../../../components/Loading/Loading";
import {extractErrorMessage} from "../../../../helpers/extractErrorMessage";

const orderStatuses = [
  { label: documentStatusesLabels[DocumentStatus.MAILED], value: DocumentStatus.MAILED },
  { label: documentStatusesLabels[DocumentStatus.DELIVERED], value: DocumentStatus.DELIVERED },
  { label: documentStatusesLabels[DocumentStatus.ACKNOWLEDGED], value: DocumentStatus.ACKNOWLEDGED },
];

type PropTypes = {
  taxpayerName: string;
  orderNumber: string;
  onHide: VoidFunction;
};

export const UploadDocPopup = ({
  taxpayerName,
  orderNumber,
  onHide,
}: PropTypes) => {
  const [docStatus, setDocStatus] = useState<DocumentStatus | null>(null);
  const [fileDetails, setFileDetails] = useState<{
    file: File | null;
    fileName: string;
  }>({
    file: null,
    fileName: "",
  });

  const { show } = useToast();
  const queryClient = useQueryClient();

  const isFileChosen = !!fileDetails.file;
  const isActionEnabled = (Boolean(docStatus) && isFileChosen) || (docStatus === 'MAILED');

  const { mutate: uploadDoc, isPending: isUploading } = useMutation({
    mutationFn: DocumentService.document.uploadDoc,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: DocumentService.documents.queryKey({} as FilterQueryParams),
      });
      show({
        life: successToastLife,
        severity: "success",
        detail: "Success",
      });
      onHide();
    },
    onError: (error: AxiosError<{ fieldErrors: Record<string, string> }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: uploadDoc,
        show: show,
      };
      extractErrorMessage(errorData);
    },
  });

  const handleUpload = () => {
    if ((!fileDetails.file && docStatus !== 'MAILED') || !docStatus) return;
    const formData = new FormData();
    if (fileDetails.file) {
      formData.append("file", fileDetails?.file);
      formData.append("name", fileDetails?.fileName);
    }
    const payload = {
      formData: formData,
      orderNumber,
      status: docStatus,
    };
    uploadDoc(payload);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const uploadingFile = e.target.files[0];
      setFileDetails({
        fileName: uploadingFile.name,
        file: uploadingFile,
      });
    }
  };

  return (
    <div className={styles.uploadOverlay}>
      <div className={styles.uploadControl}>
        {
            isUploading && (
                <div className={styles.uploadingLoading}>
                  <Loading />
                </div>
            )
        }
        <p>{taxpayerName}</p>
        <Button
          className={classNames(
            "p-button-rounded p-button-text",
            styles.closeOverlay
          )}
          onClick={onHide}
          icon="pi pi-times"
        />
        <Dropdown
          onChange={(e) => setDocStatus(e.target.value)}
          style={{ width: "100%" }}
          options={orderStatuses}
          value={docStatus}
        />
        <div className={styles.uploadRow}>
          <div className={styles.inputUpload}>
            <i className="pi pi-upload" />
            <p>{isFileChosen ? "Change file" : "Choose file"}</p>
            <input onChange={handleFileChange} accept=".pdf" type="file" />
          </div>
          {isFileChosen && (
            <p className={styles.fileName}>{fileDetails.fileName}</p>
          )}
        </div>
        <Button
          className={styles.applyFileBtn}
          disabled={!isActionEnabled || isUploading}
          onClick={handleUpload}
          label="Upload"
        />
      </div>
    </div>
  );
};
