import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import styles from './termsUpdate.module.scss';
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import 'react-quill/dist/quill.snow.css';
import {useMutation, useQuery} from "@tanstack/react-query";
import {TermsService} from "./TermsService";
import {TTermTypes} from "./utils";
import {successToastLife} from "../../config/config";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import Loading from "../../components/Loading/Loading";
import HtmlEditor from "../../components/HtmlEditor/HtmlEditor";
import EditorResult from "../../components/HtmlEditor/EditorResult";

const TermsUpdate = () => {
    const { policyType } = useParams<{ policyType: TTermTypes }>();
    const [content, setContent] = useState<string>('');
    const [version, setVersion] = useState<string>('');
    const [isForceUpdate, setForceUpdate] = useState<boolean>(false);
    const { show } = useToast();

    const { data: latestPolicyContentData, isLoading, refetch } = useQuery({
        queryKey: ['latest-policy-content'],
        queryFn: () => TermsService.getLatestContentByType(policyType as TTermTypes),
        gcTime: 0,
        staleTime: 0,
    });
    const latestPolicyContent = latestPolicyContentData?.data;
    const policyContent = latestPolicyContent?.text;
    const policyVersion = latestPolicyContent?.version;

    const createNewTerm = useMutation({
        mutationFn: TermsService.createTerm,
        onSuccess: () => {
            refetch();
            show({
                severity: 'success',
                detail: 'Updated successfully!',
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: createNewTerm.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onCreateNewTerm = () => {
        const params = {
            version: version,
            text: content,
            type: policyType as TTermTypes,
            forceAccept: isForceUpdate,
        }
        createNewTerm.mutate(params);
    }

    const handleContentChange = (value: string) => {
        setContent(value);
    };

    useEffect(() => {
        if (!content) {
            setContent(policyContent);
        }
    }, [policyContent]);

    useEffect(() => {
        if (version !== policyVersion) {
            setVersion(policyVersion);
        }
    }, [policyVersion]);
    return (
        <div className={styles.termsUpdate}>
            <div className={styles.rowsWrapper}>
                <div className={styles.editorWrapper}>
                    <div className={styles.quillWrapper}>
                        {
                            (isLoading || !content) ? (
                                <Loading horizontalSpacing={150} />
                                ) : (
                                <HtmlEditor
                                    content={content}
                                    handleContentChange={handleContentChange}
                                />
                            )
                        }
                    </div>
                    <div className={styles.bottomControl}>
                        <InputText
                            className={styles.versionField}
                            style={{width: '100%'}}
                            value={version}
                            placeholder="Version"
                            autoComplete="new-password"
                            onChange={(e) => {
                                setVersion(e.target.value);
                            }}
                        />
                        <div className={styles.checkboxHolder}>
                            <Checkbox
                                inputId="inputid"
                                name="terms"
                                onChange={() => setForceUpdate(!isForceUpdate)}
                                checked={isForceUpdate}
                            />
                            <label htmlFor="inputid">Force user to accept this terms.</label>
                        </div>
                        <ButtonPrimary
                            className={styles.btnCustom}
                            disabled={!version || (version === policyVersion)}
                            onClick={onCreateNewTerm}
                            label="Save"
                        />
                    </div>
                </div>
                <div className={styles.editorResultColumn}>
                    <EditorResult
                        content={content}
                        isEditing
                    />
                </div>
            </div>
        </div>
    )
}

export default TermsUpdate;
