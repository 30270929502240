import React, { useMemo, useState, useRef, FC, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import {Chart as ChartJS, Tooltip, Legend, ArcElement, ChartData, ChartEvent, ActiveElement} from 'chart.js';
import styles from './dashboard.module.scss';
import {useMutation, useQuery} from "@tanstack/react-query";
import {requests} from "../../services/http-common";
import Loading from "../../components/Loading/Loading";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";
import TableData from "./TableData";
import StatusPill from "../../uiComponents/StatusPill/StatusPill";
import {TDocumentStatuses} from "../../@types/global";
import {useLocation} from "react-router-dom";
import {documentStatusesLabels, DocumentStatusKeys} from "../../config/config";

ChartJS.register(Tooltip, Legend, ArcElement);

const dataConfig: Record<string, {
    label: string;
    color: string;
}> = {
    FULLY_SIGNED: {
        label: documentStatusesLabels[DocumentStatusKeys.FULLY_SIGNED],
        color: '#2DB976',
    },
    PENDING: {
        label: documentStatusesLabels[DocumentStatusKeys.PENDING],
        color: '#FCB900',
    },
    MAILED: {
        label: documentStatusesLabels[DocumentStatusKeys.MAILED],
        color: '#35B4F1',
    },
    DELIVERED: {
        label: documentStatusesLabels[DocumentStatusKeys.DELIVERED],
        color: '#FF5F00',
    },
    ACKNOWLEDGED: {
        label: documentStatusesLabels[DocumentStatusKeys.ACKNOWLEDGED],
        color: '#9AE66E',
    }
}

interface TAdminDashboardProps {
    companyId: number;
    hasSpouseFeature: boolean;
    email: string;
}

const AdminDashboard: FC<TAdminDashboardProps> = ({
companyId,
hasSpouseFeature,
email,
}) => {
    const [namesByStatus, setNamesByStatus] = useState([]);
    const selectedSegmentRef = useRef<string>('');
    const [selectedSegment, setSelectedSegment] = useState('');
    const color = selectedSegment ? dataConfig[selectedSegment]?.color : '';
    const label = selectedSegment ? dataConfig[selectedSegment]?.label : '';
    const { show } = useToast();
    const { pathname } = useLocation();
    const {
        data: countByStatusData,
        isLoading: countByStatusDataIsLoading,
        refetch,
    } = useQuery({
        queryKey: ['count-by-status', companyId],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/file83b/count-by-status`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
    });

    const refetchCountsByStatusData = () => {
        refetch();
    }

    const getNamesByStatusData = useMutation({
        mutationFn: async (status: string) => {
            return await requests.get(
                `/api/companies/${companyId}/file83b/file83b-by-status/${status}`
            );
        },
        onSuccess: (response) => {
            setNamesByStatus(response);
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getNamesByStatusData.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const namesByStatusIsLoading =  getNamesByStatusData?.isPending;

    const countByStatusKeys = Object.keys(countByStatusData || {});
    const combinedConfig = useMemo(
        () => {
            const config: {
                label: string;
                value: number;
                key: string;
            }[] = [];
            let total = 0;
            const data: number[] = [];
            const backgroundColor: string[] = [];
            const segmentNames: string[] = [];
            countByStatusKeys.forEach((key) => {
                const value = countByStatusData[key];
                let label = key;
                let color = '';
                if (Object.prototype.hasOwnProperty.call(dataConfig, key)) {
                    label = dataConfig[key].label;
                    color = dataConfig[key].color;
                }
                total += value;
                data.push(value);
                backgroundColor.push(color);
                segmentNames.push(key);
                config.push({
                    label,
                    value,
                    key,
                });
            });
            const percentData = data.map((value) => {
                return (value / total) * 100;
            });
            return {
                config,
                datasets: [
                    {
                        data: percentData,
                        backgroundColor: backgroundColor,
                        dataValues: data,
                    }
                ],
                segmentNames,
            }
        }, [countByStatusData, countByStatusKeys]
    );
    const { config, datasets, segmentNames } = combinedConfig;
    const onChartHover = (event: ChartEvent, elements: ActiveElement[]) => {
        if (elements.length > 0) {
            const segmentIndex = elements[0].index;
            const segmentLName = segmentNames[segmentIndex];
            if (segmentLName && selectedSegmentRef.current !== segmentLName) {
                selectedSegmentRef.current = segmentLName;
                getNamesByStatusData.mutate(segmentLName);
                setSelectedSegment(segmentLName);
            }
        }
    }
    const chartData: ChartData<'pie'> = {
        labels: [],
        datasets: datasets,
    };

    const plugins = {
        tooltip: {
            callbacks: {
                label: function (tooltipItem: { dataset: { dataValues: [] }, dataIndex: number }) {
                    const dataset = tooltipItem.dataset;
                    const dataValues = dataset.dataValues;
                    const dataIndex = tooltipItem.dataIndex;
                    const value = dataValues[dataIndex];
                    return ` ${value} ${label}`;
                },
            },
        },
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
    <div className={styles.dashboard}>
        <div className={styles.totalInformation}>
            {
                countByStatusDataIsLoading ? (
                    <Loading horizontalSpacing={82} />
                ) : (
                    <>
                        <div className={styles.infoColumns}>
                            {
                                config.map((data) => {
                                    const {value, key} = data;
                                    return (
                                        <div className={styles.infoItem} key={key}>
                                            <div className={styles.header}>
                                                <StatusPill
                                                    status={key as TDocumentStatuses}
                                                    styleType="primary"
                                                />
                                            </div>
                                            <div className={styles.value}>
                                                {value}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.chartHolder}>
                            <Pie
                                data={chartData}
                                width={195}
                                height={195}
                                options={{
                                    maintainAspectRatio: false,
                                    onHover: onChartHover,
                                    // @ts-ignore
                                    plugins: plugins,
                                }}
                            />
                        </div>
                        <div className={styles.deadlineApproachingWrapper}>
                            <div className={styles.deadlineApproaching}>
                                <span className={styles.ribbon} style={{borderColor: color}}/>
                                <p className={styles.orderNumber}>{label}</p>
                                <div style={{display: 'flex'}}>
                                    {
                                        namesByStatusIsLoading ? (
                                            <Loading horizontalSpacing={50}/>
                                        ) : (
                                            <div className={styles.scrollWrapper}>
                                                {
                                                    namesByStatus.length === 0 ? (
                                                        <div className={styles.selectChart}>
                                                            Select chart
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                namesByStatus.map(({fullName, grantId}: {
                                                                    fullName: string, grantId: string
                                                                }, index: number) => {
                                                                    const grantIdStr = grantId ? ` (${grantId})` : '';
                                                                    return (<p key={index}>{`${fullName}${grantIdStr}`}</p>)
                                                                })
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
        <TableData
            companyId={companyId}
            refetchCountsByStatusData={refetchCountsByStatusData}
            hasSpouseFeature={hasSpouseFeature}
            email={email}
        />
    </div>
    );
};

export default AdminDashboard;
