import React from 'react';
import styles from "./dashboard.module.scss";
import Loading from "../../components/Loading/Loading";

const TableLoading = () => {
    return (
        <tr>
            <td colSpan={7}>
                <div style={{ width: `100%`}}>
                    <div className={styles.loadingWrapper}>
                        <Loading horizontalSpacing={191}/>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default TableLoading;
