import {TDataItem, TValidationSchemeBaseItem} from "../types";
import * as Yup from "yup";

const requireLabel = 'Field is required';
export const getYupDynamicScheme = (config: TDataItem[], isPublicOpenAi?: boolean) => {
    const steps: Record<string, number> = {};
    const validationSchemeBase: TValidationSchemeBaseItem[] = [];
    config.forEach((item: TDataItem) => {
        const { required, configType, configName, step, action} = item;
        const stepStr = `${step}`;
        const isManualField = !action;
        if (!Object.prototype.hasOwnProperty.call(steps, stepStr)) {
            steps[stepStr] = step;
        }
        if (isManualField) {
            validationSchemeBase.push({
                required,
                configType,
                configName,
                step,
            });
        }
    });
    const schemesByFormStep: Record<string, {}> = {};
    Object.keys(steps).forEach((key) => {
        schemesByFormStep[key] = {};
    });
    validationSchemeBase.forEach(
        ({ configType, configName, required, step }: TValidationSchemeBaseItem) => {
            let fieldSchema: Yup.AnySchema;
            switch (configType) {
                case 'NUMERIC':
                case 'DECIMAL':
                    fieldSchema = required ? Yup.number().required(requireLabel).nullable() : Yup.number().nullable();
                    break;
                case 'DATE':
                    fieldSchema = required ? Yup.date().required(requireLabel).nullable() : Yup.date().nullable();
                    break;
                case 'EMAIL':
                    if (isPublicOpenAi) {
                        fieldSchema = Yup.string()
                            .required(`Value is required`)
                            .nullable()
                            .email('Invalid email address')
                            .matches(
                                /^[a-zA-Z0-9._%+-]+@(openai\.com|corpora\.us)$/,
                                'Only @openai.com emails allowed',
                            );
                    } else {
                        fieldSchema = required ?
                            Yup.string().email('Must be a valid email').required(requireLabel).nullable() :
                            Yup.string().email('Must be a valid email').nullable();
                    }
                    break;
                case 'STRING':
                default:
                    fieldSchema = required ? Yup.string().required(requireLabel).nullable() : Yup.string().nullable();
                    break;
            }
            switch (configName) {
                case 'state': {
                    fieldSchema = fieldSchema.when('country', {
                        is: 'United States',
                        then: Yup.string().required('State is required'),
                        otherwise: Yup.string().nullable(),
                    });
                    break;
                }
                case 'zip_code': {
                    fieldSchema = fieldSchema.when('country', {
                        is: 'United States',
                        then: Yup.string()
                            .required('Zip code is required')
                            .matches(
                                /^(\d{5}|\d{5}-\d{4})$/,
                                'Zip Code format must be 12345 or 12345-1234'
                            ),
                        otherwise: Yup.string().nullable(),
                    });
                    break;
                }
                default:
            }
            schemesByFormStep[step] = { ...schemesByFormStep[step], [configName]: fieldSchema };
        },
    );
    Object.keys(steps).forEach((key) => {
        schemesByFormStep[key] = Yup.object().shape(schemesByFormStep[key]);
    });
    return schemesByFormStep;
}