import React from 'react';
import Loading from "../../../components/Loading/Loading";
import styles from './bulkUploadUsers.module.scss';

const UploadingLoading = () => {
    return (
        <div className={styles.uploadingLoading}>
            <div className={styles.loadingContent}>
                <Loading horizontalSpacing={10} />
                <p>Importing the users list in progress...</p>
            </div>
        </div>
    )
}

export default UploadingLoading;
