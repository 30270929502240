import {HttpService} from "../../services/HttpService";
import {TTermTypes} from "./utils";

const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;

const getTermsVersions = (urlParams: string) => {
    return HttpService.get(`api/admin/policy?${urlParams}`, {
        baseURL,
    });
}

const getTermsItem = (id: number) => {
    return HttpService.get(`api/admin/policy/${id}`, {
        baseURL,
    });
}

const createTerm = (params: {
    version: string,
    text: string,
    type: TTermTypes,
    forceAccept: boolean,
}) =>
HttpService.post<string>(
    "/api/admin/policy",
    params,
    { baseURL }
);

const getLatestContentByType = (type: TTermTypes) => {
    return HttpService.get(`api/admin/policy/current/${type}`, {
        baseURL,
    });
}


export const TermsService = {
    getTermsVersions,
    getTermsItem,
    createTerm,
    getLatestContentByType,
}