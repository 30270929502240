import { PublicHttpService } from '../../services/PublicHttpService';

export const templateQueryKeys = {
    initialInfo: ['initialInfo'],
    fieldsConfiguration: ['fieldsConfiguration'],
    exportDocAsHtml: ['exportDocAsHtml'],
    fetchPublicDraft: ['fetchPublicDraft'],
};

const baseURL = `${import.meta.env.VITE_CORPORA_SERVICE_URL}`;

const fetchInitialInfoFn = () => {
    return (
        PublicHttpService.get(`/api/public/file83b/info-corpora`, {
            baseURL,
        })
    );
}

const fetchInitialInfo = {
    queryKey: templateQueryKeys.initialInfo,
    queryFn: () => fetchInitialInfoFn().then(({ data }) => data),
};

const fetchTemplateConfigurationFn = (templateCode: string) => {
    return (
        PublicHttpService.get(`/api/public/file83b/getPlaceholdersByTemplateCodeDb/${templateCode}`, {
            baseURL,
        })
    );
}

const fetchPublicDraftDataFn = (uuid: string) => {
    return (
        PublicHttpService.get(`/api/public/file83b/ext/${uuid}`, {
            baseURL,
        })
    );
}

const fetchTemplateConfiguration = {
    queryKey: templateQueryKeys.fieldsConfiguration,
    queryFn: (templateCode: string) => fetchTemplateConfigurationFn(templateCode).then(({ data }) => data),
};

const fetchPublicDraftData = {
    queryKey: templateQueryKeys.fetchPublicDraft,
    queryFn: (templateCode: string) => fetchPublicDraftDataFn(templateCode).then(({ data }) => data),
};

const exportDocAsHtmlFn = (templateCode: string) => {
    return (
        PublicHttpService.get(`/api/public/file83b/exportDocAsHtml/${templateCode}`, {
            baseURL,
        })
    );
}

const exportDocAsHtml = {
    queryKey: templateQueryKeys.exportDocAsHtml,
    queryFn: (templateCode: string) => exportDocAsHtmlFn(templateCode).then(({ data }) => data),
};

const signOrDownloadFn = (params: {
    replacements: Record<string, string>;
    templateId: string;
}) => {
    return (
        PublicHttpService.post(
            `/api/public/file83b/generate-pdf-corpora`,
            params,
            {
                baseURL,
            }
        )
    )
}

const signOrDownload = {
    queryFn: (params: {
        replacements: Record<string, string>;
        templateId: string;
    }) => signOrDownloadFn(params).then(({ data }) => data),
};

const generatePdfLogFn = (params: {
    replacements: Record<string, string>;
    templateId: string;
}) => {
    return (
        PublicHttpService.post(
            `/api/public/file83b/generate-pdf-log`,
            params,
            {
                baseURL,
            }
        )
    )
}

const generatePdfLog = {
    queryFn: (params: {
        replacements: Record<string, string>;
        templateId: string;
    }) => generatePdfLogFn(params).then(({ data }) => data),
};

export const TemplatesService = {
    fetchInitialInfo,
    fetchTemplateConfiguration,
    fetchPublicDraftData,
    exportDocAsHtml,
    signOrDownload,
    generatePdfLog,
};