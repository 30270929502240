import React, {FC} from 'react';
import styles from './creationControl.module.scss';
import StepsControl, {stepsKeys, TStep} from "../StepsControl/StepsContro";
import BusinessInformation from "../steps/BusinessInformation/BusinessInformation";
import LateElection from "../steps/LateElection/LateElection";
import SignerInformation from "../steps/SignerInformation/SignerInformation";
import Shareholders from "../steps/Shareholders/Shareholders";
import {TForm2553Information} from "./types";
import Process from "../steps/process/Process";

interface TCreationControlProps {
    companyId: number;
    formId: string;
    isLateElection: boolean;
    form2553Information: TForm2553Information;
    refetch2553Data: () => void;
}
const CreationControl: FC<TCreationControlProps> = ({
companyId,
formId,
isLateElection,
form2553Information,
refetch2553Data,
}) => {
    const [step, setStep] = React.useState<TStep>(stepsKeys.BUSINESS_INFORMATION);
    return (
        <>
            <div className={styles.creationHeader}>
                <StepsControl
                    currentStep={step}
                    isLateElection={isLateElection}
                />
            </div>
            <div className={styles.creationBody}>
                {
                    step === stepsKeys.BUSINESS_INFORMATION && (
                        <BusinessInformation
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                            isLateElection={isLateElection}
                            form2553Information={form2553Information}
                            refetch2553Data={refetch2553Data}
                        />
                    )
                }
                {
                    step === stepsKeys.LATE_ELECTION_JUSTIFICATION && (
                        <LateElection
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                            form2553Information={form2553Information}
                            refetch2553Data={refetch2553Data}
                        />
                    )
                }
                {
                    step === stepsKeys.SHAREHOLDER_INFORMATION && (
                        <Shareholders
                            form2553Information={form2553Information}
                            companyId={companyId}
                            formId={formId}
                            refetch2553Data={refetch2553Data}
                            setStep={setStep}
                            isLateElection={isLateElection}
                        />
                    )
                }
                {
                    step === stepsKeys.SIGNER_INFORMATION && (
                        <SignerInformation
                            form2553Information={form2553Information}
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                        />
                    )
                }
                {
                    step === stepsKeys.PROCESS && (
                        <Process
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                        />
                    )
                }
            </div>
        </>
    )
}

export default CreationControl;
