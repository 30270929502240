import React, { FC, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import styles from "../shared.module.scss";
import * as Yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import { InputNumber } from 'primereact/inputnumber';
import {yupResolver} from "@hookform/resolvers/yup";
import {InputText} from "primereact/inputtext";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";
import {OwnershipTypes, TOwnershipType, TShareholderInformationFormValues} from "./types";
import {Calendar} from "primereact/calendar";
import sharedStyles from '../shared.module.scss';


const ownershipTypesOptions: { label: string; value: TOwnershipType }[] = [
    {
        label: 'Individual',
        value: OwnershipTypes.INDIVIDUAL
    },
    {
        label: 'Entity',
        value: OwnershipTypes.ENTITY
    },
];

const mock = {
    name: 'Narek Pedanyan',
    email: 'naropedan@gmail.com',
    address: {
        line1: 'H. Sahyan',
        city: 'Yerevan',
        state: 'Wash',
        zipCode: '0012',
        country: 'Armenia',
    },
    ownershipPercentage: 12,
    numberOfShares: 24,
    ownershipType: 'INDIVIDUAL',
    ein: '123-23-3457',
    dateAcquired: new Date('2025-01-14'),
    taxYearEndMonthAndDay: 'January 14',
}

const defaultValues: TShareholderInformationFormValues = {
    name: '',
    email: '',
    address: {
        line1: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
    },
    ownershipPercentage: undefined,
    numberOfShares: undefined,
    ein: '',
    dateAcquired: undefined,
    taxYearEndMonthAndDay: '',
    ownershipType: '',
}

interface TShareholdersFormProps {
    onSubmit: SubmitHandler<TShareholderInformationFormValues>;
    resetFormKey: number;
    setIsShareholderCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareholdersForm: FC<TShareholdersFormProps> = ({
onSubmit,
resetFormKey,
setIsShareholderCreate,
}) => {
    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        address: Yup.object({
            line1: Yup.string().required('Address line 1 is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            zipCode: Yup.string().required('Zip code is required'),
            country: Yup.string().required('Country is required'),
        }),
        ownershipPercentage: Yup
            .number()
            .nullable()
            .min(0, 'Ownership percentage cannot be less than 0')
            .max(100, 'Ownership percentage cannot exceed 100')
            .required('Ownership percentage is required'),
        numberOfShares: Yup
            .number()
            .nullable()
            .min(0, 'Number of shares cannot be negative')
            .required('Number of shares is required'),
        ownershipType: Yup
            .string()
            .oneOf([OwnershipTypes.INDIVIDUAL, OwnershipTypes.ENTITY], `Ownership type must be ${OwnershipTypes.INDIVIDUAL} or ${OwnershipTypes.ENTITY}`)
            .required('Ownership type is required'),
        ein: Yup.string().when('ownershipType', {
            is: OwnershipTypes.INDIVIDUAL,
            then: Yup.string()
                .matches(/^\d{3}-\d{2}-\d{4}$/, 'EIN must be in the format XXX-XX-XXXX for INDIVIDUAL')
                .required('EIN is required for INDIVIDUAL ownership'),
            otherwise: Yup.string()
                .matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX for ENTITY')
                .required('EIN is required for ENTITY ownership'),
        }),
        dateAcquired: Yup.date().required('Date acquired is required'),
        taxYearEndMonthAndDay: Yup.string()
            .required('Tax year end month and day are required')
            .matches(
                /^(January|February|March|April|May|June|July|August|September|October|November|December) (\d|[12]\d|3[01])$/,
                'Invalid format. Use "Month Day", e.g., January 16'
            ),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm<TShareholderInformationFormValues>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const submitHandler: SubmitHandler<TShareholderInformationFormValues> = (values) => {
        onSubmit(values);
    }

    useEffect(() => {
        reset();
    }, [resetFormKey]);

    return (
        <form className={sharedStyles.form} onSubmit={handleSubmit(submitHandler)}>
            <div className={sharedStyles.fieldsHolder}>
                <div className={sharedStyles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <LabelValue
                            label="Name"
                            required={true}
                            fieldName="name"
                            comment=""
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.name ? "p-invalid" : ""}
                                        placeholder="Enter shareholder name"
                                    />
                                )}
                            />
                            {errors.name && <small className="p-error">{errors.name.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Email"
                            required={true}
                            fieldName="email"
                            comment=""
                        >
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.email ? "p-invalid" : ""}
                                        placeholder="Enter shareholder email"
                                    />
                                )}
                            />
                            {errors.email && <small className="p-error">{errors.email.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Address Line 1"
                            required={true}
                            fieldName="address.line1"
                            comment=""
                        >
                            <Controller
                                name="address.line1"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.address?.line1 ? "p-invalid" : ""}
                                        placeholder="Enter address line 1"
                                    />
                                )}
                            />
                            {errors.address?.line1 && <small className="p-error">{errors.address.line1.message}</small>}
                        </LabelValue>
                        <div className={styles.addressLineGrid}>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="Address city"
                                    required={true}
                                    fieldName="address.city"
                                    comment=""
                                >
                                    <Controller
                                        name="address.city"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.city ? "p-invalid" : ""}
                                                placeholder="Enter city"
                                            />
                                        )}
                                    />
                                    {errors.address?.city && <small className="p-error">{errors.address.city.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="State"
                                    required={true}
                                    fieldName="address.state"
                                    comment=""
                                >
                                    <Controller
                                        name="address.state"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.state ? "p-invalid" : ""}
                                                placeholder="Enter state"
                                            />
                                        )}
                                    />
                                    {errors.address?.state && <small className="p-error">{errors.address.state.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="Zip Code"
                                    required={true}
                                    fieldName="address.zipCode"
                                    comment=""
                                >
                                    <Controller
                                        name="address.zipCode"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.zipCode ? "p-invalid" : ""}
                                                placeholder="Enter zip code"
                                            />
                                        )}
                                    />
                                    {errors.address?.zipCode &&
                                        <small className="p-error">{errors.address.zipCode.message}</small>}
                                </LabelValue>
                            </div>
                        </div>
                        <LabelValue
                            label="Country"
                            required={true}
                            fieldName="address.country"
                            comment=""
                        >
                            <Controller
                                name="address.country"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.address?.country ? "p-invalid" : ""}
                                        placeholder="Enter country"
                                    />
                                )}
                            />
                            {errors.address?.country &&
                                <small className="p-error">{errors.address.country.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Ownership Percentage"
                            required={true}
                            fieldName="ownershipPercentage"
                            comment=""
                        >
                            <Controller
                                name="ownershipPercentage"
                                control={control}
                                render={({field}) => (
                                    <InputNumber
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.ownershipPercentage ? "p-invalid" : ""}
                                        onChange={(e) => {
                                            field.onChange(e.value);
                                        }}
                                        placeholder="12%"
                                    />
                                )}
                            />
                            {errors.ownershipPercentage &&
                                <small className="p-error">{errors.ownershipPercentage.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Number of shares"
                            required={true}
                            fieldName="numberOfShares"
                            comment=""
                        >
                            <Controller
                                name="numberOfShares"
                                control={control}
                                render={({field}) => (
                                    <InputNumber
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.numberOfShares ? "p-invalid" : ""}
                                        onChange={(e) => {
                                            field.onChange(e.value);
                                        }}
                                    />
                                )}
                            />
                            {errors.numberOfShares &&
                                <small className="p-error">{errors.numberOfShares.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Ownership type"
                            required={true}
                            fieldName="ownershipType"
                            comment=""
                        >
                            <Controller
                                name="ownershipType"
                                control={control}
                                render={({field}) => (
                                    <Dropdown
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.ownershipType ? "p-invalid" : ""}
                                        options={ownershipTypesOptions}
                                        onChange={(e) => {
                                            field.onChange(e.value);
                                        }}
                                        placeholder="Select ownership type"
                                    />
                                )}
                            />
                            {errors.ownershipType &&
                                <small className="p-error">{errors.ownershipType.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="EIN"
                            required={true}
                            fieldName="ein"
                            comment=""
                        >
                            <Controller
                                name="ein"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.ein ? "p-invalid" : ""}
                                        placeholder="Select ein"
                                    />
                                )}
                            />
                            {errors.ein && <small className="p-error">{errors.ein.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Date Acquired"
                            required={true}
                            fieldName="dateAcquired"
                            comment=""
                        >
                            <Controller
                                name="dateAcquired"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="yy-mm-dd"
                                        showIcon
                                        style={{width: '100%'}}
                                        className={errors.dateAcquired ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.dateAcquired &&
                                <small className="p-error">{errors.dateAcquired.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Tax year end month and day"
                            required={true}
                            fieldName="taxYearEndMonthAndDay"
                            comment=""
                        >
                            <Controller
                                name="taxYearEndMonthAndDay"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        placeholder="January 16"
                                        style={{width: '100%'}}
                                        className={errors.taxYearEndMonthAndDay ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.taxYearEndMonthAndDay &&
                                <small className="p-error">{errors.taxYearEndMonthAndDay.message}</small>}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={sharedStyles.actionButtonHolder}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={() => setIsShareholderCreate(false)}
                    type="button"
                    label="Back to shareholders"
                />
                <ButtonPrimary
                    type="submit"
                    label="Add"
                />
            </div>
        </form>
    )
}

export default ShareholdersForm;
