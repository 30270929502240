import React, { useState, FC, useMemo } from 'react';
import styles from './activitiesAndDocuments.module.scss';
import {classNames} from "primereact/utils";
import sharedStyles from "../../../assets/sharedStyles.module.scss";
import {TActivityItem} from "../../../@types/global";
import {formatToStandardDateUTC} from "../../../helpers/convertDates";

interface TActivitiesProps {
    data?: TActivityItem[];
    showInitialCount?: number;
}

const Activities: FC<TActivitiesProps> = ({ data = [], showInitialCount = 3 }) => {
    const [isToggled, toggle] = useState(false);
    const items = useMemo(
        () => {
            if (!data) return [];
            const firstThreeItems = data.slice(0, showInitialCount);
            return isToggled ? data : firstThreeItems;
        }, [data, isToggled]
    );
    const showMore = data.length > showInitialCount;
    return (
        <div className={styles.dataHolder}>
            <div className={styles.header}>
                <span className={styles.label}>Activity</span>
                {
                    showMore && (
                        <button
                            onClick={() => toggle(!isToggled)}
                            className={classNames(sharedStyles.btnLink, sharedStyles.noDecor)}
                            type="button"
                        >
                            {isToggled ? 'View less' : 'View All'}
                        </button>
                    )
                }
            </div>
            <div className={styles.scrollWrapper}>
                {
                    items.length === 0 ? (
                        <div className={styles.noRecords}>No activity records</div>
                    ) : (
                        <>
                        {
                                items.map((item) => {
                                    const { createdDateTime, description } = item;
                                    return (
                                        <div className={styles.activityRow} key={createdDateTime}>
                                            <span>{description}</span>
                                            <span>{formatToStandardDateUTC(createdDateTime)}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default Activities;
