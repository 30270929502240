import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import CreationControl from "./CreationControl/CreationControl";
import Questionnaire from "./Questionnaire/Questionnaire";
import {Nullable} from "primereact/ts-helpers";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Form2553Service} from "../Service";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";
import {useToast} from "../../../hooks/useToast";
import styles from './form2553Creation.module.scss';
import Loading from "../../../components/Loading/Loading";

export type TQuestionnaireAnswers = {
    question_1: Nullable<number>;
    question_2: Nullable<'Yes' | 'No'>;
    question_3: Nullable<'timelyElection' | 'lateElection'>;
}
interface TForm2553CreationProps {
    companyId: number;
}
const questionnaireAnswersInitialValue = {
    question_1: null,
    question_2: null,
    question_3: null,
}
const Form2553Creation: FC<TForm2553CreationProps> = ({ companyId }) => {
    const { formIdParam } = useParams<{ formIdParam: string }>();
    const [formId, setFormId] = useState<Nullable<string>>(formIdParam);
    const [questionnaireAnswers, setQuestionnaireAnswers] = useState<TQuestionnaireAnswers>(questionnaireAnswersInitialValue);
    const { question_3 } = questionnaireAnswers;
    const isCreationEnabled = Boolean(formId);
    const { show } = useToast();
    const isLateElection = question_3 === 'lateElection';

    const { data: form2553Data, isLoading: form2553DataLoading, refetch} = useQuery({
        queryKey: ['form2553-data', formId],
        queryFn: () =>  Form2553Service.getForm2553Data(formId as string, companyId),
        gcTime: 0,
        enabled: Boolean(formId),
    });
    const form2553Information = form2553Data?.data || {};

    const refetch2553Data = () => {
        refetch();
    }

    const setQuestionState = (questionKey: string, value: number | string) => {
        setQuestionnaireAnswers((prev) => {
            return ({
                ...prev,
                [questionKey]: value,
            });
        });
    }

    const initForm = useMutation({
        mutationFn: Form2553Service.initForm2553,
        onSuccess: (response: AxiosResponse) => {
            const id = response?.data?.id;
            setFormId(`${id}`);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: initForm.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const enableCreation = () => {
        const initParams =  {
            calendarYearCheckbox: questionnaireAnswers.question_1 === 1,
            yearEndingOfDecemberCheckbox: questionnaireAnswers.question_1 === 3,
            lateElectionQuestionEnabled: questionnaireAnswers.question_3 === 'lateElection',
        }
        initForm.mutate({ params: initParams, companyId });
    }

    if (formIdParam && form2553DataLoading) {
        return (
            <div className={styles.form2553Creation}>
                <div className={styles.loadingWrapper}>
                    <Loading />
                </div>
            </div>
        )
    }

    return (
        <div className={styles.form2553Creation}>
            {
                isCreationEnabled ? (
                    <CreationControl
                        companyId={companyId}
                        isLateElection={isLateElection}
                        formId={formId as string}
                        form2553Information={form2553Information}
                        refetch2553Data={refetch2553Data}
                    />
                ) : (
                    <Questionnaire
                        enableCreation={enableCreation}
                        setQuestionState={setQuestionState}
                        questionnaireAnswers={questionnaireAnswers}
                    />
                )
            }
        </div>
    );
}

export default Form2553Creation;
