import { Toast } from 'primereact/toast';
import React, { createContext, useContext, useRef } from 'react';

export type ToastShowProps = {
  severity?: 'success' | 'info' | 'warn' | 'error' | undefined;
  detail?: React.ReactNode;
  life?: number;
};

type ToastsContext = {
  show: (props: ToastShowProps) => void;
  // clearAll: () => void;
};

const Toasts = createContext<ToastsContext>({
  show: () => null,
  // clearAll: () => null,
});

export const ToastProvider = ({
  children,
}: React.HtmlHTMLAttributes<HTMLElement>) => {
  const toast = useRef<any>(null);

  const show = ({
    severity = 'success',
    detail,
    life = 2000,
  }: ToastShowProps) => {
    toast.current.show({
      severity,
      summary: severity.toUpperCase(),
      detail,
      life,
    });
  };
  return (
    <Toasts.Provider value={{ show }}>
      {children}
      <Toast ref={toast} />
    </Toasts.Provider>
  );
};

export const useToast = () => useContext<ToastsContext>(Toasts);
