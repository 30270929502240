import { Tooltip } from 'primereact/tooltip';
import { classNames as cx, classNames } from 'primereact/utils';
import React, { FC, ReactElement } from 'react';

import styles from './inputText.module.scss';

interface IInputFieldProps {
  label?: string;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  children?: ReactElement | ReactElement[];
  showErrorColoring?: boolean;
  disabled?: boolean;
  onClear?: () => void;
  showClear?: boolean;
  toolTipMessage?: string;
  showPercentSign?: boolean;
  isSearch?: boolean;
}

const FieldWrapper: FC<IInputFieldProps> = ({
  label,
  className,
  required,
  errorMessage,
  children,
  showErrorColoring,
  disabled,
  onClear,
  showClear,
  toolTipMessage,
  showPercentSign,
  isSearch,
}) => {
  return (
    <div
      className={classNames(styles.inputField, className, {
        [styles.withIcon]: isSearch,
        [styles.required]: required,
        [styles.errorColoring]: showErrorColoring,
        [styles.disabled]: disabled,
      })}
    >
      {(label || toolTipMessage) && (
        <div className={styles.labelWrapper}>
          <p className={styles.label}>{label}</p>
          {toolTipMessage && (
            <>
              <Tooltip target=".person-label" />
              <i
                data-pr-tooltip={toolTipMessage}
                data-pr-position="top"
                className={cx(
                  'person-label pi pi-info-circle',
                  styles.tooltipIcon,
                )}
              ></i>
            </>
          )}
        </div>
      )}
      <div
        className={classNames(styles.inputElementWrapper, {
          [styles.percentSignPadding]: showPercentSign,
          [styles.searchPadding]: isSearch,
        })}
      >
        {children}
        {showClear && (
          <button type="button" className={styles.clearBtn} onClick={onClear}>
            <i className="bi bi-x-lg"></i>
          </button>
        )}
        {showPercentSign && <span className={styles.percentSign}>%</span>}
        {isSearch && <i className={classNames('bi bi-search', styles.searchIcon)}></i>}
      </div>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};
export default FieldWrapper;
