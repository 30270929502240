import React, { FC } from 'react';
import styles from './policyAcceptModal.module.scss';
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";

interface TPolicyAcceptModalProps {
    privacyPolicyNotAccepted: boolean;
    termsNotAccepted: boolean;
    onCloseModal: () => void;
    onAccept: () => void;
    loading: boolean;
}

const PolicyAcceptModal: FC<TPolicyAcceptModalProps> = ({
privacyPolicyNotAccepted,
termsNotAccepted,
onCloseModal,
onAccept,
loading,
}) => {
    return (
        <div className={styles.policyModalOverlay}>
            <div className={styles.policyModal}>
                <button type="button" className={styles.closeBtn} onClick={onCloseModal}>
                    <i className="bi bi-x"/>
                </button>
                <p className={styles.modalHeading}>Accept our updated terms</p>
                <p className={styles.description}>
                    Corpora has updated the below terms since you last visited.
                    By continuing, you confirm that you have reviewed and accepted the updated terms.
                    You cannot access the platform unless you agree to the updated terms.
                </p>
                <div className={styles.listWrapper}>
                    <ul>
                        {
                            termsNotAccepted && (
                                <li>
                                    <a href="https://83b.corpora.us/terms-of-service" target="_blank">Terms Of Service</a>
                                </li>
                            )
                        }
                        {
                            privacyPolicyNotAccepted && (
                                <li>
                                    <a href="https://83b.corpora.us/privacy-policy" target="_blank">Privacy Policy</a>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <div className={styles.actionBtnWrapper}>
                    <ButtonPrimary
                        onClick={onAccept}
                        label="Accept"
                        className={styles.actionBtn}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    )
}

export default PolicyAcceptModal;
