import {useMutation} from '@tanstack/react-query';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loading from '../Loading/Loading';
import { statusesConfig } from '../../config/config';
import { requests } from '../../services/http-common';

import styles from './styles.module.scss';
import { monthsConfig } from './types';
import PublicHeader from "../PublicHeader/PublicHeader";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import {formatToStandardDateUTC} from "../../helpers/convertDates";

type TData = {
  orderNumber: string;
  name: string;
  statuses: Record<string, string>;
  amountPaid: string;
};

const DocGenerationOrder = () => {
  const [searchKey, setSearchKey] = useState<string>('');
  const [data, setData] = useState<TData | null>(null);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const orderNumber = urlParams.get('orderNumber');
  const [initialState, changeInitialState] = useState<boolean>(true);
  const isPendingExist = Boolean(data?.statuses?.PENDING);
  const searchOrder = useMutation({
    mutationFn: async (searchKeyFromProps: string) => {
      const sKy = searchKeyFromProps ? searchKeyFromProps : searchKey;
      return requests.get(`/api/public/file83b/${sKy}`);
    },
    onSuccess: (res) => {
      if (initialState) {
        changeInitialState(false);
      }
      setLoading(false);
      setData(res);
    },
    onError: () => {
      if (initialState) {
        changeInitialState(false);
      }
      setLoading(false);
      setData(null);
    },
  });

  const formatDate = (date: Date) => {
    const dt = moment(date).format('MM/DD/YYYY');
    const dtArr = dt.split('/');
    return `${monthsConfig[dtArr[0]]} ${dtArr[1]}, ${dtArr[2]}`;
  };

  const formattedOrderDate = data?.statuses?.REQUESTED
    ? formatDate(new Date(data?.statuses?.REQUESTED))
    : '';
  const twoDaysTs = 2 * 24 * 60 * 60 * 1000;
  const newDateInTimeStep = new Date(
    data?.statuses?.REQUESTED as string,
  ).getTime();
  const formattedDeadlineToSignDate = data?.statuses?.REQUESTED
    ? formatDate(new Date(newDateInTimeStep + twoDaysTs))
    : '';
  useEffect(() => {
    if (orderNumber) {
      setSearchKey(orderNumber);
      searchOrder.mutate(orderNumber);
      setLoading(true);
    }
  }, [orderNumber]);

  return (
    <div className={styles.docGenerationOrder}>
      <PublicHeader label="Track Your Order"/>
      <div className={styles.orderContainer}>
        <div className={styles.orderHeader}>
          <div className={styles.searchFieldHolder}>
            <input
                value={searchKey}
                placeholder="Search by order number"
                type="text"
                className={styles.orderSearchField}
                onChange={(e) => setSearchKey(e.target.value)}
            />
            <i className={classNames('pi pi-search', styles.searchIcon)}></i>
            <ButtonPrimary
                className={styles.searchBtn}
                label="Search"
                onClick={() => {
                  searchOrder.mutate('');
                  setLoading(true);
                }}
                disabled={!searchKey}
            />
          </div>
        </div>
        {Boolean(searchKey) && isLoading && <Loading/>}
        {data && !isLoading && (
            <>
              <div className={styles.docGenerationOrderBody}>
                <div className={styles.trackingInfo}>
                  <div className={styles.widgetHead}>
                    <span className={styles.label}>Order Number:</span>
                    <span className={styles.value}>{data?.orderNumber ? data?.orderNumber : '-'}</span>
                  </div>
                  <div className={styles.infoHolder}>
                    {data?.name && (
                        <div className={styles.infoItem}>
                          <p>Name:</p>
                          <p>{` ${data?.name}`}</p>
                        </div>
                    )}
                    {data?.statuses?.REQUESTED && (
                        <div className={styles.infoItem}>
                          <p>Order Date:</p>
                          <p>{` ${formattedOrderDate}`}</p>
                        </div>
                    )}
                    {data?.statuses?.REQUESTED && (
                        <div className={styles.infoItem}>
                          <p>Deadline to sign:</p>
                          <span>{` ${formattedDeadlineToSignDate}`}</span>
                        </div>
                    )}
                  </div>
                </div>
                <div className={styles.processWidget}>
                  <div className={styles.widgetHead}>
                    <span className={styles.txt}>
                      Submission Progress
                    </span>
                  </div>
                  <div className={styles.statusesWidget}>
                    <div className={styles.widget}>
                      {Object.keys(statusesConfig)
                          .map((itemKey: string, index) => {
                            if (!isPendingExist && itemKey === 'PENDING') return null;
                            const config = statusesConfig[itemKey];
                            const currentDate = data?.statuses[itemKey];
                            return (
                                <div key={index}>
                                  <div className={styles.widgetItem}>
                                    <p className={styles.dateHolder}>{formatToStandardDateUTC(currentDate, undefined, ' ')}</p>
                                    <div className={styles.circle}>
                                      {currentDate && (
                                          <span className={styles.circleFilled}/>
                                      )}
                                    </div>
                                    <p className={styles.labelHolder}>{config?.label}</p>
                                  </div>
                                  {index < Object.keys(statusesConfig).length - 1 && (
                                      <div className={styles.spaceBetween}/>
                                  )}
                                </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
              <p className={styles.expiration}>
                This page will expire after three months of your order date.
              </p>
            </>
        )}
        {Boolean(searchKey) && !initialState && !isLoading && data === null && (
            <div className={styles.nothingFound}>Record not found</div>
        )}
      </div>
    </div>
  );
};

export default DocGenerationOrder;
