import React, {FC, useMemo} from 'react';
import styles from "../shared.module.scss";
import SignerInformationForm from "./SignerInformationForm";
import {SubmitHandler} from "react-hook-form";
import {TSignerInformationFormValues} from "./types";
import {useToast} from "../../../../../hooks/useToast";
import {useMutation} from "@tanstack/react-query";
import {Form2553Service} from "../../../Service";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {stepsKeys, TStep} from "../../StepsControl/StepsContro";
import {TForm2553Information} from "../../CreationControl/types";

interface TSignerInformation {
    companyId: number;
    formId: string;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    form2553Information: TForm2553Information;
}
const SignerInformation:FC<TSignerInformation> = ({
companyId,
formId,
setStep,
form2553Information,
}) => {
    const { show } = useToast();
    const postSignerInformation = useMutation({
        mutationFn: Form2553Service.postSignerInformation,
        onSuccess: (response: AxiosResponse) => {
            setStep(stepsKeys.PROCESS);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postSignerInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit: SubmitHandler<TSignerInformationFormValues> = (values) => {
        postSignerInformation.mutate({ params: values, companyId, formId });
    }
    const signerInformationFormInitialValues = useMemo(() => {
        return form2553Information?.signerInformation;
    }, [form2553Information]);
    const onPrev = () => {
        setStep(stepsKeys.SHAREHOLDER_INFORMATION);
    }
    return (
        <div className={styles.stepSection}>
            <div className={styles.formColumn}>
                <SignerInformationForm
                    onSubmit={onSubmit}
                    initialValues={signerInformationFormInitialValues}
                    onPrev={onPrev}
                />
            </div>
        </div>
    )
}

export default SignerInformation;
