import ReactQuill from "react-quill";
import React, { FC } from "react";
import styles from './htmlEditor.module.scss';

interface THtmlEditorProps {
    content: string;
    handleContentChange: (content: string) => void;
}
const HtmlEditor: FC<THtmlEditorProps> = ({
content,
handleContentChange,
}) => {
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }], // Header options
            ["bold", "italic", "underline", "strike"], // Text formatting
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
            [{ indent: "-1" }, { indent: "+1" }], // Indent
            [{ direction: "rtl" }], // Text direction
            [{ size: ["small", false, "large", "huge"] }], // Font size
            [{ color: [] }, { background: [] }], // Text and background color
            [{ align: [] }], // Text alignment
            ["link"], // Media
            ["clean"], // Remove formatting
        ],
    };

    const formats = [
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "script",
        "indent",
        "direction",
        "size",
        "color",
        "background",
        "align",
        "link",
        "header",
    ];
    return (
        <div className={styles.quillFrame}>
            <ReactQuill
                value={content}
                onChange={handleContentChange}
                theme="snow"
                placeholder="Write something amazing..."
                modules={modules}
                formats={formats}
            />
        </div>
    );
}

export default HtmlEditor;
