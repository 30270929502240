import { HttpService } from "../../services/HttpService";
import {TBusinessInformationRequestParams} from "./Form2553Creation/steps/BusinessInformation/types";
import {TLateElectionFormValues} from "./Form2553Creation/steps/LateElection/types";
import {TSignerInformationFormValues} from "./Form2553Creation/steps/SignerInformation/types";
import {InitFormParams} from "./types";
import {TAdditionalInformationParams, TShareholderInformationParams} from "./Form2553Creation/steps/Shareholders/types";

const baseURL = `${import.meta.env.VITE_CORPORA_SERVICE_URL}`;

const postBusinessInformation = ({ params, companyId, formId }: { params: TBusinessInformationRequestParams, companyId: number, formId: string }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/business-information`,
        params,
        { baseURL }
    );

const postLateElectionJustification = ({ params, companyId, formId }: { params: TLateElectionFormValues, companyId: number, formId: string }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/late-election-justification`,
        params,
        { baseURL }
    );

const postSignerInformation = ({ params, companyId, formId }: { params: TSignerInformationFormValues, companyId: number, formId: string }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/signer-information`,
        params,
        { baseURL }
    );

const initForm2553 = ({ params, companyId }: { params: InitFormParams, companyId: number }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/init`,
        params,
        { baseURL }
    );

const getForm2553Files = (urlParams: string, companyId: number) => {
    return HttpService.get(`api/companies/${companyId}/form2553/search?${urlParams}`, {
        baseURL,
    });
}

const getForm2553Data = (formId: string, companyId: number) => {
    return HttpService.get(`api/companies/${companyId}/form2553/${formId}`, {
        baseURL,
    });
}

const validateShareholderInformation = ({ formId, companyId }: { formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `api/companies/${companyId}/form2553/${formId}/shareholder-information/validate`,
        {},
        { baseURL }
    );
}

const postShareholderInformation = ({ params, formId, companyId }: { params: TShareholderInformationParams, formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `api/companies/${companyId}/form2553/${formId}/shareHolder`,
        params,
        { baseURL }
        );
}

const patchAdditionalInformation = ({ params, formId, companyId }: { params: TAdditionalInformationParams, formId: string, companyId: number }) => {
    return HttpService.patch<string>(
        `api/companies/${companyId}/form2553/${formId}/additional-info`,
        params,
        { baseURL }
    );
}

const postProcess = ({ formId, companyId }: { formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `api/companies/${companyId}/form2553/${formId}/process`,
        {},
        { baseURL }
    );
}

const deleteShareholder = ({ formId, companyId, id }: { formId: string, companyId: number, id: number }) =>
    HttpService.delete(`api/companies/${companyId}/form2553/${formId}/shareHolder/${id}`, { baseURL });

export const Form2553Service = {
    getForm2553Files,
    postBusinessInformation,
    postLateElectionJustification,
    postSignerInformation,
    initForm2553,
    getForm2553Data,
    validateShareholderInformation,
    postShareholderInformation,
    patchAdditionalInformation,
    postProcess,
    deleteShareholder,
}