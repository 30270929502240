import { classNames } from 'primereact/utils';
import React, { FC } from 'react';

import styles from './toggleButton.module.scss';

interface TToggleButtonProps {
  isRowOpened: boolean;
  onClickHandler: () => void;
}

const ToggleButton: FC<TToggleButtonProps> = ({
  isRowOpened,
  onClickHandler,
}) => {
  return (
    <div className={styles.toggleBtnWrapper}>
      <button
        type="button"
        className={classNames(styles.toggleTableRowBtn, {
          [styles.toggled]: isRowOpened,
        })}
        onClick={onClickHandler}
      >
        <i className="bi-caret-right-fill" />
      </button>
    </div>
  );
};

export default ToggleButton;
