import React, { FC } from 'react';
import {Document} from "../../../types";
import styles from './firstColumnTemplate.module.scss';
import {Checkbox} from "primereact/checkbox";

interface FirstColumnTemplate {
    document: Document;
    bulkSelectMode: boolean;
    onBulkSelect: (value: number) => void;
    bulkSelectedIds: number[];
}
const FirstColumnTemplate: FC<FirstColumnTemplate> = ({
document,
bulkSelectMode,
onBulkSelect,
bulkSelectedIds,
}) => {
    const isSelected = Boolean(bulkSelectedIds.find((selectedId) => selectedId === document.id));
    return (
        <div className={styles.wrapper}>
            {
                bulkSelectMode && (
                    <Checkbox
                        className={styles.checkbox}
                        onChange={() => onBulkSelect(document.id)}
                        checked={isSelected}
                    />
                )
            }
            <span>
                {document.purchaserName}
            </span>
        </div>
    )
}

export default FirstColumnTemplate;
