import React, { FC } from 'react';
import styles from "./publicHeader.module.scss";
import logo from '../../assets/images/83b-logo-corpora.png';
import { NavLink } from 'react-router-dom';

interface TPublicHeaderProps {
    label?: string;
}

const PublicHeader: FC<TPublicHeaderProps> = ({ label = '83(b) Election' }) => {
    return (
        <div className={styles.header83b}>
            <NavLink to="/" className={styles.logoWrapperLink}>
                <img src={logo} alt=""/>
            </NavLink>
            <p>{label}</p>
        </div>
    )
}

export default PublicHeader;
