import { AxiosError } from 'axios';
import {errorToastLife} from "../config/config";

export type TAxios = AxiosError<{
  message?: string;
  detail?: string;
  error?: string;
  errorMessage?: string;
  fieldErrors?: Record<string, string>;
}>;
interface TExtractErrorMessageProps {
  error: TAxios,
  customDefaultMessage?: string,
  variables?: any,
  retryFn?: Function,
  show: Function,
  logErrorMessage?: boolean,
  cb?: (errorMessage?: string) => void;
  getFieldErrorsCb?: (fieldErrors: string) => void;
  hideToastMessage?: boolean;
}
export const extractErrorMessage = (errorData: TExtractErrorMessageProps) => {
  const {
    error,
    customDefaultMessage,
    variables,
    retryFn,
    show,
    logErrorMessage,
    cb,
    getFieldErrorsCb,
    hideToastMessage,
  } = errorData;
  if (error.message === 'retry') {
    if (retryFn) {
      setTimeout(() => {
        retryFn(variables);
      }, 800);
    }
    return;
  }
  const fieldErrorsArr: string[] = [];
  const fieldErrors = error?.response?.data?.fieldErrors || {};
  const fieldErrorKeys = Object.keys(fieldErrors);
  const isFieldErrorExist = fieldErrorKeys.length > 0;
  if (isFieldErrorExist) {
    fieldErrorKeys.forEach((fieldName) => {
      // const errorFieldLabel = fieldLabels[fieldName] || fieldName;
      const errorText = fieldErrors[fieldName];
      if (errorText) {
        fieldErrorsArr.push(`${errorText} `);
      }
    });
  }
  const fieldErrorsMessage = isFieldErrorExist ? fieldErrorsArr.join(', ') : '';
  if (isFieldErrorExist && getFieldErrorsCb) {
    getFieldErrorsCb(fieldErrorsMessage);
    if (cb) {
      cb();
    }
    return;
  }
  const detail = error?.response?.data?.detail;
  const message = error?.response?.data?.message;
  const errorMsg = error?.response?.data?.error;
  const errorMessage = error?.response?.data?.errorMessage;
  const defaultMessage = customDefaultMessage || 'Something went wrong';
  const finalErrorMessage =
      fieldErrorsMessage ||
      detail ||
      message ||
      errorMsg ||
      errorMessage ||
      defaultMessage;
  if (logErrorMessage) {
  } else {
    if (!hideToastMessage) {
      show({
        severity: 'error',
        detail: finalErrorMessage,
        life: errorToastLife,
      });
    }
    if (cb) {
      cb(finalErrorMessage);
    }
  }
};
