import React, { FC } from 'react';
import styles from "../styles.module.scss";
import Loading from "../../Loading/Loading";

interface TProcessingLoadingProps {
    requestedEmail: string;
    isGlobalLoading: boolean;
    paymentEnabled: boolean;
    isPublic?: boolean;
}

const ProcessingLoading: FC<TProcessingLoadingProps> = ({
requestedEmail,
isGlobalLoading,
isPublic,
paymentEnabled,
}) => {
    if (!isGlobalLoading) return null;
    const message = isPublic ?
        'You are being redirected to Stripe for payment.' :
        paymentEnabled ? 'We are preparing your payment form.' : 'We are preparing your document for signature.';
    const requestedWithEmailLoadingMessage = 'This may take a couple moments.  Thank you for your patience.';
    return (
        <div className={styles.loadingOverlay}>
            <div className={styles.loadingInfo}>
                <p>{requestedEmail ? requestedWithEmailLoadingMessage : message}</p>
                <Loading horizontalSpacing={25}/>
            </div>
        </div>
    )
}

export default ProcessingLoading;
