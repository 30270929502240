import React, { FC } from 'react';
import styles from "../authentication.module.scss";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {Controller, useForm} from "react-hook-form";
import FieldWrapper from "../../../uiComponents/FieldWrapper/FieldWrapper";
import {InputText} from "primereact/inputtext";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

export type TFieldName = 'code';
export type TFieldConfig = {
    fieldName: TFieldName;
    label: string;
    placeholder: string;
};

export type TConfirmValues = Record<TFieldName, string>;

const fieldsConfig: TFieldConfig[] = [
    {
        fieldName: 'code',
        label: 'Verification code',
        placeholder: '',
    },
];

const initialFormValues = {
    code: '',
};

interface TConfirmSignUpFormProps {
    onSubmit: (values: TConfirmValues) => void;
    isLoading: boolean;
    resendCode: () => void;
}

const ConfirmSignUpForm: FC<TConfirmSignUpFormProps> = ({ onSubmit, isLoading, resendCode }) => {
    const formSchema = Yup.object().shape({
        code: Yup.string().required('Name is required'),
    });
    const form = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: initialFormValues,
    });
    const { control, handleSubmit, watch } = form;
    const formErrors = form.formState.errors;
    return (
        <form className={styles.authForm} onSubmit={handleSubmit(onSubmit)}>
            <p className={styles.headTxt}>Verify your email address</p>
            <div className={styles.fields}>
                {fieldsConfig.map((fieldConfig: TFieldConfig) => {
                    const {fieldName, placeholder, label} = fieldConfig;
                    const fieldError = formErrors[fieldName]?.message;
                    return (
                        <div className={styles.fieldItem} key={fieldName}>
                            <Controller
                                name={fieldName as TFieldName}
                                control={control}
                                render={({field}) => {
                                    return (
                                        <FieldWrapper
                                            className={styles.fieldWide}
                                            errorMessage={fieldError}
                                            label={label}
                                            required
                                        >
                                            <InputText
                                                className={styles.fieldWide}
                                                value={field.value as string}
                                                autoComplete="new-password"
                                                name={fieldName}
                                                placeholder={placeholder}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                }}
                                            />
                                        </FieldWrapper>
                                    );
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <ButtonPrimary
                label="Verify"
                className={styles.actionBtn}
                type="submit"
                disabled={isLoading}
            />
            <div className={styles.haveAccountRow}>
                <p className={styles.label}>Didn't receive an email ?</p>
                <button type="button" className={styles.link} onClick={resendCode}>
                    Resend
                </button>
            </div>
        </form>
    );
}

export default ConfirmSignUpForm;
