import React from 'react';
import styles from "./layoutPlaceholder.module.scss";
import SidebarUiPlaceholder from "../Sidebar/SidebarUiPlaceholder";
import { useLocation } from 'react-router-dom';

const LayoutPlaceholder = () => {
    const location = useLocation();
    const hideLeftMenuPlaceholder = location?.pathname === '/documents' || location?.pathname === '/acknowledgements/upload' || location?.pathname === '/acknowledgements';
    return (
        <div className={styles.appPlaceholder}>
            {
                !hideLeftMenuPlaceholder && (
                    <div className={styles.sidebarPl}>
                        <SidebarUiPlaceholder/>
                    </div>
                )
            }
            <div className={styles.headerPl}/>
            <div className={styles.contentPl} />
        </div>
    );
}

export default LayoutPlaceholder;
