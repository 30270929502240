import React, { FC } from 'react';
import styles from "./dashboard.module.scss";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import {Nullable} from "primereact/ts-helpers";

interface TRowActionsProps {
    redirectToTrackPage: (url: string) => void;
    remind: (id: number) => void;
    allowRemind: boolean;
    uspsUrl: Nullable<string>;
    id: number;
    remindLoading: boolean;
}

const RowActions: FC<TRowActionsProps> = ({
                                              redirectToTrackPage,
                                              remind,
                                              allowRemind,
                                              uspsUrl,
                                              id,
                                              remindLoading,
                                          }) => {
    return (
        <div className={styles.actionsHolder}>
            {
                uspsUrl && (
                    <ButtonPrimary
                        styleType="secondaryOutline"
                        label="Track"
                        className={styles.actionBtnCustom}
                        onClick={() => redirectToTrackPage(uspsUrl)}
                    />
                )
            }
            {
                allowRemind && (
                    <ButtonPrimary
                        styleType="secondaryOutline"
                        label="Remind"
                        className={styles.actionBtnCustom}
                        onClick={() => remind(id)}
                        disabled={remindLoading}
                    />
                )
            }
        </div>
    )
}

export default RowActions;
