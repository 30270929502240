import React, { FC } from "react";
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import RadioCheckboxLabel from "../../../../../uiComponents/RadioCheckboxLabel/RadioCheckboxLabel";
import {TBusinessInformationFormValues} from "./types";
import styles from '../shared.module.scss';

const schema = Yup.object().shape({
    entityName: Yup.string().required("Entity Name is required"),
    ein: Yup.string()
        .matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX')
        .required('EIN is required'),
    address: Yup.object().shape({
        line1: Yup.string().required("Address Line 1 is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string(),
        zipCode: Yup.string().required("Zip Code is required"),
        country: Yup.string().required("Country is required"),
    }),
    incorporationDate: Yup.date().required("Incorporation Date is required"),
    electionEffectiveDate: Yup.date().required("Election Effective Date is required"),
    nameCheckbox: Yup.boolean(),
    addressCheckbox: Yup.boolean(),
    calendarYearCheckbox: Yup.boolean(),
    yearEndingOfDecemberCheckbox: Yup.boolean(),
    moreThan100ShareholdersCheckbox: Yup.boolean(),
    officerName: Yup.string().required("Officer Name is required"),
    officerTitle: Yup.string().required("Officer Title is required"),
    officerPhoneNumber: Yup.string().required("Officer Phone Number is required"),
    lateElectionQuestionEnabled: Yup.boolean(),
});

interface TBusinessInformationProps {
    onSubmit: SubmitHandler<TBusinessInformationFormValues>;
    initialValues: TBusinessInformationFormValues;
}
const BusinessInformationForm: FC<TBusinessInformationProps> = ({
onSubmit,
initialValues,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<TBusinessInformationFormValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.fieldsHolder}>
                <div className={styles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <LabelValue
                            label="Entity Name"
                            required={true}
                            fieldName="entityName"
                            comment=""
                        >
                            <Controller
                                name="entityName"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.entityName ? "p-invalid" : ""}
                                        placeholder="Enter entity name"
                                    />
                                )}
                            />
                            {errors.entityName && <small className="p-error">{errors.entityName.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="EIN"
                            required={true}
                            fieldName="ein"
                            comment=""
                        >
                            <Controller
                                name="ein"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.ein ? "p-invalid" : ""}
                                        placeholder="77-7777777"
                                    />
                                )}
                            />
                            {errors.ein && <small className="p-error">{errors.ein.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Address Line 1"
                            required={true}
                            fieldName="address.line1"
                            comment=""
                        >
                            <Controller
                                name="address.line1"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.address?.line1 ? "p-invalid" : ""}
                                        placeholder="Enter address line 1"
                                    />
                                )}
                            />
                            {errors.address?.line1 && <small className="p-error">{errors.address.line1.message}</small>}
                        </LabelValue>
                        <div className={styles.addressLineGrid}>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="Address city"
                                    required={true}
                                    fieldName="address.city"
                                    comment=""
                                >
                                    <Controller
                                        name="address.city"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.city ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {errors.address?.city && <small className="p-error">{errors.address.city.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="State"
                                    required={false}
                                    fieldName="address.state"
                                    comment=""
                                >
                                    <Controller
                                        name="address.state"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.state ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {errors.address?.state && <small className="p-error">{errors.address.state.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="Zip Code"
                                    required={true}
                                    fieldName="address.zipCode"
                                    comment=""
                                >
                                    <Controller
                                        name="address.zipCode"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.zipCode ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {errors.address?.zipCode &&
                                        <small className="p-error">{errors.address.zipCode.message}</small>}
                                </LabelValue>
                            </div>
                        </div>
                        <LabelValue
                            label="Country"
                            required={true}
                            fieldName="address.country"
                            comment=""
                        >
                            <Controller
                                name="address.country"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.address?.country ? "p-invalid" : ""}
                                        placeholder="Enter country"
                                    />
                                )}
                            />
                            {errors.address?.country &&
                                <small className="p-error">{errors.address.country.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Incorporation Date"
                            required={true}
                            fieldName="incorporationDate"
                            comment=""
                        >
                            <Controller
                                name="incorporationDate"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="yy-mm-dd"
                                        showIcon
                                        style={{width: '100%'}}
                                        className={errors.incorporationDate ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.incorporationDate &&
                                <small className="p-error">{errors.incorporationDate.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Election Effective Date"
                            required={true}
                            fieldName="electionEffectiveDate"
                            comment=""
                        >
                            <Controller
                                name="electionEffectiveDate"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="yy-mm-dd"
                                        showIcon
                                        style={{width: '100%'}}
                                        className={errors.electionEffectiveDate ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.electionEffectiveDate &&
                                <small className="p-error">{errors.electionEffectiveDate.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label=""
                            required={false}
                            fieldName="nameCheckbox"
                            comment=""
                        >
                            <Controller
                                name="nameCheckbox"
                                control={control}
                                render={({field}) => (
                                    <Checkbox
                                        {...field}
                                        inputId="nameCheckbox"
                                        checked={field.value}
                                    />
                                )}
                            />
                            <RadioCheckboxLabel
                                label="Name Checkbox"
                                htmlFor="nameCheckbox"
                            />
                        </LabelValue>
                        <LabelValue
                            label=""
                            required={false}
                            fieldName="addressCheckbox"
                            comment=""
                        >
                            <Controller
                                name="addressCheckbox"
                                control={control}
                                render={({field}) => (
                                    <Checkbox
                                        {...field}
                                        inputId="addressCheckbox"
                                        checked={field.value}
                                    />
                                )}
                            />
                            <RadioCheckboxLabel
                                label="Address Checkbox"
                                htmlFor="addressCheckbox"
                            />
                        </LabelValue>
                        <LabelValue
                            label=""
                            required={false}
                            fieldName="moreThan100ShareholdersCheckbox"
                            comment=""
                        >
                            <Controller
                                name="moreThan100ShareholdersCheckbox"
                                control={control}
                                render={({field}) => (
                                    <Checkbox
                                        {...field}
                                        inputId="moreThan100ShareholdersCheckbox"
                                        checked={field.value}
                                    />
                                )}
                            />
                            <RadioCheckboxLabel
                                label="More Than 100 Shareholders"
                                htmlFor="moreThan100ShareholdersCheckbox"
                            />
                        </LabelValue>
                        <LabelValue
                            label="Officer Name"
                            required={true}
                            fieldName="officerName"
                            comment=""
                        >
                            <Controller
                                name="officerName"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.officerName ? "p-invalid" : ""}
                                        placeholder="Enter officer name"
                                    />
                                )}
                            />
                            {errors.officerName && <small className="p-error">{errors.officerName.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Officer Title"
                            required={true}
                            fieldName="officerTitle"
                            comment=""
                        >
                            <Controller
                                name="officerTitle"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.officerTitle ? "p-invalid" : ""}
                                        placeholder="Enter officer title"
                                    />
                                )}
                            />
                            {errors.officerTitle && <small className="p-error">{errors.officerTitle.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Officer Phone Number"
                            required={true}
                            fieldName="officerPhoneNumber"
                            comment=""
                        >
                            <Controller
                                name="officerPhoneNumber"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.officerPhoneNumber ? "p-invalid" : ""}
                                        placeholder="Enter officer phone number"
                                    />
                                )}
                            />
                            {errors.officerPhoneNumber && <small className="p-error">{errors.officerPhoneNumber.message}</small>}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={styles.actionButtonHolder}>
                <ButtonPrimary
                    type="submit"
                    label="Next"
                />
            </div>
        </form>
    );
};

export default BusinessInformationForm;

