import { classNames } from 'primereact/utils';
import React, { FC, ReactNode, useMemo } from 'react';

import sharedStyles from '../../assets/sharedStyles.module.scss';

interface TThElementProps {
    columnName?: string;
    label?: string;
    children?: ReactNode;
    className?: string;
    clickHandler?: () => void;
    colSpan?: number;
    onSort?: (columnName: string) => void;
    isFiltered?: boolean;
    isAscSorted?: boolean;
    isDescSorted?: boolean;
}
const ThElement: FC<TThElementProps> = ({
label,
children,
className,
clickHandler,
colSpan,
columnName,
onSort,
isFiltered,
isAscSorted,
isDescSorted,
}) => {
    const sortable = Boolean(onSort);
    const handleColumnClick = (columnName: string) => {
        if (onSort) {
            onSort(columnName);
        }
    }
    const sortIconClassName = useMemo(() => {
        if (isAscSorted || (!isAscSorted && !isDescSorted)) {
            return 'bi bi-sort-down';
        }
        return 'bi bi-sort-up';
    }, [isAscSorted, isDescSorted]);
    return (
        <th
            className={classNames(sharedStyles.tableHead, className, {
                [sharedStyles.sortable]: sortable,
                [sharedStyles.sorted]: isAscSorted || isDescSorted,
                [sharedStyles.filtered]: isFiltered,
            })}
            onClick={clickHandler}
            colSpan={colSpan}
        >
            {
                children ? (
                    children
                ) : (
                    <>
                        <span className={sharedStyles.headLabel} onClick={() => handleColumnClick(columnName as string)}>{label}</span>
                        {
                            sortable && (
                                <i className={classNames(sortIconClassName, sharedStyles.sortIcon)}
                                    onClick={() => handleColumnClick(columnName as string)}
                                />
                            )
                        }
                    </>
                )
            }
        </th>
    );
};

export default ThElement;
