import React from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import styles from './acknowledgement.module.scss';
import ProcessedAcknowledgements from "./ProcessedAcknowledgements";
import UploadAcknowledgement from "./UploadAcknowledgement";

enum tabRoutes {
    '' = 0,
    'upload' = 1,
}

const Acknowledgements = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const activeIndex = location.pathname === '/acknowledgements' ? 0 : 1;
    return (
        <div className={styles.acknowledgement}>
            <div>
                <TabView
                    onTabChange={(e) => {
                        navigate(`${tabRoutes[e.index]}`);
                    }}
                    activeIndex={activeIndex}
                    className={styles.tabViewCustom}
                >
                    <TabPanel
                        header="Processed files"
                        className={styles.tabHeader}
                    />
                    <TabPanel
                        header="Upload files"
                        className={styles.tabHeader}
                    />
                </TabView>
            </div>
            <Routes>
                <Route path={tabRoutes[`${0}`]} element={<ProcessedAcknowledgements />} />
                <Route path={tabRoutes[`${1}`]} element={<UploadAcknowledgement />} />
                <Route path="/*" element={<Navigate to={tabRoutes[`${0}`]} replace />} />
            </Routes>
        </div>
    )
}

export default Acknowledgements;
