import { useState } from "react";
import { DocumentStatus } from "../../../types";
import { Button } from "primereact/button";
import FilePreviewOverlay from "../../../../../uiComponents/FilePreviewOverlay/FilePreviewOverlay";
import { useQuery } from "@tanstack/react-query";
import { DocumentService } from "../../../DocumentService";

type PropTypes = {
  orderNumber: string;
  docStatus: DocumentStatus;
};
export const FilePreviewAction = ({ orderNumber, docStatus }: PropTypes) => {
  const [showPreview, setShowPreview] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: DocumentService.filePreview.queryKey({ orderNumber, docStatus }),
    queryFn: DocumentService.filePreview.queryFn,
    enabled: showPreview && !!docStatus && !!orderNumber,
  });

  return (
    <>
      <Button
        icon="pi pi-eye"
        className="p-button-rounded p-button-text"
        onClick={() => setShowPreview(true)}
      />
      {showPreview && data?.url && (
        <FilePreviewOverlay
          preview={data?.url}
          closePreview={() => setShowPreview(false)}
        />
      )}
    </>
  );
};
