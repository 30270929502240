import moment from "moment";
import { Document } from "../../../types";

type PropTypes = {
  document: Document;
};

export const RequestedDateBodyTemplate = ({ document }: PropTypes) => {
  return (
    <div>
      {moment.utc(document.createdDateTime).format("MMM D, YYYY") ??
        "--------------------------"}
    </div>
  );
};
