import React, { FC } from 'react';
import styles from './shareholders.module.scss';
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {OwnershipMetric, TAdditionalInformationParams, TOwnershipMetric} from "./types";
import {Dropdown} from "primereact/dropdown";

const ownershipMetricOptions: { label: string; value: TOwnershipMetric }[] = [
    {
        label: 'Number of shares',
        value: OwnershipMetric.NUMBER_OF_SHARES,
    },
    {
        label: 'Percentage',
        value: OwnershipMetric.PERCENTAGE,
    }
];

interface TOwnershipMetricModalProps {
    onPatchAdditionalInformation: (params: TAdditionalInformationParams) => void;
}
const OwnershipMetricModal: FC<TOwnershipMetricModalProps> = ({
onPatchAdditionalInformation,
}) => {
    const [value, setValue] = React.useState<TOwnershipMetric | ''>('');
    const onAccept = () => {
        onPatchAdditionalInformation({ ownershipMetric: value as TOwnershipMetric });
    }
    return (
        <div className={styles.ownershipMetricModalOverlay}>
            <div className={styles.modal}>
                <div className={styles.selectRow}>
                    <p className={styles.msg}>Choose ownership metric</p>
                    <Dropdown
                        value={value}
                        placeholder="Select ownership metric"
                        style={{ minWidth: '200px' }}
                        options={ownershipMetricOptions}
                        onChange={(e) => setValue(e.value)}
                    />
                </div>
                <ButtonPrimary
                    onClick={onAccept}
                    disabled={!value}
                    label="Accept"
                />
            </div>
        </div>
    )
}

export default OwnershipMetricModal;
