import React, { FC } from 'react';
import styles from "../shared.module.scss";
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {useMutation} from "@tanstack/react-query";
import {Form2553Service} from "../../../Service";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../../hooks/useToast";
import {useNavigate} from "react-router-dom";
import {stepsKeys, TStep} from "../../StepsControl/StepsContro";

interface TProcessProps {
    companyId: number;
    formId: string;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
}
const Process: FC<TProcessProps> = ({ companyId, formId, setStep }) => {
    const { show } = useToast();
    const navigate = useNavigate();

    const postProcess = useMutation({
        mutationFn: Form2553Service.postProcess,
        onSuccess: (response: AxiosResponse) => {
            navigate('/form2553');
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postProcess.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const isProcessing = postProcess?.isPending;
    const onProcess = () => {
        postProcess.mutate({ companyId, formId });
    }
    const onPrev = () => {
        setStep(stepsKeys.SIGNER_INFORMATION);
    }
    return (
        <div className={styles.stepSection}>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '48px' }}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    type="button"
                    label="Back"
                    onClick={onPrev}
                    disabled={isProcessing}
                />
                <ButtonPrimary
                    style={{ marginLeft: '12px' }}
                    label="Process"
                    onClick={onProcess}
                    disabled={isProcessing}
                />
            </div>
        </div>
    )
}

export default Process;
