import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';
import './index.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import {ToastProvider, useToast} from './hooks/useToast';
import SingIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
import {setAuthorizationHeader} from "./services/http-common";
import axios from "axios";
import RecoverPassword from "./pages/Authentication/RecoverPassword";
import PublicFile83b from "./pages/PublicFile83b/PublicFile83b";
import {extractErrorMessage} from "./helpers/extractErrorMessage";
import DocGenerationSuccess from "./components/DocumentGeneration/DocGenerationSuccess";
import {App} from "./components/App/App";
import DocGenerationOrder from "./components/DocumentGeneration/DocGenerationOrder";
import GoogleSignInRedirectPage from "./pages/Authentication/GoogleSignInRedirectPage";
import PublicOAIFile83b from "./pages/PublicOAIFile83b/PublicOAIFile83b";
import PublicDraftFile83b from "./pages/PublicFile83b/PublicDraftFile83b";
import TermsOfService from "./pages/PolicyPages/TermsOfService";
import PrivacyPolicy from "./pages/PolicyPages/PrivacyPolicy";
import TermsFor83b from "./pages/PolicyPages/Terms83b";

const baseUrl = import.meta.env.VITE_CORPORA_SERVICE_URL;

export const CORPORAQueryClient = new QueryClient();
const RenderApp = () => {
    const { show } = useToast();
    const [loggedIn, setLoggedIn] = useState(false);
    const [initApp, setInitApp] = useState(false);
    const [logOutLoading, setLogOutLoading] = useState(false);

    const userLogIn = () => {
        setLoggedIn(true);
    }

    const saveValuesToSessionStorage = () => {
        const paramsUrl = window.location.search;
        const params = new URLSearchParams(paramsUrl);
        const email = params.get('email');
        const fileId = params.get('fileId');
        const name = params.get('name');
        const companyId = params.get('companyId');

        if (email && fileId && name && companyId) {
            const obj = { email, fileId, name, companyId };
            sessionStorage.setItem('userInfo', JSON.stringify(obj));
        }
    }

    const userLogOut = () => {
      setLogOutLoading(true);
      const refreshToken = localStorage.getItem('refreshToken');
      axios.post(`${baseUrl}api/auth/logout`, { token: refreshToken })
          .then(() => {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('refreshToken');
              sessionStorage.removeItem('userInfo');
              setLoggedIn(false);
              setLogOutLoading(false);
              localStorage.setItem('logout', Date.now().toString());
          })
          .catch((error) => {
              setLogOutLoading(false);
              const errorData = {
                  error: error,
                  show: show,
              };
              extractErrorMessage(errorData);
          });
    }

    useEffect(() => {
        saveValuesToSessionStorage();
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            setLoggedIn(true);
            setAuthorizationHeader(accessToken);
        }
        setInitApp(true);
    }, []);

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'logout') {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    if (!initApp) {
        return <div className="bg" />
    }
    return (
        <ErrorBoundary>
          <BrowserRouter>
            <QueryClientProvider client={CORPORAQueryClient}>
              <ToastProvider>
                  <Routes>
                      <Route path="/file83b" element={<PublicFile83b />} />
                      <Route path="/ext-file83b/:uuid" element={<PublicDraftFile83b />} />
                      <Route path="/oai83b" element={<PublicOAIFile83b />} />
                      <Route
                          path="/doc-generation-success"
                          element={<DocGenerationSuccess userLogIn={userLogIn} loggedIn={loggedIn} />}
                      />
                      <Route path="/search83b" element={<DocGenerationOrder />} />
                      <Route path="/terms-of-service" element={<TermsOfService />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                      <Route path="/83b-terms" element={<TermsFor83b />} />
                      {
                          loggedIn ? (
                              <>
                                  <Route
                                      path="/*"
                                      element={
                                        <App userLogOut={userLogOut} logOutLoading={logOutLoading} />
                                      }
                                  />
                              </>
                          ) : (
                              <>
                                  <Route path="/" element={<SingIn userLogIn={userLogIn} />} />
                                  <Route path="/google-signin/*" element={<GoogleSignInRedirectPage userLogIn={userLogIn} />} />
                                  <Route path="/sign-up" element={<SignUp />} />
                                  <Route path="/recover-password" element={<RecoverPassword />} />
                                  <Route path="/*" element={<Navigate to="/" replace />} />
                              </>
                          )
                      }
                  </Routes>
              </ToastProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </ErrorBoundary>
      );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RenderApp />,
);
