import React, { FC } from 'react';
import styles from "../styles.module.scss";
import FileLoadingHighlight from "./FileLoadingHightlight";

interface TFileViewProps {
    isLoading: boolean;
    formValuesMergedDoc: string;
}

const FileView: FC<TFileViewProps> = ({ isLoading, formValuesMergedDoc }) => {
    return (
        <div className={styles.docViewWrapper}>
            <div className={styles.docViewGrid}>
                {isLoading ? (
                    <FileLoadingHighlight/>
                ) : (
                    <div
                        dangerouslySetInnerHTML={{__html: formValuesMergedDoc}}
                        className={styles.preview}
                    />
                )}
            </div>
        </div>
    )
}

export default FileView;
