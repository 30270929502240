import React from 'react';
import logoDark from '../../assets/images/corpora-logo-dark.png';
import styles from './policyPages.module.scss';
import {useQuery} from "@tanstack/react-query";
import {TermsTypeKeys} from "../TermsUpdate/utils";
import Loading from "../../components/Loading/Loading";
import EditorResult from "../../components/HtmlEditor/EditorResult";
import {formatToStandardDateUTC} from "../../helpers/convertDates";
import {PrivacyService} from "./PolicyService";

const TermsOfService = () => {
    const { data: latestPolicyContentData, isLoading } = useQuery({
        queryKey: ['terms-of-service'],
        queryFn: () => PrivacyService.getContentByType(TermsTypeKeys.TERMS_OF_SERVICE),
        gcTime: 0,
        staleTime: 0,
    });
    const latestPolicyContent = latestPolicyContentData?.data;
    const policyContent = latestPolicyContent?.text;
    const lastUpdate = latestPolicyContent?.createdAt;
    const version = latestPolicyContent?.version;
    const lastUpdateLabel = formatToStandardDateUTC(lastUpdate);
    return (
        <div className={styles.policyPage}>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                    <img src={logoDark} alt="" width="169px" />
                </div>
            </div>
            <div className={styles.policyContent}>
                <div className={styles.contentContainer}>
                    {
                        isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Loading horizontalSpacing={120}/>
                            </div>
                        ) : (
                            <>
                                <p className={styles.lastUpdateLabel}>
                                    {`Last updated: ${lastUpdateLabel}`}
                                </p>
                                <p className={styles.policyVersion}>
                                    {`Version: ${version}`}
                                </p>
                                <div>
                                    <EditorResult
                                        content={policyContent}
                                    />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TermsOfService;
