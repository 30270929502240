import React, { FC } from 'react';

interface TBooleanBodyTemplateProps {
    isTrue: boolean;
}

const BooleanBodyTemplate: FC<TBooleanBodyTemplateProps> = ({ isTrue }) => {
    const checkIconStyles = { color: 'green', fontSize: '18px' };
    const closeIconStyles = { color: 'red', fontSize: '14px' };
    return (
        <div>
            {
                isTrue ? (
                    <i className="bi bi-check2" style={checkIconStyles} title="Yes" />
                ) : (
                    <i className="bi bi-x-lg" style={closeIconStyles} title="No" />
                )
            }
        </div>
    )
}


export default BooleanBodyTemplate;
