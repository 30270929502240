import { Placement } from '@popperjs/core';
import { classNames } from 'primereact/utils';
import React, { FC, ReactElement, useState, useRef } from 'react';
import { usePopper } from 'react-popper';

import useOutsideClick from '../../hooks/useOutsideClick';

import styles from './dropdownWrapper.module.scss';
import {Nullable} from "primereact/ts-helpers";

type TOptions = {
  value: string;
  label: string;
  iconClassName?: string;
  disabled?: boolean;
};
interface IDropdownWrapperProps {
  options: TOptions[];
  onSelect: (value: string) => void;
  children: ReactElement;
  className?: string;
  placement?: Placement;
  disabled?: boolean;
}
const DropdownWrapper: FC<IDropdownWrapperProps> = ({
  options,
  onSelect,
  children,
  className,
  placement,
  disabled,
}) => {
  const [isOpened, setOpenedState] = useState(false);
  const targetRef = useRef(null);
  const [referenceEl, setReferenceEl] =
    useState<Nullable<HTMLDivElement>>(null);
  const [popperEl, setPopperEl] = useState<Nullable<HTMLDivElement>>(null);
  const { styles: popperStyles } = usePopper(referenceEl, popperEl, {
    placement: placement || 'bottom',
  });

  const handleOutsideClick = () => {
    setOpenedState(false);
  };
  useOutsideClick(targetRef, handleOutsideClick);

  const handleSelect = (key: string) => {
    onSelect(key);
    setOpenedState(false);
  };

  return (
    <div ref={targetRef} className={styles.dropdownWrapper}>
      <div
        role="presentation"
        onClick={() => setOpenedState(!isOpened)}
        ref={disabled ? () => null : (ref) => setReferenceEl(ref)}
      >
        {children}
      </div>
      {isOpened && !disabled && (
        <div
          className={classNames(styles.dropdown, className)}
          ref={(ref) => setPopperEl(ref)}
          style={popperStyles.popper}
        >
          {options.map((dropdownOption, index) => {
            const { label, value, iconClassName, disabled } = dropdownOption;
            return (
              <div
                onClick={disabled ? () => null : () => handleSelect(value)}
                key={`${value}${index}`}
                className={classNames(styles.dropItem, {
                  [styles.disabled]: disabled,
                })}
              >
                <i className={classNames(iconClassName, styles.iconEl)} />
                <p className={styles.dropLabel}>{label}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownWrapper;
