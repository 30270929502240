import React, { FC } from 'react';

interface TCloseButtonProps {
    className?: string;
    onClick: () => void;
}
const CloseButton: FC<TCloseButtonProps> = ({ className, onClick }) => {
    return (
        <button type="button" className={className} onClick={onClick}>
            <i className="bi bi-x-lg" style={{ fontSize: '18px' }}></i>
        </button>
    )
}

export default CloseButton
