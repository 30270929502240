import { QueryFunctionContext } from "@tanstack/react-query";
import { HttpService } from "../../services/HttpService";
import { documentQueryKeys } from "./query-keys";
import { DocumentStatus, FilterQueryParams } from "./types";

const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;

const fetchDocuments = (params: FilterQueryParams, signal?: AbortSignal) =>
  HttpService.get("/api/admin/file83b/search", {
    params,
    baseURL,
    signal,
  });

const fetchCompanyNames = () =>
    HttpService.get("api/admin/file83b/company-names", {
        baseURL,
    });

const fetchCompanies = () =>
    HttpService.get("api/admin/file83b/companies", {
        baseURL,
    });

const downloadCSV = (params: FilterQueryParams, signal?: AbortSignal) =>
  HttpService.post<string>(
    "/api/admin/file83b/csv",
    {
      ...params,
     statuses: params.statuses !== '' ? params.statuses?.split(',') : [],
    },
    { baseURL, signal }
  );

const documents = {
  queryKey: documentQueryKeys.documents,
  queryFn: ({
    queryKey: [{ params }],
  }: QueryFunctionContext<ReturnType<typeof documentQueryKeys.documents>>) =>
    fetchDocuments(params).then(({ data }) => data),
  downloadCSV,
};

const deleteDocument = (id: Number) =>
  HttpService.delete(`/api/admin/file83b/${id}`, { baseURL });

const updateUspsNumber = ({
  orderNumber,
  trackingNumber,
}: {
  orderNumber: string;
  trackingNumber: string;
}) =>
  HttpService.post(
    `/api/admin/file83b/${orderNumber}/usps-tracking-number`,
    {
      trackingNumber,
    },
    {
      baseURL,
    }
  );

const uploadDoc = ({
  orderNumber,
  status,
  formData,
}: {
  orderNumber: string;
  status: DocumentStatus;
  formData: FormData;
}) =>
  HttpService.post(
    `/api/admin/file83b/${orderNumber}/uploadFile/${status}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      baseURL,
    }
  );

const document = {
  delete: deleteDocument,
  updateUspsNumber,
  uploadDoc,
};

const getDocumentUrl = (orderNumber: string, signal?: AbortSignal) =>
  HttpService.get<string>(
    `/api/admin/file83b/${orderNumber}/ready-to-mail`,
    {
      baseURL,
      signal,
    }
  );

const generatedPDFPreview = {
  queryKey: documentQueryKeys.generatedPDFPreview,
  queryFn: ({
    queryKey: [{ orderNumber }],
    signal,
  }: QueryFunctionContext<
    ReturnType<typeof documentQueryKeys.generatedPDFPreview>
  >) => getDocumentUrl(orderNumber, signal).then(({ data }) => data),
};

const getDocumentDetails = (orderNumber: string, signal?: AbortSignal) =>
  HttpService.get<Record<string, string>>(
    `/api/admin/file83b/${orderNumber}/scm-request-info`,
    {
      baseURL,
      signal,
    }
  );

const sendSCM = (orderNumber: string, signal?: AbortSignal) =>
  HttpService.post(
    `/api/admin/file83b/send-scm`,
    { orderNumber },
    {
      baseURL,
      signal,
    }
  );

const generatedPDFDetails = {
  queryKey: documentQueryKeys.generatedPDFDetails,
  queryFn: ({
    queryKey: [{ orderNumber }],
    signal,
  }: QueryFunctionContext<
    ReturnType<typeof documentQueryKeys.generatedPDFDetails>
  >) => getDocumentDetails(orderNumber, signal).then(({ data }) => data),
  sendSCM,
};

const getFileUrl = (
  {
    orderNumber,
    docStatus,
  }: {
    orderNumber: string;
    docStatus: DocumentStatus;
  },
  signal?: AbortSignal
) =>
  HttpService.post<{ url: string }>(
    `/api/admin/file83b/downloadFile/${docStatus}/${orderNumber}`,
    {},
    {
      baseURL,
      signal,
    }
  );

const filePreview = {
  queryKey: documentQueryKeys.filePreview,
  queryFn: ({
    queryKey: [{ orderNumber, docStatus }],
    signal,
  }: QueryFunctionContext<ReturnType<typeof documentQueryKeys.filePreview>>) =>
    getFileUrl({ orderNumber, docStatus }, signal).then(({ data }) => data),
};

const generateFedexLabels = (params: any) =>
    HttpService.post(
        `/api/admin/file83b/export-data`,
        params,
        { baseURL }
    );

const downloadLabel = (orderNumber: any) =>
    HttpService.post(
        `/api/admin/file83b/downloadFile/label/${orderNumber}`,
        {},
        { baseURL }
    );

export const DocumentService = {
  document,
  documents,
  generatedPDFPreview,
  generatedPDFDetails,
  filePreview,
  fetchCompanyNames,
  generateFedexLabels,
  downloadLabel,
  fetchCompanies,
};
