import React, { FC } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Form2553Creation from "./Form2553Creation/Form2553Creation";
import FilingList from "./FilingList/FilingList";

interface TForm2553RoutesProps {
    companyId: number;
}

const Form2553Routes: FC<TForm2553RoutesProps> = ({ companyId }) => {
    return (
        <Routes>
            <Route path="/" element={<FilingList companyId={companyId} />} />
            <Route path="/create" element={<Form2553Creation companyId={companyId} />} />
            <Route path="/update/:formIdParam" element={<Form2553Creation companyId={companyId} />} />
            <Route path="/*" element={<Navigate to="" replace />} />
        </Routes>
    )
}

export default Form2553Routes;
