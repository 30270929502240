import React, {FC, useRef, useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import {TRole, TUserRole, User} from "../../../@types/global";
import {UserRoleLabels, userRoleOptions} from "../../../config/config";
import sharedStyles from "../../../assets/sharedStyles.module.scss";
import styles from './editableRoleControe.module.scss';
import {Button} from "primereact/button";
import useOutsideClick from "../../../hooks/useOutsideClick";

interface TEditableRoleControlProps {
    value: TUserRole;
    userInfo: User,
    openedRowId: number;
    editUserRole: React.Dispatch<React.SetStateAction<number>>;
    onChangeRole: (params: User) => void;
}

const EditableRoleControl:FC<TEditableRoleControlProps> = ({
value,
openedRowId,
editUserRole,
onChangeRole,
userInfo,
}) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState(value);
    const { id } = userInfo;
    const onChange = (newValue: TRole) => {
        setInputValue(newValue);
        const params = {
            ...userInfo,
            role: newValue,
        };
        onChangeRole(params);
    }
    const handleOutsideClick = () => {
        editUserRole(0);
    }
    useOutsideClick(targetRef, handleOutsideClick);
    const isEditMode = id === openedRowId;
    const disableEditRole = () => {
        editUserRole(0);
    }
    return (
        <div className={styles.editRoleControl}>
            {
                isEditMode ? (
                    <Dropdown
                        value={inputValue}
                        onChange={(e) => {
                            onChange(e.value);
                        }}
                        options={userRoleOptions}
                        placeholder="Select a City"
                        className={styles.customSelect}
                    />
                ) : (
                    <div className={styles.controlHolder} onDoubleClick={() => editUserRole(id)}>
                        <p className={sharedStyles.dataLabel}>{UserRoleLabels[value]}</p>
                    </div>
                )
            }
            <div className={styles.editButtonHolder}>
                {
                    isEditMode && (
                        <Button
                            className="p-button-rounded p-button-text"
                            icon={`pi pi-times`}
                            onClick={disableEditRole}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default EditableRoleControl;
