import { classNames } from 'primereact/utils';
import React, { FC } from 'react';

import toggleIcon from '../../assets/icons/left-arrow-toggle-menu.svg';
import logoImage from '../../assets/icons/logo-and-text.svg';

import styles from './sidebar.module.scss';

interface ILogoSectionProps {
  openCloseSidebar: () => void;
  isClosed: boolean;
  companyName?: string;
}

const LogoSection: FC<ILogoSectionProps> = ({
  openCloseSidebar,
  isClosed,
  companyName,
}) => {
  return (
    <div className={styles.logoSection}>
      <div className={styles.logoHolder}>
        <img src={logoImage} alt="" />
      </div>
      {!isClosed && (
        <p
          className={classNames(styles.companyName, styles.delayedSmoothAppear)}
        >
          {companyName}
        </p>
      )}
        <button
            type="button"
            className={classNames(styles.toggleBtn, {
                [styles.btnToggled]: isClosed,
            })}
            onClick={openCloseSidebar}
        >
            <img src={toggleIcon} alt=""/>
        </button>
    </div>
  );
};

export default LogoSection;
