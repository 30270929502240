import React, {useEffect, FC} from 'react';
import {useMutation} from "@tanstack/react-query";
import {requests, setAuthorizationHeader} from "../../services/http-common";
import {AxiosError} from "axios";
import styles from "./authentication.module.scss";
import Loading from "../../components/Loading/Loading";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";

interface TGoogleSignInRedirectPageProps {
    userLogIn: () => void;
}
const GoogleSignInRedirectPage:FC<TGoogleSignInRedirectPageProps> = ({ userLogIn }) => {
    const paramsUrl = window.location.search;
    const params = new URLSearchParams(paramsUrl);
    const state = params.get('state');
    const code = params.get('code');
    const { show } = useToast();

    const getTokens = useMutation({
        mutationFn: async () => {
            const stateStr = state ? `&state=${state}` : '';
            const url = `/api/auth/sso/callback?code=${code}${stateStr}`;
            return await requests.get(url);
        },
        onSuccess: (response) => {
            const data = response as { token: string; refreshToken: string };
            const { token, refreshToken } = data;
            localStorage.setItem('accessToken', token);
            localStorage.setItem('refreshToken', refreshToken);
            setAuthorizationHeader(token);
            userLogIn();
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getTokens.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const onRedirect = () => {
        getTokens.mutate();
    }
    useEffect(() => {
        onRedirect();
    }, []);
    return (
        <div className={styles.authentication}>
            <div className={styles.ssoLayer}>
                <Loading />
            </div>
        </div>
    )
}

export default GoogleSignInRedirectPage;
