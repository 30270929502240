import { HttpService } from "../../services/HttpService";
import {DocumentStatus} from "../../modules/Document/types";

const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;

const getUploadUrl = (params: { fileName: string }) =>
    HttpService.post<string>(
        "/api/admin/textract/presigned-url",
        params,
        { baseURL }
    );

const triggerUploadComplete = (params: { uuid: string }) =>
    HttpService.put<string>(
        "/api/admin/textract/upload-complete",
        params,
        { baseURL }
    );

const getProcessingFiles = (urlParams: string) => {
    return HttpService.get(`api/admin/acknowledged-files?${urlParams}`, {
        baseURL,
    });
}

const getGeneratedFileUrl = ({ orderNumber, docStatus } : { orderNumber: string, docStatus: DocumentStatus }) => {
    return HttpService.post(
        `/api/admin/file83b/downloadFile/${docStatus}/${orderNumber}`,
        {},
        {
            baseURL,
        }
    );
}

const getProcessingFileUrl = (orderNumber: string) => {
    return HttpService.get(`api/admin/acknowledged-files/presigned-url/${orderNumber}`, {
        baseURL,
    });
}

const confirmProcessedFile = ({ orderNumber } : { orderNumber: string }) => {
    return HttpService.put(
        `api/admin/acknowledged-files/${orderNumber}`,
        {},
        {
            baseURL,
        }
    );
}

export const AcknowledgementService = {
    getUploadUrl,
    triggerUploadComplete,
    getProcessingFiles,
    getGeneratedFileUrl,
    getProcessingFileUrl,
    confirmProcessedFile,
}