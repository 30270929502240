import React, { FC } from 'react';
import usePagination from "../../../hooks/usePagination";
import {useToast} from "../../../hooks/useToast";
import generateUrlParams from "../../../helpers/generateUrlParams";
import {useQuery} from "@tanstack/react-query";
import {Form2553Service} from "../Service";
import styles from './fillingList.module.scss';
import Loading from "../../../components/Loading/Loading";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {useNavigate} from "react-router-dom";
import sharedStyles from "../../../assets/sharedStyles.module.scss";
import ReactPaginate from "react-paginate";
import {Dropdown} from "primereact/dropdown";
import {perPageOptions} from "../../../config/config";

interface TFilingListProps {
    companyId: number;
}
const FilingList: FC<TFilingListProps> = ({ companyId }) => {
    const { params, changePage, changePerPage } = usePagination();
    const { page, size} = params;
    const { show } = useToast();
    const navigate = useNavigate();

    const urlParams = generateUrlParams({
        page,
        size,
    });

    const { data: filesData, isLoading, refetch} = useQuery({
        queryKey: ['form-2553-files', page, size],
        queryFn: () =>  Form2553Service.getForm2553Files(urlParams, companyId),
        gcTime: 0,
    });
    const goToCreation = () => {
        navigate('/form2553/create');
    }
    const goToUpdate = (id: number) => {
        navigate(`/form2553/update/${id}`);
    }
    const files = filesData?.data?.content || [];
    const isZeroRecords = files.length === 0;
    const totalElements = filesData?.data?.totalElements || 0;
    const pageCount = Math.ceil(totalElements / size);
    const onPageChange = ({ selected }: { selected: number }) => {
        changePage(selected);
    }
    return (
        <div className={styles.filings}>
            <div className={styles.header}>
                <div></div>
                <div>
                    <ButtonPrimary
                        onClick={goToCreation}
                        iconClassName="bi-plus"
                        label="Add new filing"
                    />
                </div>
            </div>
            <div>

            </div>
            {
                isLoading ? (
                    <div className={styles.loadingWrapper}>
                        <Loading horizontalSpacing={191}/>
                    </div>
                ) : (
                    <div className={styles.filesGrid}>
                        {
                            files.map((item: any, index: number) => {
                                return (
                                    <div key={index} className={styles.gridItem}>
                                        <div>
                                            {`File ${item.id}`}
                                        </div>
                                        <div>
                                            <ButtonPrimary
                                                onClick={() => goToUpdate(item.id)}
                                                label="Update"
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            <div>
                {
                    !isZeroRecords && (
                        <div className={sharedStyles.paginationControl}>
                            <div className={sharedStyles.perPageRow}>
                                {`Showing ${(page) * size + 1} to ${((page) * size) + files.length} of ${totalElements} entries`}
                            </div>
                            <div className={sharedStyles.changeControl}>
                                <ReactPaginate
                                    className={sharedStyles.paginationWrapper}
                                    breakLabel="..."
                                    nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                    onPageChange={onPageChange}
                                    pageRangeDisplayed={size}
                                    pageCount={pageCount}
                                    previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                    renderOnZeroPageCount={null}
                                    pageLabelBuilder={(page) => (
                                        <div className={sharedStyles.pItem}>{page}</div>
                                    )}
                                    activeClassName={sharedStyles.pItemActive}
                                    forcePage={page}
                                />
                                <div className={sharedStyles.perPageHolder}>
                                    <Dropdown
                                        options={perPageOptions}
                                        value={size}
                                        onChange={(e) => {
                                            changePerPage(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default FilingList;
