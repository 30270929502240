import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import sharedStyles from "../../assets/sharedStyles.module.scss";
import React from "react";

type ReactPdfViewerProps = {
  fileUrl: string;
  closePreview: () => void;
};

export const ReactPdfViewer = ({ fileUrl, closePreview }: ReactPdfViewerProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
          <div
              style={{
                  height: '100%',
                  position: 'relative',
              }}
          >
              <button className={sharedStyles.closeDocPreview} onClick={closePreview}>
                  <i className="bi bi-x"/>
              </button>
              <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]}/>
          </div>
      </Worker>
  );
};
