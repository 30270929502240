import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {FilterParams} from "../../types";

export const useFilters = ({
                               from: initialFrom,
                               to: initialTo,
                               statuses: initialStatuses,
                               size: initialSize,
                               page: initialPage,
                               sort: initialSort,
                               query: initialQuery,
                               companyName: companyName,
                               signWellViewed: initialSignWellViewed,
                               paid: initialPaid,
                               companyId: initialCompanyId,
                           }: FilterParams) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState({
        from: initialFrom || searchParams.get("from") || "",
        to: initialTo || searchParams.get("to") || "",
        statuses: initialStatuses || searchParams.get("statuses")?.split(",") || [],
        size: initialSize || parseInt(searchParams.get("size") as string) || 10,
        page: initialPage || parseInt(searchParams.get("page") as string) || 0,
        sort: initialSort || searchParams.get("sort")?.split(",") || [],
        query: initialQuery || searchParams.get("query") || "",
        companyName: companyName || searchParams.get("companyName") || '',
        signWellViewed: initialSignWellViewed || searchParams.get("signWellViewed") || 'NONE',
        paid: initialPaid || searchParams.get("paid") || 'NONE',
        companyId: initialCompanyId || searchParams.get("companyId") || '',
    });

    useEffect(() => {
        const params = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                if (Array.isArray(value)) {
                    if (value.length > 0) {
                        params.set(key, value.join(","));
                    }
                } else {
                    params.set(key, value as string);
                }
            }
        });
        setSearchParams(params, {replace: true});
    }, [filters]);

    return {
        filters,
        setFilters,
    };
};
