import { useState } from "react";
import { Document, FilterQueryParams } from "../../../types";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import styles from "./uspsTrackingNumberBodyTemplate.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DocumentService } from "../../../DocumentService";
import { useToast } from "../../../../../hooks/useToast";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";

type PropTypes = {
  document: Document;
};

export const UspsTrackingNumberBodyTemplate = ({ document }: PropTypes) => {
  const [isEditMode, setEditMode] = useState(false);

  const [trackingNumber, setTrackingNumber] = useState(
    document.trackingNumber || ""
  );

  const queryClient = useQueryClient();
  const { show } = useToast();

  const { mutate: updateTrackingNumber } = useMutation({
    mutationFn: DocumentService.document.updateUspsNumber,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: DocumentService.documents.queryKey({} as FilterQueryParams),
      });
      setEditMode(false);
      show({ detail: "Tracking number updated successfully!" });
    },
    onError: (error: AxiosError<{ message: string }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: updateTrackingNumber,
        show: show,
      };
      extractErrorMessage(errorData);
    },
  });

  const handleSave = () => {
    updateTrackingNumber({
      orderNumber: document.orderNumber,
      trackingNumber: trackingNumber,
    });
  };

  return (
    <div className={styles.container}>
      {isEditMode ? (
        <>
          <InputText
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
          <div className={styles.actions}>
            <Button
              className={"p-button-rounded p-button-text"}
              icon="pi pi-times"
              severity="secondary"
              onClick={() => setEditMode(false)}
            />
            <Button
              className={"p-button-rounded p-button-text"}
              icon="pi pi-check"
              severity="success"
              onClick={handleSave}
            />
          </div>
        </>
      ) : (
        <>
          <p className={styles.columnInfo}>
            {document?.trackingNumber || "----------------------"}
          </p>
          <Button
            className={"p-button-rounded p-button-text"}
            icon="pi pi-pencil"
            onClick={() => setEditMode(true)}
          />
        </>
      )}
    </div>
  );
};
