import React, {FC, useMemo} from 'react';
import styles from '../bulkUploadUsers.module.scss';
import {Dropdown} from 'primereact/dropdown';
import {THeaderState, THeaderStateItem} from "../BulkUploadUsers";
import {Nullable} from "primereact/ts-helpers";

export type THeaderConfigItem = {
    key: string;
    label: string;
    name: string;
};

export type TCsvHeadOptionItem = {
    name: string;
    required: boolean;
    fieldType: string;
    label: string;
}

interface TTableHeadProps {
    options: THeaderConfigItem[],
    csvHeaderOptions: TCsvHeadOptionItem[];
    headerState: Nullable<THeaderState>;
    onChangeColumnName: (key: string, value: THeaderStateItem) => void;
}

const TableHead: FC<TTableHeadProps> = ({
options = [],
csvHeaderOptions = [],
headerState,
onChangeColumnName,
    }) => {
    const dropdownOption = useMemo(() => {
        return (csvHeaderOptions || []).map((item: TCsvHeadOptionItem) => {
            return {
                label: item.label,
                value: item.name,
            }
        });
    }, [csvHeaderOptions]);
    return (
        <thead>
            <tr>
                {
                    options.map((configItem, ) => {
                        const { key, name} = configItem;
                        const selectValue = headerState && headerState[name].key;
                        return (
                            <th className={styles.tableHead} key={key}>
                                <Dropdown
                                    onChange={(e) => {
                                        const newOptionKey = e.target.value;
                                        const newOptionItem = options.find((obj) => obj.key === newOptionKey);
                                        const newVal = {
                                            label: newOptionItem?.label,
                                            key: newOptionItem?.key,
                                        };
                                        onChangeColumnName(name, newVal as THeaderStateItem);
                                    }}
                                    style={{ width: "100%" }}
                                    options={dropdownOption}
                                    value={selectValue}
                                    disabled={true}
                                />
                            </th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}

export default TableHead;