import React, {useState, useMemo, useRef, FC} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Dropdown } from 'primereact/dropdown';
import { useToast } from '../../hooks/useToast';
import {extractErrorMessage} from "../../helpers/extractErrorMessage";

import styles from './manageUser.module.scss';
import {requests} from "../../services/http-common";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import CreateUserModal from "./CreateUserModal/CreateUserModal";
import sharedStyles from "../../assets/sharedStyles.module.scss";
import TdElement from "../../uiComponents/TableTypeA/TdElement";
import AvatarFrame from "../../uiComponents/AvatarFrame/AvatarFrame";
import {User} from "../../@types/global";
import Loading from "../../components/Loading/Loading";
import {InputText} from "primereact/inputtext";
import FieldWrapper from "../../uiComponents/FieldWrapper/FieldWrapper";
import DropdownWrapper from "../../uiComponents/DropdownWrapper/DropdownWrapper";
import {perPageOptions, successToastLife} from "../../config/config";
import {classNames} from "primereact/utils";
import usePagination from "../../hooks/usePagination";
import ConfirmDialogCustom from "../../uiComponents/ConfirmDianlogCustom/ConfirmDialogCustom";
import ThElement from "../../uiComponents/TableTypeA/ThElement";
import EditableRoleControl from "./EditableRoleControl/EditableRoleControl";
import useBulkSelect from "../../hooks/useBulkSelect";
import {Checkbox} from "primereact/checkbox";


type TActionType = 'delete' | 'suspend' | 'remind' | 'activate';
const actionKeys: Record<TActionType, TActionType> = {
    delete: 'delete',
    suspend: 'suspend',
    remind: 'remind',
    activate: 'activate'
};
type TActionModalInfoItem = {
    header: string;
    message: string;
    successLabel: string;
    confirmLabel: string;
};

type TUpdateUserRoleParams = { userId: number, params: User };

interface TManageUserProps {
    companyId: number;
}
const ManageUser: FC<TManageUserProps> = ({ companyId }) => {
    const [search, setSearch] = useState<string>('');
    const { params, changePage, changePerPage, onSort, isSorted } = usePagination();
    const { page, size, sort } = params;
    const [searchVal, setSearchVal] = useState('');
    const tempSearchKey = useRef('');
    const [showCreateUserModal, toggleModal] = useState<boolean>(false);
    const [actionUser, setActionUser] = useState<User | null>(null);
    const [actionType, setActionType] = useState<TActionType | ''>('');
    const timeoutIdRef = useRef<NodeJS.Timeout>();
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const [tBodyHeight, updateTBodyHeight] = useState(0);
    const loadingHeight = tBodyHeight > 0 ? { height: `${tBodyHeight}px` } : { height: 'auto' };
    const tableHolderRef = useRef<HTMLDivElement>(null);
    const [editEnabledUserId, editUserRole] = useState(0);
    const [loadingRowId, setLoadingRowId] = useState(0);
    const { show } = useToast();
    const {
        bulkSelectMode,
        bulkSelectedIds,
        onBulkSelect,
        toggleBulkMode,
        resetBulkState,
    } = useBulkSelect();
    const [bulkInviteConfirmation, setBulkInviteConfirmation] = useState<boolean>(false);
    const [bulkSuspendConfirmation, setBulkSuspendConfirmation] = useState<boolean>(false);

    const searchUrl = useMemo(() => {
        const searchStr = search ? `search=${search}&` : ``;
        const pageStr = `page=${page}&size=${size}`;
        const sortFormatted = sort.length > 0 ? sort.map((str) => `sort=${str}`) : [];
        const sortStr = sort.length > 0 ? `&${sortFormatted.join('&')}` : '';
        const searchUrl = `${searchStr}${pageStr}${sortStr}`;
        return `?${searchUrl}`;
    }, [page, size, sort, search]);

    const {
        data: usersData,
        isLoading: usersIsLoading,
        refetch: refetchUsersData,
    } = useQuery({
        queryKey: ['users', page, size, search, sort.join(''), companyId],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/users${searchUrl}`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
        retry: true,
    });
    const users = usersData?.content || [];
    const totalElements = usersData?.totalElements || 0;

    const deleteUserRequest = useMutation({
        mutationFn: async () => {
            return await requests.delete(
                `api/companies/${companyId}/users/${actionUser?.id}`,
            );
        },
        onSuccess: () => {
            void refetchUsersData();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: deleteUserRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const isDeleteLoading = deleteUserRequest?.isPending;

    const suspendUserRequest = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/companies/${companyId}/users/${actionUser?.id}/suspend`, {}
            );
        },
        onSuccess: () => {
            void refetchUsersData();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: suspendUserRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const isSuspendLoading = suspendUserRequest?.isPending;

    const remindUserRequest = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/companies/${companyId}/users/${actionUser?.id}/remind`, {}
            );
        },
        onSuccess: () => {
            void refetchUsersData();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: remindUserRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const isRemindLoading = remindUserRequest?.isPending;

    const activateUserRequest = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/companies/${companyId}/users/${actionUser?.id}/activate`, {}
            );
        },
        onSuccess: () => {
            void refetchUsersData();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: activateUserRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const isActivateLoading = activateUserRequest?.isPending;

    const closeModal = () => {
        toggleModal(false);
    }
    const reFetchUsers = () => {
        void refetchUsersData();
    }
    const setSearchTerm = (term: string) => {
        tempSearchKey.current = term;
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(
            () => {
                changePage(0);
                setSearch(tempSearchKey.current);
            }, 600
        );
    }

    const modalInfo = useMemo(() => {
        if (!actionType) {
            return {
                header: '',
                message: '',
                successLabel: '',
                confirmLabel: '',
            }
        }
        const labelObj: Record<string, TActionModalInfoItem> = {
            [actionKeys.delete]: {
                header: `Warning: Deleting ${actionUser?.name} and Associated Data`,
                message: 'Are you sure you want to delete this user? This action will permanently remove the user from the system, along with all associated data. If you are unsure, consider suspending the user account instead.',
                successLabel: 'Success delete',
                confirmLabel: 'Delete',
            },
            [actionKeys.suspend]: {
                header: 'Confirm User Suspension',
                message: 'Are you sure you want to suspend this user? Suspending the user will temporarily deactivate their account and restrict their access to the system. If you proceed, the user will not be able to log in or perform any actions until their account is reinstated.  Their information will be saved.',
                successLabel: 'Success suspend',
                confirmLabel: 'Suspend',
            },
            [actionKeys.remind]: {
                header: 'Resend invite',
                message: 'You’re about to resend the invitation to this user to sign up.  Do you wish to proceed?',
                successLabel: 'Success remind',
                confirmLabel: 'Proceed',
            },
            [actionKeys.activate]: {
                header: 'Confirm User Activation',
                message: 'Are you sure you want to activate this user?',
                successLabel: 'Successfully activated',
                confirmLabel: 'Activate',
            }
        }
        return labelObj[actionType as TActionType];
    }, [actionUser, actionType]);
    const { header, message, confirmLabel } = modalInfo;

    const onAcceptAction = () => {
        switch (actionType) {
            case actionKeys.delete:
                deleteUserRequest.mutate();
                break;
            case actionKeys.suspend:
                suspendUserRequest.mutate();
                break;
            case actionKeys.remind:
                remindUserRequest.mutate();
                break;
            case actionKeys.activate:
                activateUserRequest.mutate();
                break;
            default:
        }
    }

    const setTBodyHeight = () => {
        if (tableBodyRef?.current) {
            updateTBodyHeight(tableBodyRef?.current?.offsetHeight as number);
        }
    }
    const scrollTop = () => {
        if (tableHolderRef.current) {
            tableHolderRef.current.scrollIntoView();
        }
    };

    const onPageChange = ({ selected }: { selected: number }) => {
        scrollTop();
        setTBodyHeight();
        changePage(selected);
    }

    const onUpdateUserRole = useMutation({
        mutationFn: async (props: TUpdateUserRoleParams) => {
            return await requests.put(
                `api/companies/${companyId}/users/${props.userId}`, props.params
            );
        },
        onSuccess: () => {
            refetchUsersData().then(() => {
                editUserRole(0);
                setLoadingRowId(0);
            });
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: onUpdateUserRole.mutate,
                show: show,
                cb: () => {
                    setLoadingRowId(0);
                }
            };
            extractErrorMessage(errorData);
        },
    });

    const onChangeRole = (params: User) => {
        setLoadingRowId(params.id);
        onUpdateUserRole.mutate({
            userId: params.id,
            params,
        });
    }

    const bulkInvitationRequest = useMutation({
        mutationFn: async (params: number[]) => {
            return await requests.post(
                `api/companies/${companyId}/users/remind`,
                { ids: params }
            );
        },
        onSuccess: () => {
            resetBulkState();
            show({
                severity: "success",
                detail: "Remind sent to selected users successfully!",
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            resetBulkState();
            const errorData = {
                error: error,
                variables: variables,
                retryFn: bulkInvitationRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const bulkInvitationLoading = bulkInvitationRequest?.isPending;

    const onBulkInvite = () => {
        bulkInvitationRequest.mutate(bulkSelectedIds);
    }

    const openBulkInviteConfirmation = () => {
        setBulkInviteConfirmation(true);
    }

    const bulkSuspendRequest = useMutation({
        mutationFn: async (params: number[]) => {
            return await requests.post(
                `api/companies/${companyId}/users/suspend`,
                { ids: params }
            );
        },
        onSuccess: () => {
            resetBulkState();
            show({
                severity: "success",
                detail: "Remind sent to selected users successfully!",
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            resetBulkState();
            const errorData = {
                error: error,
                variables: variables,
                retryFn: bulkSuspendRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const bulkSuspendIsLoading = bulkSuspendRequest?.isPending;

    const onBulkSuspend = () => {
        bulkSuspendRequest.mutate(bulkSelectedIds);
    }
    const onBulkSuspendConfirmation = () => {
        setBulkSuspendConfirmation(true);
    }

    const pageCount = Math.ceil(totalElements / size);
    const tableLoading = usersIsLoading;
    const freezerLoading = isDeleteLoading || isSuspendLoading || isRemindLoading || isActivateLoading;
    const isZeroRecords = users.length === 0;
      return (
          <div className={styles.manageUser}>
              {
                  showCreateUserModal && (
                      <CreateUserModal
                          closeModal={closeModal}
                          reFetchUsers={reFetchUsers}
                          companyId={companyId}
                      />
                  )
              }
              <div className={styles.pageHolder} ref={tableHolderRef}>
                  <div className={styles.headerRow}>
                      <div>
                          <FieldWrapper isSearch>
                              <InputText
                                  value={searchVal}
                                  placeholder="Search user"
                                  onChange={(e) => {
                                      const newVal = e.target.value;
                                      setSearchVal(newVal);
                                      setSearchTerm(newVal);
                                  }}
                              />
                          </FieldWrapper>
                      </div>
                      <div className={styles.actionButtons}>
                          {
                              bulkSelectMode && (
                                  <>
                                      <ButtonPrimary
                                          onClick={openBulkInviteConfirmation}
                                          label="Resend bulk invitation"
                                          className={styles.actBtn}
                                          disabled={bulkSelectedIds?.length === 0 || bulkInvitationLoading}
                                      />
                                      <ButtonPrimary
                                          onClick={onBulkSuspendConfirmation}
                                          label="Bulk suspend user access"
                                          className={styles.actBtn}
                                          disabled={bulkSelectedIds?.length === 0 || bulkSuspendIsLoading}
                                      />
                                  </>
                              )
                          }
                          <ButtonPrimary
                              label="Add user"
                              iconClassName="bi-plus"
                              onClick={() => toggleModal(true)}
                          />
                          <button className={classNames(styles.bulkSelectModeBtn, {
                              [styles.btnActive]: bulkSelectMode,
                          })} onClick={toggleBulkMode}>
                              <i className="bi bi-pencil-square"></i>
                          </button>
                      </div>
                  </div>
                  <div className={styles.tableHolder}>
                      <div className={styles.tableWrapper}>
                          <table className={classNames(sharedStyles.tableTypeA, styles.tableCustom, {
                              [styles.tableLoading]: freezerLoading || bulkSuspendIsLoading || bulkInvitationLoading,
                          })}>
                              <thead>
                              <tr className={sharedStyles.tableRow}>
                                  <ThElement
                                      columnName="user.name"
                                      label="Name"
                                      onSort={onSort}
                                      isAscSorted={isSorted('user.name', sort)?.isAscSorted}
                                      isDescSorted={isSorted('user.name', sort)?.isDescSorted}
                                      colSpan={2}
                                  />
                                  <ThElement
                                      columnName="user.email"
                                      label="Email"
                                      onSort={onSort}
                                      isAscSorted={isSorted('user.email', sort)?.isAscSorted}
                                      isDescSorted={isSorted('user.email', sort)?.isDescSorted}
                                      colSpan={2}
                                  />
                                  <ThElement
                                      columnName="role"
                                      label="Role"
                                      onSort={onSort}
                                      isAscSorted={isSorted('role', sort)?.isAscSorted}
                                      isDescSorted={isSorted('role', sort)?.isDescSorted}
                                      colSpan={2}
                                  />
                                  <ThElement
                                      label="Status"
                                      colSpan={1}
                                  />
                                  <ThElement
                                      columnName="createdDateTime"
                                      label="Invited"
                                      onSort={onSort}
                                      isAscSorted={isSorted('createdDateTime', sort)?.isAscSorted}
                                      isDescSorted={isSorted('createdDateTime', sort)?.isDescSorted}
                                      colSpan={1}
                                  />
                                  <ThElement
                                      label=""
                                      colSpan={1}
                                  />
                              </tr>
                              </thead>
                              <tbody ref={tableBodyRef}>
                              {
                                  tableLoading ? (
                                      <tr>
                                          <td colSpan={8}>
                                              <div style={{width: `100%`, ...loadingHeight}}>
                                                  <div className={styles.loadingWrapper}>
                                                      <Loading className={styles.loadingCustomStyle}/>
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                  ) : (
                                      <>
                                          {
                                              isZeroRecords ? (
                                                  <tr>
                                                      <td colSpan={8}>
                                                          <p className={sharedStyles.noTableRecords}>
                                                              No records found.
                                                          </p>
                                                      </td>
                                                  </tr>
                                              ) : (
                                                  <>
                                                      {
                                                          users.map((user: User) => {
                                                              const {id, email, name, role, status, createdDateTime} = user;
                                                              const date = new Date(createdDateTime);
                                                              const formattedDate = createdDateTime
                                                                  ? moment(date).format('MMM D, YYYY')
                                                                  : '-';
                                                              const isSuspended = status === 'SUSPENDED';
                                                              const isPending = status === 'PENDING';
                                                              const userActionsConfig = [
                                                                  // {
                                                                  //     value: actionKeys.delete,
                                                                  //     label: 'Delete',
                                                                  //     iconClassName: 'bi bi-trash',
                                                                  // },
                                                                  ...([isSuspended ? {
                                                                      value: actionKeys.activate,
                                                                      label: 'Activate',
                                                                      iconClassName: 'bi bi-power',
                                                                  } : {
                                                                      value: actionKeys.suspend,
                                                                      label: 'Suspend',
                                                                      iconClassName: 'bi bi-x-circle',
                                                                  }]),
                                                                  ...(isPending ? [
                                                                      {
                                                                          value: actionKeys.remind,
                                                                          label: 'Resend invite',
                                                                          iconClassName: 'bi bi-file-earmark',
                                                                          disabled: isSuspended,
                                                                      }
                                                                  ] : []),
                                                              ];
                                                              const isSelected = Boolean(bulkSelectedIds.find((selectedId) => selectedId ===id));
                                                              return (
                                                                  <tr className={classNames(sharedStyles.tableRow, {
                                                                      [styles.rowLoading]: loadingRowId === id,
                                                                  })} key={id}>
                                                                      <TdElement
                                                                          className={styles.paddingLeft}
                                                                          colSpan={2}
                                                                      >
                                                                          {
                                                                              bulkSelectMode && (
                                                                                  <Checkbox
                                                                                      className={styles.checkbox}
                                                                                      onChange={() => onBulkSelect(id)}
                                                                                      checked={isSelected}
                                                                                  />
                                                                              )
                                                                          }
                                                                          <div style={{marginRight: '12px'}}>
                                                                              <AvatarFrame fullName={name}/>
                                                                          </div>
                                                                          <p className={sharedStyles.dataLabel}>
                                                                              {name}
                                                                          </p>
                                                                      </TdElement>
                                                                      <TdElement value={email} colSpan={2}/>
                                                                      <TdElement colSpan={2}>
                                                                          <EditableRoleControl
                                                                              value={role}
                                                                              openedRowId={editEnabledUserId}
                                                                              editUserRole={editUserRole}
                                                                              onChangeRole={onChangeRole}
                                                                              userInfo={user}
                                                                          />
                                                                      </TdElement>
                                                                      <TdElement>
                                                                          <div
                                                                              className={sharedStyles.statusPill}
                                                                              data-attr-status={status.toLowerCase()}
                                                                          >
                                                                              <p>{(status || '').toLowerCase()}</p>
                                                                          </div>
                                                                      </TdElement>
                                                                      <TdElement value={formattedDate}/>
                                                                      <TdElement>
                                                                          <DropdownWrapper
                                                                              placement="bottom-end"
                                                                              options={userActionsConfig}
                                                                              onSelect={(val) => {
                                                                                  setActionUser(user);
                                                                                  setActionType(val as TActionType);
                                                                              }}
                                                                              className={styles.customDropdown}
                                                                          >
                                                                              <button
                                                                                  type="button"
                                                                                  className={styles.menuBtn}
                                                                              >
                                                                                  <i className="bi bi-three-dots"></i>
                                                                              </button>
                                                                          </DropdownWrapper>
                                                                      </TdElement>
                                                                  </tr>
                                                              )
                                                          })
                                                      }
                                                  </>
                                              )
                                          }
                                      </>
                                  )
                              }
                              </tbody>
                          </table>
                      </div>
                      {
                          !isZeroRecords && (
                              <div className={sharedStyles.paginationControl}>
                                  <div className={sharedStyles.perPageRow} style={{...(tableLoading ? {opacity: 0} : {})}}>
                                      {`Showing ${(page) * size + 1} to ${((page) * size) + users.length} of ${totalElements} entries`}
                                  </div>
                                  <div className={sharedStyles.changeControl}>
                                      <ReactPaginate
                                          className={sharedStyles.paginationWrapper}
                                          breakLabel="..."
                                          nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                          onPageChange={onPageChange}
                                          pageRangeDisplayed={size}
                                          pageCount={pageCount}
                                          previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                          renderOnZeroPageCount={null}
                                          pageLabelBuilder={(page) => (
                                              <div className={sharedStyles.pItem}>{page}</div>
                                          )}
                                          activeClassName={sharedStyles.pItemActive}
                                          forcePage={page}
                                      />
                                      <div className={sharedStyles.perPageHolder}>
                                          <Dropdown
                                              options={perPageOptions}
                                              value={size}
                                              onChange={(e) => {
                                                  changePerPage(e.value);
                                                  scrollTop();
                                              }}
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      }
                  </div>
              </div>
              <ConfirmDialogCustom
                  header={header}
                  acceptLabel={confirmLabel}
                  rejectLabel="Cancel"
                  message={message}
                  visible={Boolean(actionType)}
                  onHide={() => {
                      setActionType('');
                  }}
                  accept={onAcceptAction}
              />
              <ConfirmDialogCustom
                  header="Resend bulk invitation"
                  acceptLabel="Proceed"
                  rejectLabel="Cancel"
                  message="You’re about to resend the invitation to the selected user(s) to sign up. Do you wish to proceed?"
                  visible={bulkInviteConfirmation}
                  onHide={() => setBulkInviteConfirmation(false)}
                  accept={onBulkInvite}
              />
              <ConfirmDialogCustom
                  header="Resend invite"
                  acceptLabel="Proceed"
                  rejectLabel="Cancel"
                  message="You’re about to resend the invitation to the selected user(s) to sign up. Do you wish to proceed?"
                  visible={bulkInviteConfirmation}
                  onHide={() => setBulkInviteConfirmation(false)}
                  accept={onBulkInvite}
              />
              <ConfirmDialogCustom
                  header="Confirm User Suspension"
                  acceptLabel="Proceed"
                  rejectLabel="Cancel"
                  message={`Are you sure you want to suspend the selected user(s)? Suspending a user will temporarily deactivate their account and restrict their access to the system. If you proceed, the user will not be able to log in or perform any actions until their account is reinstated. Their information will be saved.`}
                  visible={bulkSuspendConfirmation}
                  onHide={() => setBulkSuspendConfirmation(false)}
                  accept={onBulkSuspend}
              />
          </div>
      );
};

export default ManageUser;
