import React, { FC } from 'react';
import { classNames } from 'primereact/utils';

import styles from './loading.module.scss';

interface TLoadingProps {
  horizontalSpacing?: number;
  styleType?: 'small';
  className?: string;
}

const Loading: FC<TLoadingProps> = ({ horizontalSpacing, styleType = '', className }) => {
  const spacingStyle = { style: { margin: `${horizontalSpacing}px auto` } };
  return (
    <div
      className={classNames(styles.loading, className, {
        [styles.small]: styleType === 'small',
      })}
      {...(horizontalSpacing ? spacingStyle : {})}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        width={40}
        height={40}
        viewBox="0 0 50 50"
      >
        <path d="M43.935 25.145c0-10.318-8.364-18.683-18.683-18.683-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615s14.615 6.543 14.615 14.615h4.068z">
          <animateTransform
            attributeName="transform"
            attributeType="xml"
            dur="0.6s"
            from="0 25 25"
            repeatCount="indefinite"
            to="360 25 25"
            type="rotate"
          />
        </path>
      </svg>
    </div>
  );
};

export default Loading;
