export type TShareholderInformationFormValues = {
    name: string,
    email: string,
    address: {
        line1: string,
        city: string,
        state: string,
        zipCode: string,
        country: string,
    },
    ownershipPercentage: number | undefined,
    numberOfShares: number | undefined,
    ein: string,
    dateAcquired: Date | undefined,
    taxYearEndMonthAndDay: string,
    ownershipType: string
}

export type TShareholderInformationParams = {
    id?: number;
    name: string,
    email: string,
    address: {
        line1: string,
        city: string,
        state: string,
        zipCode: string,
        country: string,
    },
    ownershipPercentage: number | undefined,
    numberOfShares: number | undefined,
    ein: string,
    dateAcquired: string;
    taxYearEndMonthAndDay: string;
}

export type TAdditionalInformationParams = {
    ownershipMetric: TOwnershipMetric,
}

export enum OwnershipTypes {
    INDIVIDUAL= 'INDIVIDUAL',
    ENTITY = 'ENTITY',
}

export type TOwnershipType = OwnershipTypes.INDIVIDUAL | OwnershipTypes.ENTITY;

export enum OwnershipMetric {
    NUMBER_OF_SHARES= 'NUMBER_OF_SHARES',
    PERCENTAGE = 'PERCENTAGE',
}

export type TOwnershipMetric = OwnershipMetric.NUMBER_OF_SHARES | OwnershipMetric.PERCENTAGE;