import React, { useState } from 'react';
import styles from './acknowledgement.module.scss';
import {useMutation, useQuery} from "@tanstack/react-query";
import usePagination from "../../hooks/usePagination";
import generateUrlParams from "../../helpers/generateUrlParams";
import {AcknowledgementService} from "./Service";
import sharedStyles from "../../assets/sharedStyles.module.scss";
import ReactPaginate from "react-paginate";
import {Dropdown} from "primereact/dropdown";
import {perPageOptions, successToastLife} from "../../config/config";
import Loading from "../../components/Loading/Loading";
import {classNames} from "primereact/utils";
import ThElement from "../../uiComponents/TableTypeA/ThElement";
import TdElement from "../../uiComponents/TableTypeA/TdElement";
import {Nullable} from "primereact/ts-helpers";
import moment from "moment/moment";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";
import {DocumentStatus} from "../../modules/Document/types";
import CompareModal from "./CompareModal";
import {disableBodyScroll, enableBodyScroll} from "../../helpers/bodyScrollControllers";

enum Statuses {
    'NONE' = 'NONE',
    'UPLOADED' = 'UPLOADED',
    'PROCESSING' = 'PROCESSING',
    'COMPLETED' = 'COMPLETED',
    'NO_UUID_FOUND' = 'NO_UUID_FOUND',
    'UUID_ASSIGNED' = 'UUID_ASSIGNED',
    'NO_FILE83B_FOUND' = 'NO_FILE83B_FOUND',
    'FAILED' = 'FAILED',
    'ARCHIVE' = 'ARCHIVE',
    'REJECTED' = 'REJECTED',
}
type TStatus =
    Statuses.NONE |
    Statuses.UPLOADED |
    Statuses.PROCESSING |
    Statuses.COMPLETED |
    Statuses.NO_UUID_FOUND |
    Statuses.UUID_ASSIGNED |
    Statuses.NO_FILE83B_FOUND |
    Statuses.FAILED |
    Statuses.ARCHIVE |
    Statuses.REJECTED;
type TProcessedFile = {
    createdDateTime: string;
    fileName: string;
    id: number;
    message: string;
    orderNumber: Nullable<string>;
    signWellId: Nullable<string>;
    status: TStatus;
    updateDateTime: string;
    uuid: string;
}

export type TModalInfo = {
    generatedFileUrl: string;
    processedFileUrl: string;
    orderNumber: string;
};

const modalInfoInitialValue = {
    generatedFileUrl: '',
    processedFileUrl: '',
    orderNumber: '',
};

const ProcessedAcknowledgements = () => {
    const { params, changePage, changePerPage } = usePagination();
    const { page, size} = params;
    const { show } = useToast();
    const [modalInfo, setModalInfo] = useState<TModalInfo>(modalInfoInitialValue);
    const { generatedFileUrl, processedFileUrl } = modalInfo;

    const urlParams = generateUrlParams({
        page,
        size,
    });

    const { data: filesData, isLoading, refetch} = useQuery({
        queryKey: ['acknowledged-files', page, size],
        queryFn: () => AcknowledgementService.getProcessingFiles(urlParams),
        gcTime: 0,
    });
    const files = filesData?.data?.content || [];
    const totalElements = filesData?.data?.totalElements || 0;
    const pageCount = Math.ceil(totalElements / size);

    const getGeneratedFileUrl = useMutation({
        mutationFn: AcknowledgementService.getGeneratedFileUrl,
        onSuccess: (response: AxiosResponse) => {
            const previewUrl = response.data.url;
            setModalInfo((prev) => {
                return ({
                    ...prev,
                    generatedFileUrl: previewUrl,
                })
            });
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            setModalInfo(modalInfoInitialValue);
            enableBodyScroll();
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getGeneratedFileUrl.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const getGeneratedFileUrlLoading = getGeneratedFileUrl.isPending;

    const getProcessedFileUrl = useMutation({
        mutationFn: AcknowledgementService.getProcessingFileUrl,
        onSuccess: (response: AxiosResponse) => {
            const previewUrl = response.data.url;
            setModalInfo((prev) => {
                return ({
                    ...prev,
                    processedFileUrl: previewUrl,
                });
            });
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            setModalInfo(modalInfoInitialValue);
            enableBodyScroll();
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getProcessedFileUrl.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const getProcessedFileUrlLoading = getProcessedFileUrl.isPending;

    const confirmProcessedFile = useMutation({
        mutationFn: AcknowledgementService.confirmProcessedFile,
        onSuccess: () => {
            refetch();
            setModalInfo(modalInfoInitialValue);
            enableBodyScroll();
            show({
                severity: 'success',
                detail: 'Confirmed successfully!',
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            setModalInfo(modalInfoInitialValue);
            enableBodyScroll();
            const errorData = {
                error: error,
                variables: variables,
                retryFn: confirmProcessedFile.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const confirmProcessedFileLoading = confirmProcessedFile?.isPending;

    const onConfirmProcessedFile = (orderNumber: string) => {
        confirmProcessedFile.mutate({ orderNumber });
    }



    const comparePreview = (orderNumber: string) => {
        disableBodyScroll();
        setModalInfo((prev) => {
            return ({
                ...prev,
                orderNumber,
            });
        })
        getGeneratedFileUrl.mutate({
            orderNumber: orderNumber,
            docStatus: DocumentStatus.FULLY_SIGNED,
        });
        getProcessedFileUrl.mutate(orderNumber);
    }

    const closeModal = () => {
        enableBodyScroll();
        setModalInfo(modalInfoInitialValue);
    }

    return (
        <div className={styles.processedAcknowledgements}>
            {
                Boolean(modalInfo.orderNumber) && (
                    <CompareModal
                        generatedFileUrl={generatedFileUrl}
                        processedFileUrl={processedFileUrl}
                        loading={getGeneratedFileUrlLoading || getProcessedFileUrlLoading}
                        closeModal={closeModal}
                        orderNumber={modalInfo.orderNumber}
                        onConfirmProcessedFile={onConfirmProcessedFile}
                        confirmProcessedFileLoading={confirmProcessedFileLoading}
                    />
                )
            }
            <div className={styles.tableHolder}>
                {
                    isLoading ? (
                        <div className={styles.loadingWrapper}>
                            <Loading />
                        </div>
                    ) : (
                        <div className={styles.tableWrapper}>
                            <table className={classNames(sharedStyles.tableTypeA, styles.tableCustom)}>
                                <thead>
                                <tr className={sharedStyles.tableRow}>
                                    <ThElement label="Name" />
                                    <ThElement label="Created Date" />
                                    <ThElement label="Message" />
                                    <ThElement label="Status" />
                                    <ThElement />
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    files.length === 0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <p className={sharedStyles.noTableRecords}>
                                                    No records found.
                                                </p>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {
                                                files.map((file: TProcessedFile, index: number) => {
                                                    const {
                                                        fileName,
                                                        createdDateTime,
                                                        message,
                                                        status,
                                                        orderNumber,
                                                    } = file;
                                                    const date = new Date(createdDateTime);
                                                    const formattedDate = createdDateTime
                                                        ? moment(date).format('MMM D, YYYY')
                                                        : '-';
                                                    return (
                                                        <tr className={classNames(sharedStyles.tableRow)} key={index}>
                                                            <TdElement value={fileName} />
                                                            <TdElement value={formattedDate} />
                                                            <TdElement value={message} />
                                                            <TdElement value={status} />
                                                            <TdElement>
                                                                <ButtonPrimary
                                                                    onClick={() => comparePreview(orderNumber as string)}
                                                                    label="Preview"
                                                                    disabled={status !== Statuses.COMPLETED}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className={styles.doneBtn}
                                                                    disabled={status !== Statuses.COMPLETED || confirmProcessedFileLoading}
                                                                    onClick={() => onConfirmProcessedFile(orderNumber as string)}
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                </button>
                                                            </TdElement>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
            {
                files.length > 0 && (
                    <div className={sharedStyles.paginationControl}>
                        <div className={sharedStyles.perPageRow}>
                            {`Showing ${(page) * size + 1} to ${((page) * size) + files.length} of ${totalElements} entries`}
                        </div>
                        <div className={sharedStyles.changeControl}>
                            <ReactPaginate
                                className={sharedStyles.paginationWrapper}
                                breakLabel="..."
                                nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                onPageChange={({selected}) => changePage(selected)}
                                pageRangeDisplayed={size}
                                pageCount={pageCount}
                                previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                renderOnZeroPageCount={null}
                                pageLabelBuilder={(page) => (
                                    <div className={sharedStyles.pItem}>{page}</div>
                                )}
                                activeClassName={sharedStyles.pItemActive}
                                forcePage={page}
                            />
                            <div className={sharedStyles.perPageHolder}>
                                <Dropdown
                                    options={perPageOptions}
                                    value={size}
                                    onChange={(e) => changePerPage(e.value)}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ProcessedAcknowledgements;
