import React, {FC, useMemo, useRef, useState} from 'react';
import styles from './bulkUploadUsers.module.scss';
import {DragDropFile} from "../../../components/Drag&DropFile/Drag&DropFile";
import {requests} from "../../../services/http-common";
import UploadingLoading from "./UploadingLoading";
import TableHead, {THeaderConfigItem} from "./Table/TableHead";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Nullable} from "primereact/ts-helpers";
import TableBody from "./Table/TableBody";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";
import {useToast} from "../../../hooks/useToast";
import usePagination from "../../../hooks/usePagination";
import DraftUsers from "../DraftUsers/DraftUsers";
import Loading from "../../../components/Loading/Loading";
import AddRowManually from "./AddRowManually/AddRowManually";

interface TBulkUploadUsersProps {
    companyId: number;
    skipGrantHeaderRecognizing: boolean;
}

type TConfig = {
    data: Record<string, string>[];
    headers: THeaderConfigItem[];
    uniqueKey: string;
};

type TOnRecognizeParams = {
    headers: Record<string, string>;
    uniqueKey: string;
}

export type THeaderStateItem = { key: string, label: string };
export type THeaderState = Record<string, THeaderStateItem>;

const BulkUploadUsers: FC<TBulkUploadUsersProps> = ({ companyId, skipGrantHeaderRecognizing }) => {
    const { params, changePage, changePerPage } = usePagination();
    const { page, size, sort } = params;

    const [config, updateConfig] = useState<Nullable<TConfig>>(null);
    const [uploading, setUploading] = useState(false);
    const [headerState, updateHeaderState] = useState<Nullable<THeaderState>>(null);
    const [order, setOrder] = useState<THeaderConfigItem[]>([]);
    const pickedFileRef = useRef<Nullable<Blob>>(null);

    const { show } = useToast();
    const data = config?.data || [];
    const headers = config?.headers || [];
    const uniqueKey = config?.uniqueKey || '';

    const setUploadingInitialStates = () => {
        updateConfig(null);
        updateHeaderState(null);
        setOrder([]);
    }

    const searchUrl = useMemo(() => {
        const pageStr = `page=${page}&size=${size}`;
        const sortFormatted =
            sort.length > 0 ? sort.map((str) => `sort=${str}`) : [];
        const sortStr = sort.length > 0 ? `&${sortFormatted.join("&")}` : "";
        const concatUrl = `${pageStr}${sortStr}`;
        return `?${concatUrl}`;
    }, [page, size, sort]);

    const {
        data: draftUsers,
        isLoading: draftUsersLoading,
        refetch: refetchDraftUsers,
    } = useQuery({
        queryKey: ["draft-file83b-users", page, size, sort.join(""), companyId],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/file83b-draft${searchUrl}`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
        retry: true,
    });
    const totalElements = draftUsers?.totalElements || 0;
    const draftUsersList = draftUsers?.content || [];
    const pageCount = Math.ceil(totalElements / size);

    const {
        data: draftUsersConfiguration,
    } = useQuery({
        queryKey: ["draft-users-configuration", page, size, sort.join(""), companyId],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/file83b-draft/configuration`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
        retry: true,
    });
    const columnHeaders = draftUsersConfiguration?.columnHeaders || [];

    const { data: csvHeaderOptions } = useQuery({
        queryKey: ["csv-head-option"],
        queryFn: async () => {
            return requests.get(`/api/companies/${companyId}/csv/configs`);
        },
        gcTime: 0,
    });

    const convertConfigToValues = (data: THeaderConfigItem[]) => {
        const columnOrder: THeaderConfigItem[] = [];
        const initialHeaderStateValue: THeaderState = {};
        data.forEach((item: THeaderConfigItem) => {
            const { name, label, key } = item;
            initialHeaderStateValue[name] = { key, label };
            columnOrder.push(item);
        });
        return { initialHeaderStateValue, columnOrder };
    }

    const uploadAction = async (file: Blob) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const uploadType = skipGrantHeaderRecognizing ? 'upload-directly' : 'upload';
            const url = `/api/companies/${companyId}/csv/${uploadType}`;
            const response = await requests.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (skipGrantHeaderRecognizing) {
                refetchDraftUsers().then(() => {
                    setUploading(false);
                    pickedFileRef.current = null;
                });
            } else {
                updateConfig(response);
                const { initialHeaderStateValue, columnOrder } = convertConfigToValues(response?.headers || []);
                updateHeaderState(initialHeaderStateValue);
                setOrder(columnOrder);
                setUploading(false);
                pickedFileRef.current = null;
            }
        } catch (error: any) {
            const errorData = {
                error: error,
                variables: pickedFileRef.current,
                retryFn: uploadAction,
                show: show,
                cb: () => {
                    setUploading(false);
                    pickedFileRef.current = null;
                }
            };
            extractErrorMessage(errorData);
        }
    };

    const onRecognizeHeaders = useMutation({
        mutationFn: async (params: TOnRecognizeParams) => {
            return await requests.post(
                `api/companies/${companyId}/csv/recognize`, params
            );
        },
        onSuccess: () => {
            refetchDraftUsers();
            setUploadingInitialStates();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: onRecognizeHeaders.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSelect = (files: Blob[]) => {
        if (files && files[0]) {
            setUploading(true);
            const file = files[0];
            pickedFileRef.current = file;
            void uploadAction(file);
        }
    };

    const onChangeColumnName = (key: string, value: THeaderStateItem) => {
        updateHeaderState((prev) => ({...prev, [key]: value}));
    }

    const onSubmitColumnOrder = () => {
        const obj: Record<string, string> = {};
        (Object.keys(headerState || {})).forEach((columnName) => {
            if (headerState) {
                obj[columnName] = headerState[columnName].key;
            }

        });
        const params = {
            headers: obj,
            uniqueKey,
        };
        onRecognizeHeaders.mutate(params);
    }

    const onClearTempData = () => {
        pickedFileRef.current = null;
        updateConfig(null);
        updateHeaderState(null);
        setOrder([]);
        setUploading(false);
    }

    const exportCSV = useMutation({
        mutationFn: async () => {
            return await requests.get(
                `api/companies/${companyId}/csv/csv-example`,
            );
        },
        onSuccess: (csvStr) => {
            const fileName = localStorage.getItem('tempFileName') || 'data.csv';
            const blob = new Blob([csvStr], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            localStorage.removeItem('tempFileName');
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: exportCSV.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onDownload = () => {
        exportCSV.mutate();
    }

    if (draftUsersLoading) {
        return (
            <div className={styles.mainLoadingWrapper}>
                <Loading horizontalSpacing={250} />
            </div>
        )
    }

    if (draftUsersList.length > 0) {
        return (
            <DraftUsers
                draftUsersList={draftUsersList}
                page={page}
                size={size}
                currentLength={draftUsersList.length}
                totalElements={totalElements}
                changePage={changePage}
                pageCount={pageCount}
                changePerPage={changePerPage}
                columnHeaders={columnHeaders}
                companyId={companyId}
                refetchDraftUsers={refetchDraftUsers}
            />
        );
    }

    if (uploading) {
        return (
            <UploadingLoading />
        );
    }
    return (
        <div className={styles.bulkUploadUsers}>
            {
                !config ? (
                    <div className={styles.content}>
                        <div className={styles.dragDropWrapper}>
                            <h3>Add Grants</h3>
                            <DragDropFile
                                label="Drag and drop the CSV file you’d like to upload."
                                onChange={onSelect}
                                multiple={false}
                                allowedExtensions=".csv"
                            />
                            <span>
                                Please use the linked CSV template to draft the 83(b) elections.
                                <button type="button" className={styles.downloadTemplate} onClick={onDownload}>
                                  Download
                                </button>
                            </span>
                        </div>
                        <div className={styles.addRowManually}>
                            <AddRowManually
                                columnHeaders={columnHeaders}
                                companyId={companyId}
                                refetchDraftUsers={refetchDraftUsers}
                                className={styles.addBtn}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles.horizontalScroller}>
                        <table className={styles.table}>
                            <TableHead
                                options={headers as THeaderConfigItem[]}
                                csvHeaderOptions={csvHeaderOptions}
                                headerState={headerState}
                                onChangeColumnName={onChangeColumnName}
                            />
                            <TableBody
                                data={data}
                                order={order}
                            />
                        </table>
                    </div>
                )
            }
            {
                config && (
                    <div className={styles.footer}>
                        <ButtonPrimary
                            label="Cancel"
                            styleType="secondaryOutline"
                            onClick={onClearTempData}
                        />
                        <ButtonPrimary
                            label="Next"
                            onClick={onSubmitColumnOrder}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default BulkUploadUsers;
