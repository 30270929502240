import React, { FC } from 'react';
import styles from "../authentication.module.scss";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {Controller, useForm} from "react-hook-form";
import FieldWrapper from "../../../uiComponents/FieldWrapper/FieldWrapper";
import {InputText} from "primereact/inputtext";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

export type TFieldName = 'username';

export type TFieldConfig = {
    fieldName: TFieldName;
    label: string;
    placeholder: string;
};

export type TRequestRecoverPasswordValues = {
    username: string;
}

const fieldsConfig: TFieldConfig[] = [
    {
        fieldName: 'username',
        label: 'Username',
        placeholder: '',
    },
];

const initialFormValues = {
    username: '',
};

interface TConfirmSignUpFormProps {
    onSubmit: (values: TRequestRecoverPasswordValues) => void;
    isLoading: boolean;
}

const ConfirmSignUpForm: FC<TConfirmSignUpFormProps> = ({ onSubmit, isLoading }) => {
    const formSchema = Yup.object().shape({
        username: Yup.string().email('Invalid email').required('Email is required')
    });
    const form = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: initialFormValues,
    });
    const { control, handleSubmit } = form;
    const formErrors = form.formState.errors;

    return (
        <form className={styles.authForm} onSubmit={handleSubmit(onSubmit)}>
            <p className={styles.headTxt}>Verify your email address</p>
            <div className={styles.fields}>
                {fieldsConfig.map((fieldConfig: TFieldConfig) => {
                    const {fieldName, placeholder, label} = fieldConfig;
                    const fieldError = formErrors[fieldName]?.message;
                    return (
                        <div className={styles.fieldItem} key={fieldName}>
                            <Controller
                                name={fieldName as TFieldName}
                                control={control}
                                render={({field}) => {
                                    return (
                                        <FieldWrapper
                                            className={styles.fieldWide}
                                            errorMessage={fieldError}
                                            label={label}
                                            required
                                        >
                                            <InputText
                                                className={styles.fieldWide}
                                                value={field.value as string}
                                                autoComplete="new-password"
                                                name={fieldName}
                                                placeholder={placeholder}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                }}
                                            />
                                        </FieldWrapper>
                                    );
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <ButtonPrimary
                label="Get verification code"
                className={styles.actionBtn}
                type="submit"
                disabled={isLoading}
            />
        </form>
    );
}

export default ConfirmSignUpForm;
