import axios, { type AxiosResponse } from "axios";
import { HttpService } from "./HttpService";

const baseUrl = import.meta.env.VITE_CORPORA_SERVICE_URL;

const responseBody = (response: AxiosResponse) => {
  const contentDisposition = response?.headers?.['content-disposition'];
  const match = contentDisposition?.match(/filename="(.+?)"/);
  if (match && match[1]) {
    const filename = match[1];
    localStorage.setItem('tempFileName', filename);
  }
  if (response?.data === "unauthorized") {
    throw new Error("retry");
  }
  return response?.data;
};

export const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_CORPORA_SERVICE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  HttpService.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const fulFilledCb = (response: any) => {
  return response;
};

const signOutClearStorages = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.pathname = "/";
};

const onRejectedCb = async (error: any) => {
  if (error.response.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      axios
        .post(`${baseUrl}api/auth/refresh-token`, { token: refreshToken })
        .then((response) => {
          const data = response?.data as {
            token: string;
            refreshToken: string;
          };
          const { token } = data;
          localStorage.setItem("accessToken", token);
          setAuthorizationHeader(token);
        })
        .catch(() => {
          signOutClearStorages();
        });
    } else {
      signOutClearStorages();
    }
    return Promise.resolve({ data: 'unauthorized' });
  } else if (error.response.status === 403) {
    // It must be empty as we trigger retry action
  } else {
    return Promise.reject(error);
  }
};

axiosInstance.interceptors.response.use(fulFilledCb, onRejectedCb);

export const requests = {
  get: async (url: string) => axiosInstance.get(url).then(responseBody),
  post: async (
    url: string,
    body: Record<string, any>,
    config?: Record<string, any>
  ) => axiosInstance.post(url, body, config).then(responseBody),
  put: async (url: string, body: Record<string, any>) =>
    axiosInstance.put(url, body).then(responseBody),
  patch: async (url: string, body: Record<string, any>) =>
    axiosInstance.patch(url, body).then(responseBody),

  delete: async (url: string) => axiosInstance.delete(url).then(responseBody),
};
