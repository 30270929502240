import React, { FC } from 'react';
import styles from './statusPill.module.scss';
import {documentStatusesLabels} from "../../config/config";
import {TDocumentStatuses} from "../../@types/global";

import pending from '../../assets/icons/statuses/pending.svg';
import pending_a from '../../assets/icons/statuses/pending_a.svg';
import partially_signed from '../../assets/icons/statuses/partially_signed.svg';
import partially_signed_a from '../../assets/icons/statuses/partially_signed_a.svg';
import fully_signed from '../../assets/icons/statuses/fully_signed.svg';
import fully_signed_a from '../../assets/icons/statuses/fully_signed_a.svg';
import mailed from '../../assets/icons/statuses/mailed.svg';
import mailed_a from '../../assets/icons/statuses/mailed_a.svg';
import delivered from '../../assets/icons/statuses/delivered.svg';
import delivered_a from '../../assets/icons/statuses/delivered_a.svg';
import acknowledged from '../../assets/icons/statuses/acknowledged.svg';
import acknowledged_a from '../../assets/icons/statuses/acknowledged_a.svg';
import unable_to_process from '../../assets/icons/statuses/unable_to_process.svg';
import unable_to_process_a from '../../assets/icons/statuses/unable_to_process_a.svg';
import none from '../../assets/icons/statuses/none.svg';
import none_a from '../../assets/icons/statuses/none_a.svg';
import {classNames} from "primereact/utils";

type TConfigItem = {
    primaryIcon: string;
    secondaryIcon: string;
};

const config: Record<TDocumentStatuses, TConfigItem> = {
    PARTIALLY_SIGNED: {
        primaryIcon: partially_signed,
        secondaryIcon: partially_signed_a,
    },
    FULLY_SIGNED: {
        primaryIcon: fully_signed,
        secondaryIcon: fully_signed_a,
    },
    MAILED: {
        primaryIcon: mailed,
        secondaryIcon: mailed_a,
    },
    DELIVERED: {
        primaryIcon: delivered,
        secondaryIcon: delivered_a,
    },
    ACKNOWLEDGED: {
        primaryIcon: acknowledged,
        secondaryIcon: acknowledged_a,
    },
    EXPIRED: {
        primaryIcon: unable_to_process,
        secondaryIcon: unable_to_process_a,
    },
    PENDING: {
        primaryIcon: pending,
        secondaryIcon: pending_a,
    },
    UNABLE_TO_PROCESS: {
        primaryIcon: unable_to_process,
        secondaryIcon: unable_to_process_a,
    },
    NONE: {
        primaryIcon: none,
        secondaryIcon: none_a,
    },
};

interface TStatusPillProps {
    status: TDocumentStatuses;
    styleType?: 'secondary' | 'primary';
}

const StatusPill: FC<TStatusPillProps> = ({ status, styleType = 'secondary' }) => {
    const isPrimary = styleType === 'primary';
    const primaryIconSrc = config[status]?.primaryIcon;
    const secondaryIconSrc = config[status]?.secondaryIcon;
    const icon = isPrimary ? primaryIconSrc : secondaryIconSrc;
    return (
        <div
            className={classNames(styles.statusPill, {
                [styles.primary]: styleType === 'primary',
                [styles.secondary]: styleType === 'secondary',
            })}
            data-attr-status={status.toLowerCase()}
        >
            <img src={icon} alt="" />
            <p>{documentStatusesLabels[status]}</p>
        </div>
    )
}

export default StatusPill;
