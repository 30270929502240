import { FunctionComponent } from 'react';

interface Props {
  condition: boolean;
  children: any;
}

export const If: FunctionComponent<Props> = ({ condition, children }) => {
  return (
    condition
      ? (children as JSX.Element[])[0] || children
      : (children as JSX.Element[])[1] || null
  ) as JSX.Element;
};
