import { classNames } from "primereact/utils";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import DocumentIconActive from "../../assets/icons/sidebar/document-icon-active.svg";
import DocumentIcon from "../../assets/icons/sidebar/document-icon.svg";
import PersonsIconActive from "../../assets/icons/sidebar/person-icon-active.svg";
import PersonsIcon from "../../assets/icons/sidebar/person-icon.svg";
import SubscriptionsIconActive from "../../assets/icons/sidebar/subscription-icon-active.svg";
import SubscriptionsIcon from "../../assets/icons/sidebar/subscription-icon.svg";
import TemplatesIconActive from "../../assets/icons/sidebar/template-icon-active.svg";
import TemplatesIcon from "../../assets/icons/sidebar/template-icon.svg";
import { If } from "../If/If";

import styles from "./sidebar.module.scss";

interface INavigationSectionProps {
  isAdmin: boolean;
  isOpened: boolean;
  isAnimate: boolean;
  subscriptionMenuVisible: boolean;
}

const NavigationSection: FC<INavigationSectionProps> = ({
  isAdmin,
  isOpened,
  isAnimate,
  subscriptionMenuVisible,
}) => {
  const adminNavConfig = [
    {
      to: `/admin-dashboard`,
      label: "Dashboard",
      icon: DocumentIcon,
      iconActive: DocumentIconActive,
    },
    {
      to: `/manage-user`,
      label: "Manage Users",
      icon: PersonsIcon,
      iconActive: PersonsIconActive,
    },
    {
      to: `/grants`,
      label: "Grants",
      icon: DocumentIcon,
      iconActive: DocumentIconActive,
    },
  ];

  if (subscriptionMenuVisible) {
    adminNavConfig.push({
      to: `/subscriptions`,
      label: "Subscription",
      icon: SubscriptionsIcon,
      iconActive: SubscriptionsIconActive,
    });
  }

  const navConfig = [
    ...(isAdmin ? adminNavConfig : []),
    {
      to: `/filing`,
      label: "File 83(b) election",
      icon: TemplatesIcon,
      iconActive: TemplatesIconActive,
    },
  ];
  return (
    <div className={styles.navigationWrapper}>
      <nav className={styles.navWrapper}>
        <div className={styles.navGroup}>
          {navConfig.map((config, index) => {
            const { to, icon, iconActive, label } = config;
            return (
              <NavLink
                key={`${index}${label}`}
                to={to}
                className={({ isActive }) =>
                  classNames(styles.navItem, {
                    [styles.navItemActive]: isActive,
                  })
                }
              >
                {({ isActive }) => {
                  return (
                    <>
                      <img src={isActive ? iconActive : icon} alt="" />
                      <If condition={isOpened}>
                        <p
                          className={classNames(styles.label, {
                            [styles.delayedSmoothAppear]: isAnimate,
                          })}
                        >
                          {label}
                        </p>
                      </If>
                    </>
                  );
                }}
              </NavLink>
            );
          })}
        </div>
      </nav>
    </div>
  );
};

export default NavigationSection;
