type Params = Record<string, string | number | (string | number)[]>;

const generateUrlParams = (params: Params): string => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => searchParams.append(key, item.toString()));
        } else {
            searchParams.set(key, value.toString());
        }
    });
    return searchParams.toString();
}

export default generateUrlParams;
