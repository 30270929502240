import React, { FC } from 'react';

import sharedStyles from '../../assets/sharedStyles.module.scss';
import { ReactPdfViewer } from '../../components/ReactPdfViewer/ReactPdfViewer';
import Loading from "../../components/Loading/Loading";

interface TFilePreviewOverlayProps {
  closePreview: () => void;
  preview?: string;
  isLoading?: boolean;
  hideDownloadButton?: boolean;
}

const FilePreviewOverlay: FC<TFilePreviewOverlayProps> = ({
  preview,
  closePreview,
  isLoading,
  hideDownloadButton,
}) => {
  if (isLoading && !preview) {
      return (
          <div className={sharedStyles.previewOverlay}>
              <Loading horizontalSpacing={10} />
          </div>
      )
  }
  if (!preview) return null;
  return (
    <div className={sharedStyles.previewOverlay}>
        {
            hideDownloadButton && (<style>{`.rpv-core__minimal-button[aria-label="Download"]{display: none;}`}</style>)
        }
        <div className={sharedStyles.previewModal}>
            <ReactPdfViewer fileUrl={preview} closePreview={closePreview} />
        </div>
    </div>
  );
};

export default FilePreviewOverlay;
