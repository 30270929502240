import React, { FC, useState } from 'react';
import styles from './draftUsers.module.scss';
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import TableBody from "./Table/TableBody";
import TableHead from "./Table/TableHead";
import sharedStyles from "../../../assets/sharedStyles.module.scss";
import ReactPaginate from "react-paginate";
import {Dropdown} from "primereact/dropdown";
import {perPageOptions} from "../../../config/config";
import {useMutation} from "@tanstack/react-query";
import {requests} from "../../../services/http-common";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";
import {useToast} from "../../../hooks/useToast";
import {classNames} from "primereact/utils";
import {TReactQueryReFetch} from "../../../@types/global";
import ConfirmDialogCustom from "../../../uiComponents/ConfirmDianlogCustom/ConfirmDialogCustom";
import AddRowManually from "../BulkUploadUsers/AddRowManually/AddRowManually";

const labelObj = {
    header: `Deleting all draft records`,
    message: 'You are about to delete all draft records.  Do you wish to continue?',
    successLabel: 'Success delete',
    confirmLabel: 'Delete',
}

export type TDraftUser = Record<string, string | number>
interface TDraftUsersProps {
    draftUsersList: TDraftUser[];
    page: number;
    size: number;
    currentLength: number;
    totalElements: number;
    changePage: (val: number) => void;
    pageCount: number;
    changePerPage: (val: number) => void;
    columnHeaders: { key: string; label: string }[];
    companyId: number;
    refetchDraftUsers: TReactQueryReFetch;
}
const DraftUsers: FC<TDraftUsersProps> = ({
draftUsersList,
page,
size,
currentLength,
totalElements,
changePage,
pageCount,
changePerPage,
columnHeaders,
companyId,
refetchDraftUsers,
}) => {
    const [isTableLoading, setTableLoading] = useState<boolean>(false);
    const [isClearDraftWarningModalOpened, setClearDraftModal] = useState(false);
    const [isPublishWarningModalOpened, setPublishModal] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
    const { show } = useToast();
    const onPublishDraftUsers = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/companies/${companyId}/file83b-draft/publish`, {}
            );
        },
        onSuccess: () => {
            refetchDraftUsers().then(() => {
                setTableLoading(false);
            });
        },
        onError: (error: AxiosError<{ detail: string }>) => {
            const errorData = {
                error: error,
                show: show,
                cb: () => {
                    setTableLoading(false);
                }
            };
            extractErrorMessage(errorData);
        },
    });
    const { isPending: publishingLoading } = onPublishDraftUsers;
    const onClearDraftUsers = useMutation({
        mutationFn: async () => {
            return await requests.delete(
                `api/companies/${companyId}/file83b-draft/all`
            );
        },
        onSuccess: () => {
            refetchDraftUsers().then(() => {
                setTableLoading(false);
            });
        },
        onError: (error: AxiosError<{ detail: string }>) => {
            const errorData = {
                error: error,
                show: show,
                cb: () => {
                    setTableLoading(false);
                }
            };
            extractErrorMessage(errorData);
        },
    });
    const deleteUserRequest = useMutation({
        mutationFn: async (rowId: number) => {
            return await requests.delete(
                `api/companies/${companyId}/file83b-draft?ids=${rowId}`,
            );
        },
        onSuccess: () => {
            if (page > 0 && draftUsersList.length === 1) {
                const onePageBack = page - 1;
                changePage(onePageBack);
            } else {
                refetchDraftUsers().then(() => {
                    setDeleteUserId(null);
                    setTableLoading(false);
                });
            }
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: deleteUserRequest.mutate,
                show: show,
                cb: () => {
                    setDeleteUserId(null);
                }
            };
            extractErrorMessage(errorData);
        },
    });
    const onDeleteSingleUser = (rowId: number) => {
        deleteUserRequest.mutate(rowId);
    }
    const onAcceptPublish = () => {
        setTableLoading(true);
        onPublishDraftUsers.mutate();
    }
    const onPublish = () => {
        setPublishModal(true);
    }
    const onClear = () => {
        setTableLoading(true);
        onClearDraftUsers.mutate();
    }
    const manuallyRowAdditionSuccessCb = () => {
        if (draftUsersList.length === size) {
            const nextPage = page + 1;
            changePage(nextPage);
        }
    }
    return (
        <div className={styles.draftUsers}>
            <div className={styles.headerControl}>
                <ButtonPrimary
                    label="Clear draft data"
                    styleType="primaryOutline"
                    onClick={() => setClearDraftModal(true)}
                    isDanger
                />
                <AddRowManually
                    columnHeaders={columnHeaders}
                    companyId={companyId}
                    refetchDraftUsers={refetchDraftUsers}
                    successCb={manuallyRowAdditionSuccessCb}
                />
            </div>
            <div className={styles.tableWrapper}>
                <div className={styles.horizontalScroller}>
                    <table className={classNames(styles.table, {
                        [styles.loading]: isTableLoading || publishingLoading,
                    })}>
                        <TableHead columnsConfig={columnHeaders} />
                        <TableBody
                            draftUsersList={draftUsersList}
                            columnsConfig={columnHeaders}
                            companyId={companyId}
                            refetchDraftUsers={refetchDraftUsers}
                            setTableLoading={setTableLoading}
                            deleteUserId={deleteUserId}
                            setDeleteUserId={setDeleteUserId}
                            onDeleteSingleUser={onDeleteSingleUser}
                        />
                    </table>
                </div>
                <div className={sharedStyles.paginationControl}>
                    <div className={sharedStyles.perPageRow}>
                        {`Showing ${page * size + 1} to ${
                            page * size + currentLength
                        } of ${totalElements} entries`}
                    </div>
                    <div className={sharedStyles.changeControl}>
                        <ReactPaginate
                            className={sharedStyles.paginationWrapper}
                            breakLabel="..."
                            nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                            onPageChange={({selected}) => changePage(selected)}
                            pageRangeDisplayed={size}
                            pageCount={pageCount}
                            previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                            renderOnZeroPageCount={null}
                            pageLabelBuilder={(page) => (
                                <div className={sharedStyles.pItem}>{page}</div>
                            )}
                            activeClassName={sharedStyles.pItemActive}
                            forcePage={page}
                        />
                        <div className={sharedStyles.perPageHolder}>
                            <Dropdown
                                options={perPageOptions}
                                value={size}
                                onChange={(e) => changePerPage(e.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <ButtonPrimary
                    label="Publish"
                    onClick={onPublish}
                    disabled={publishingLoading}
                />
            </div>
            <ConfirmDialogCustom
                header={labelObj.header}
                acceptLabel={labelObj.confirmLabel}
                rejectLabel="Cancel"
                message={labelObj.message}
                visible={isClearDraftWarningModalOpened}
                onHide={() => setClearDraftModal(false)}
                accept={onClear}
            />
            <ConfirmDialogCustom
                header="Publish draft elections"
                acceptLabel="Proceed"
                rejectLabel="Cancel"
                message="Draft 83(b) elections will be created based on your provided information, and users will get a notification to review and complete their documents.  Are you sure you want to proceed?"
                visible={isPublishWarningModalOpened}
                onHide={() => setPublishModal(false)}
                accept={onAcceptPublish}
            />
        </div>
    )
}

export default DraftUsers;
