import React, {FC, useState} from 'react';
import styles from "./shareholders.module.scss";
import sharedStyles from '../shared.module.scss';
import ShareholdersForm from "./ShareholdersForm";
import {useMutation} from "@tanstack/react-query";
import {Form2553Service} from "../../../Service";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../../hooks/useToast";
import {SubmitHandler} from "react-hook-form";
import {
    TAdditionalInformationParams,
    TOwnershipMetric,
    TShareholderInformationFormValues,
    TShareholderInformationParams
} from "./types";
import OwnershipMetricModal from "./OwnershipMetricModal";
import {TForm2553Information} from "../../CreationControl/types";
import {formatFormValues} from "../../../helpers";
import ShareholdersList from "./ShareholdersList";
import {stepsKeys, TStep} from "../../StepsControl/StepsContro";

const dateValueKeys = [
    'dateAcquired',
];

interface TShareholdersProps {
    companyId: number;
    formId: string;
    form2553Information: TForm2553Information;
    refetch2553Data: () => void;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    isLateElection: boolean;
}
const Shareholders: FC<TShareholdersProps> = ({
companyId,
formId,
form2553Information ,
refetch2553Data,
setStep,
isLateElection,
}) => {
    const ownershipMetricInitial = form2553Information?.additionalInfo?.ownershipMetric;
    const shareholders = form2553Information?.shareHolders || [];
    const noShareholderAdded = shareholders.length === 0;
    const [isShareholderCreate, setIsShareholderCreate] = useState(false);
    const [ownershipMetric, setOwnershipMetric] = useState<TOwnershipMetric | ''>(ownershipMetricInitial || '');
    const [resetFormKey, setResetFormKey] = useState<number>(0);
    const { show } = useToast();

    const validateShareholderInformation = useMutation({
        mutationFn: Form2553Service.validateShareholderInformation,
        onSuccess: (response: AxiosResponse) => {
            refetch2553Data();
            setStep(stepsKeys.SIGNER_INFORMATION);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: validateShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const validateAndNext = () => {
        validateShareholderInformation.mutate({ companyId, formId });
    }

    const postShareholderInformation = useMutation({
        mutationFn: Form2553Service.postShareholderInformation,
        onSuccess: (response: AxiosResponse) => {
            refetch2553Data();
            setResetFormKey((prev) => prev + 1);
            setIsShareholderCreate(false);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: validateShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit: SubmitHandler<TShareholderInformationFormValues> = (values) => {
        const params = formatFormValues(
            values as unknown as any[],
            dateValueKeys,
        );
        postShareholderInformation.mutate({
            params: params as TShareholderInformationParams,
            companyId: companyId,
            formId: formId,
        });
    }

    const patchAdditionalInformation = useMutation({
        mutationFn: Form2553Service.patchAdditionalInformation,
        onSuccess: (response: AxiosResponse, variables: {
            params: TAdditionalInformationParams,
            formId: string,
            companyId: number,
        }) => {
            refetch2553Data();
            setOwnershipMetric(variables.params.ownershipMetric);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: validateShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const deleteShareholder = useMutation({
        mutationFn: Form2553Service.deleteShareholder,
        onSuccess: () => {
            refetch2553Data();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: deleteShareholder.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const deleteLoading = deleteShareholder?.isPending;
    const onDeleteShareholder = (id: number) => {
        deleteShareholder.mutate({ formId, companyId, id });
    }

    const onPatchAdditionalInformation = (params: TAdditionalInformationParams) => {
        patchAdditionalInformation.mutate({ params, companyId, formId });
    }

    const onPrev = () => {
        if (isLateElection) {
            setStep(stepsKeys.LATE_ELECTION_JUSTIFICATION);
        } else {
            setStep(stepsKeys.BUSINESS_INFORMATION);
        }
    }
    return (
        <div className={styles.shareholders}>
            {
                ownershipMetric ? (
                    <>
                        <div className={sharedStyles.formColumn}>
                            {
                                isShareholderCreate ? (
                                    <ShareholdersForm
                                        onSubmit={onSubmit}
                                        resetFormKey={resetFormKey}
                                        setIsShareholderCreate={setIsShareholderCreate}
                                    />
                                ) : (
                                    <ShareholdersList
                                        shareholders={shareholders}
                                        onPrev={onPrev}
                                        noShareholderAdded={noShareholderAdded}
                                        validateAndNext={validateAndNext}
                                        setIsShareholderCreate={setIsShareholderCreate}
                                        onDeleteShareholder={onDeleteShareholder}
                                        deleteLoading={deleteLoading}
                                    />
                                )
                            }
                        </div>
                    </>
                ) : (
                    <OwnershipMetricModal
                        onPatchAdditionalInformation={onPatchAdditionalInformation}
                    />
                )
            }
        </div>
    )
}

export default Shareholders;
