import {TTermTypes} from "../TermsUpdate/utils";
import {HttpService} from "../../services/HttpService";

const baseURL = `${import.meta.env.VITE_CORPORA_SERVICE_URL}`;

const getContentByType = (type: TTermTypes) => {
    return HttpService.get(`api/public/term-policy/current-version/${type}`, {
        baseURL,
    });
}

export const PrivacyService = {
    getContentByType,
}