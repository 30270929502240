import { RadioButton } from 'primereact/radiobutton';
import { Tooltip } from 'primereact/tooltip';
import { classNames as cx, classNames } from 'primereact/utils';
import React from 'react';

import styles from '../styles.module.scss';

const Checkboxes = ({
  setSuppose,
  isSupposeExists,
}: {
  setSuppose: (isExist: boolean) => void;
  isSupposeExists: boolean;
}) => {
  return (
    <div className={classNames(styles.formHead)}>
      <div className={styles.headLabel}>
        <p>Do you have a spouse?</p>
        <span className={styles.tooltipWrapper}>
          <>
            <Tooltip target={`.spouse`} />
            <i
              data-pr-tooltip="If relevant, you can draft the 83(b) election on behalf of yourself and your spouse by selecting “Yes”.  Otherwise, select “No”."
              data-pr-position="left"
              className={cx(`spouse pi pi-info-circle`, styles.tooltip)}
            ></i>
          </>
        </span>
      </div>
      <div className={styles.checkboxes}>
        <div className={styles.itemWrapper}>
          <RadioButton
            inputId="field_2"
            name="pizza"
            value={true}
            onChange={(e) => setSuppose(e.value)}
            checked={isSupposeExists}
          />
          <label className={styles.checkboxLabel} htmlFor="field_2">
            Yes
          </label>
        </div>
        <div className={styles.itemWrapper}>
          <RadioButton
            inputId="field_1"
            name="pizza"
            value={false}
            onChange={(e) => setSuppose(e.value)}
            checked={!isSupposeExists}
          />
          <label className={styles.checkboxLabel} htmlFor="field_1">
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default Checkboxes;
