import React, {useState} from 'react';
import {useMutation, useQuery} from "@tanstack/react-query";
import usePagination from "../../hooks/usePagination";
import {useToast} from "../../hooks/useToast";
import generateUrlParams from "../../helpers/generateUrlParams";
import {TermsService} from "./TermsService";
import Loading from "../../components/Loading/Loading";
import {classNames} from "primereact/utils";
import sharedStyles from "../../assets/sharedStyles.module.scss";
import ThElement from "../../uiComponents/TableTypeA/ThElement";
import moment from "moment";
import TdElement from "../../uiComponents/TableTypeA/TdElement";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import ReactPaginate from "react-paginate";
import {Dropdown} from "primereact/dropdown";
import {perPageOptions} from "../../config/config";
import DropdownWrapper from "../../uiComponents/DropdownWrapper/DropdownWrapper";
import {useNavigate} from "react-router-dom";
import styles from './termsUpdate.module.scss';
import {TermsKeysLabels, TTermTypes} from "./utils";
import {Nullable} from "primereact/ts-helpers";
import EditorResult from "../../components/HtmlEditor/EditorResult";
import {disableBodyScroll, enableBodyScroll} from "../../helpers/bodyScrollControllers";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {AxiosError} from "axios";

type TTermsVersionsData = {
    createdAt: string;
    forceAccept: boolean;
    id: number;
    version: string;
    type: TTermTypes;
}

type TViewTermInfo = {
    createdAt: string;
    forceAccept: boolean;
    id: number;
    text: string;
    type: TTermTypes;
    version: string;
}

const TermsVersions = () => {
    const [viewTermInfo, setViewTermInfo] = useState<Nullable<TViewTermInfo>>(null);
    const { params, changePage, changePerPage } = usePagination();
    const { page, size} = params;
    const navigate = useNavigate();
    const { show } = useToast();

    const options = [
        {
            value: 'TERMS_FOR_83B',
            label: 'Terms for 83b',
        },
        {
            value: 'PRIVACY_POLICY',
            label: 'Privacy policy',
        },
        {
            value: 'TERMS_OF_SERVICE',
            label: 'Terms of Services',
        },
    ];

    const onSelect = (value: string) => {
        navigate(`/admin-terms/update/${value}`);
    }

    const { data: termsVersionsData, isLoading} = useQuery({
        queryKey: ['terms-versions', page, size],
        queryFn: () => TermsService.getTermsVersions(urlParams),
        gcTime: 0,
    });
    const termsVersions = termsVersionsData?.data?.content || [];
    const totalElements = termsVersionsData?.data?.totalElements || 0;
    const pageCount = Math.ceil(totalElements / size);

    const urlParams = generateUrlParams({
        page,
        size,
    });

    const getTermContent = useMutation({
        mutationFn: TermsService.getTermsItem,
        onSuccess: (res) => {
            setViewTermInfo(res?.data);
            disableBodyScroll();
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getTermContent.mutate,
                show: show,
                cb: () => {}
            };
            extractErrorMessage(errorData);
        },
    });
    const getTermContentLoading = getTermContent?.isPending;

    const getTermData = (id: number) => {
        getTermContent.mutate(id);
    }

    const closeModal = () => {
        enableBodyScroll();
        setViewTermInfo(null);
    }

    return (
        <div className={styles.termsVersions}>
            {
                Boolean(viewTermInfo) && (
                    <div className={styles.termViewModalOverlay}>
                        <div className={styles.modal}>
                            <button className={styles.closeBtn} onClick={closeModal}>
                                <i className="bi bi-x"/>
                            </button>
                            <div className={styles.scrollWrapper}>
                                <div className={styles.container}>
                                    <EditorResult
                                        content={viewTermInfo?.text as string}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={styles.header}>
                <DropdownWrapper
                    options={options}
                    onSelect={onSelect}
                    className={styles.dropdownCustom}
                >
                    <ButtonPrimary
                        className={styles.btnCustom}
                        label="Create new"
                    />
                </DropdownWrapper>
            </div>
            <div className={styles.tableHolder}>
                {
                    isLoading ? (
                        <div className={styles.loadingWrapper}>
                            <Loading/>
                        </div>
                    ) : (
                        <div className={styles.tableWrapper}>
                            <table className={classNames(sharedStyles.tableTypeA, styles.tableCustom, { [sharedStyles.loading]: getTermContentLoading })}>
                                <thead>
                                <tr className={sharedStyles.tableRow}>
                                    <ThElement label="Policy type"/>
                                    <ThElement label="Version"/>
                                    <ThElement label="Created Date"/>
                                    <ThElement/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    termsVersions.length === 0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <p className={sharedStyles.noTableRecords}>
                                                    No records found.
                                                </p>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {
                                                termsVersions.map((file: TTermsVersionsData, index: number) => {
                                                    const {
                                                        createdAt,
                                                        forceAccept,
                                                        id,
                                                        version,
                                                        type,
                                                    } = file;
                                                    const date = new Date(createdAt);
                                                    const formattedDate = createdAt
                                                        ? moment(date).format('MMM D, YYYY')
                                                        : '-';
                                                    return (
                                                        <tr className={classNames(sharedStyles.tableRow)} key={index}>
                                                            <TdElement value={TermsKeysLabels[type]}/>
                                                            <TdElement value={version}/>
                                                            <TdElement value={formattedDate}/>
                                                            <TdElement>
                                                                <div className={sharedStyles.buttonsWrapper}
                                                                     style={{justifyContent: 'flex-end'}}>
                                                                    <button type="button"
                                                                            className={styles.viewTermsBtn}
                                                                            onClick={() => getTermData(id)}>
                                                                        <i className="bi bi-bounding-box"/>
                                                                    </button>
                                                                </div>
                                                            </TdElement>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
            {
                termsVersions.length > 0 && (
                    <div className={sharedStyles.paginationControl}>
                        <div className={sharedStyles.perPageRow}>
                            {`Showing ${(page) * size + 1} to ${((page) * size) + termsVersions.length} of ${totalElements} entries`}
                        </div>
                        <div className={sharedStyles.changeControl}>
                            <ReactPaginate
                                className={sharedStyles.paginationWrapper}
                                breakLabel="..."
                                nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                onPageChange={({selected}) => changePage(selected)}
                                pageRangeDisplayed={size}
                                pageCount={pageCount}
                                previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                renderOnZeroPageCount={null}
                                pageLabelBuilder={(page) => (
                                    <div className={sharedStyles.pItem}>{page}</div>
                                )}
                                activeClassName={sharedStyles.pItemActive}
                                forcePage={page}
                            />
                            <div className={sharedStyles.perPageHolder}>
                                <Dropdown
                                    options={perPageOptions}
                                    value={size}
                                    onChange={(e) => changePerPage(e.value)}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default TermsVersions;
