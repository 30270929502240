import React, {FC, useMemo, useRef, useState} from "react";
import styles from "./dashboard.module.scss";
import sharedStyles from "../../assets/sharedStyles.module.scss";
import TdElement from "../../uiComponents/TableTypeA/TdElement";
import AvatarFrame from "../../uiComponents/AvatarFrame/AvatarFrame";
import RowDetails from "./RowDetails";
import {useMutation, useQuery} from "@tanstack/react-query";
import {requests} from "../../services/http-common";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from 'primereact/checkbox';
import ReactPaginate from "react-paginate";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import {formatToStandardDateUTC,} from "../../helpers/convertDates";
import {perPageOptions, successToastLife,} from "../../config/config";
import {TFile83bData} from "../../@types/global";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";
import TableLoading from "./TableLoading";
import RowActions from "./RowActions";
import usePagination from "../../hooks/usePagination";
import StatusPill from "../../uiComponents/StatusPill/StatusPill";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import {Nullable} from "primereact/ts-helpers";
import ThElement from "../../uiComponents/TableTypeA/ThElement";
import MultiSelectWrapper from "../../uiComponents/MultiSelectWrapper/MultiSelectWrapper";
import {classNames} from "primereact/utils";
import CalendarFilter, {TRangeCalendarValue} from "./CalendarFilter";
import moment from "moment";
import ConfirmDialogCustom from "../../uiComponents/ConfirmDianlogCustom/ConfirmDialogCustom";
import deleteIcon from '../../assets/images/delete-icon.svg';
import FieldWrapper from "../../uiComponents/FieldWrapper/FieldWrapper";
import {InputText} from "primereact/inputtext";
import useBulkSelect from "../../hooks/useBulkSelect";
import TooltipAndLabel from "../../uiComponents/Tooltip/Tooltip";

type TCustomFilters = {
    statuses: string[];
    grantDateStart: Nullable<string>;
    grantDateEnd: Nullable<string>;
    deadLineStart: Nullable<string>;
    deadLineEnd: Nullable<string>;
}

const initialCustomFilters = {
    statuses: [],
    grantDateStart: null,
    grantDateEnd: null,
    deadLineStart: null,
    deadLineEnd: null,
}

type TCalendarDates = {
    grantDate: TRangeCalendarValue;
    deadline: TRangeCalendarValue;
}
const calendarDatesInitialValue = {
    grantDate: null,
    deadline: null,
};

interface TTableDataProps {
    companyId: number;
    refetchCountsByStatusData: () => void;
    hasSpouseFeature: boolean;
    email: string;
}

const convertToDate = (date?: Date) => {
    const dt = new Date(date as Date);
    return date ? moment(dt).format('YYYY-MM-DD') : "";
};

const TableData: FC<TTableDataProps> = ({
companyId,
refetchCountsByStatusData,
hasSpouseFeature,
email,
}) => {
    const [search, setSearch] = useState<string>('');
    const {params, changePage, changePerPage, onSort, isSorted} = usePagination();
    const [searchVal, setSearchVal] = useState('');
    const tempSearchKey = useRef('');
    const timeoutIdRef = useRef<NodeJS.Timeout>();
    const [calendarDates, setCalendarDates] = useState<TCalendarDates>(calendarDatesInitialValue);
    const [openedCalendarFilterKey, setOpenedCalendarFilterKey] = useState<string>('');
    const {grantDate, deadline} = calendarDates;
    const [filters, setFilters] = useState<TCustomFilters>(initialCustomFilters);
    const {
        statuses,
        grantDateStart,
        grantDateEnd,
        deadLineStart,
        deadLineEnd,
    } = filters;
    const {page, size, sort} = params;
    const [openedRowId, setOpenedRowId] = useState<number>(0);
    const [remindFileId, setRemindFileId] = useState(0);
    const [deleteFileId, setDeleteFileId] = useState(0);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const tableHolderRef = useRef<HTMLDivElement>(null);
    const {show} = useToast();
    const {
        bulkSelectMode,
        bulkSelectedIds,
        onBulkSelect,
        toggleBulkMode,
        resetBulkState,
    } = useBulkSelect();
    const [bulkRemindConfirmation, setBulkRemindConfirmation] = useState<boolean>(false);

    const {
        data: possibleStatuses
    } = useQuery({
        queryKey: ["possible-statuses", companyId],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/statuses`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
        retry: true,
    });
    const possibleStatusesOptions = (possibleStatuses || []).map((item: { code: string; label: string; }) => {
        return {
            value: item?.code,
            label: item?.label,
        }
    })

    const changeFirstLetter = (str: string, newLetter: string) => {
        if (!str) {
            throw new Error('Invalid input');
        }

        return newLetter + str.slice(1);
    }

    const filtersStr = useMemo(() => {
        const statusesFormatted = statuses.length > 0 ? statuses.map((str) => `statuses=${str}`) : [];
        const statusesStr = statuses.length > 0 ? `&${statusesFormatted.join("&")}` : "";
        const grantDateStartStr = grantDateStart ? `&grantDateStart=${grantDateStart}` : ``;
        const grantDateEndStr = grantDateEnd ? `&grantDateEnd=${grantDateEnd}` : grantDateStart ? `&grantDateEnd=${grantDateStart}` : '';
        const deadLineStartStr = deadLineStart ? `&deadLineStart=${deadLineStart}` : ``;
        const deadLineEndStr = deadLineEnd ? `&deadLineEnd=${deadLineEnd}` : deadLineStart ? `&deadLineEnd=${deadLineStart}` : '';
        return `${statusesStr}${grantDateStartStr}${grantDateEndStr}${deadLineStartStr}${deadLineEndStr}`;
    }, [
        statuses,
        grantDateStart,
        grantDateEnd,
        deadLineStart,
        deadLineEnd,
    ]);
    const exportCsvParams = filtersStr ? changeFirstLetter(filtersStr, '?') : '';
    const exportCSV = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/companies/${companyId}/file83b/export${exportCsvParams}`,
                {}
            );
        },
        onSuccess: (csvStr) => {
            const blob = new Blob([csvStr], {type: "text/csv"});
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "data.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: exportCSV.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const onExportCSV = () => {
        exportCSV.mutate();
    }

    const archive = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/companies/${companyId}/file83b/archive${exportCsvParams}`,
                {}
            );
        },
        onSuccess: () => {
            const successMessage = `You will receive an email at ${email} with a ZIP file of the PDFs.`
            show({
                severity: 'success',
                detail: successMessage,
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: exportCSV.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const archiveIsLoading = archive?.isPending;
    const onArchive = () => {
        archive.mutate();
    }

    const searchUrl = useMemo(() => {
        const searchStr = search ? `query=${search}&` : ``;
        const pageStr = `page=${page}&size=${size}`;
        const sortFormatted =
            sort.length > 0 ? sort.map((str) => `sort=${str}`) : [];
        const sortStr = sort.length > 0 ? `&${sortFormatted.join("&")}` : "";
        const concatUrl = `${pageStr}${sortStr}`;
        return `?${searchStr}${concatUrl}${filtersStr}`;
    }, [
        page,
        size,
        sort,
        filtersStr,
        search,
    ]);

    const {
        data: filesData,
        isLoading: filesDataLoading,
        refetch,
    } = useQuery({
        queryKey: [
            "all-users",
            page,
            size,
            sort.join(""),
            statuses.join(''),
            companyId,
            // grantDateStart,
            grantDateEnd,
            // deadLineStart,
            deadLineEnd,
            search,
        ],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/file83b${searchUrl}`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
        retry: true,
    });
    const totalElements = filesData?.totalElements || 0;
    const files = filesData?.content || [];
    const pageCount = Math.ceil(totalElements / size);
    const tableLoading = filesDataLoading;

    const remindRequest = useMutation({
        mutationFn: async (id: number) => {
            return await requests.post(
                `api/companies/${companyId}/file83b/remind/${id}`,
                params
            );
        },
        onSuccess: () => {
            show({
                severity: "success",
                detail: "Remind sent successfully!",
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: remindRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const remindLoading = remindRequest?.isPending;

    const deleteFileRequest = useMutation({
        mutationFn: async (fileId: number) => {
            return await requests.delete(
                `api/companies/${companyId}/file83b/${fileId}`,
            );
        },
        onSuccess: () => {
            refetchCountsByStatusData();
            if (page > 0 && files.length === 1) {
                setOpenedRowId(0);
                const onePageBack = page - 1;
                changePage(onePageBack);
            } else {
                refetch().then(() => {
                    setOpenedRowId(0);
                });
            }
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: deleteFileRequest.mutate,
                show: show,
                cb: () => {
                    setDeleteFileId(0);
                }
            };
            extractErrorMessage(errorData);
        },
    });

    const toggleRow = (rowId: number) => {
        if (openedRowId === rowId) {
            setOpenedRowId(0);
        } else {
            setOpenedRowId(rowId);
        }
    };

    const redirectToTrackPage = (url: string) => {
        window.open(url, "_blank");
    };
    const remind = (id: number) => {
        setRemindFileId(id);
    };
    const acceptRemind = () => {
        remindRequest.mutate(remindFileId);
    };
    const acceptFileDelete = () => {
        deleteFileRequest.mutate(deleteFileId);
    };

    const handleMultiOnSelect = (value: string[]) => {
        setFilters((prev) => {
            return {
                ...prev,
                statuses: value,
            }
        });
    };
    const applyDateFilters = (data: TCalendarDates) => {
        // @ts-ignore
        const deadLineEnd = data?.deadline?.[1];
        // @ts-ignore
        const deadLineStart = data?.deadline?.[0];
        // @ts-ignore
        const grantDateEnd = data?.grantDate?.[1];
        // @ts-ignore
        const grantDateStart = data?.grantDate?.[0];
        const payload = {
            deadLineEnd: deadLineEnd ? convertToDate(deadLineEnd) : null,
            deadLineStart: deadLineStart ? convertToDate(deadLineStart) : null,
            grantDateEnd: grantDateEnd ? convertToDate(grantDateEnd) : null,
            grantDateStart: grantDateStart ? convertToDate(grantDateStart) : null,
        }
        setFilters((prev) => ({
            ...prev,
            ...payload,
        }));
        refetch();
    }
    const handleDateChange = (name: string, newValue: TRangeCalendarValue) => {
        let newObj: TCalendarDates = calendarDatesInitialValue;
        setCalendarDates((prev) => {
            newObj = {
                ...prev,
                [name]: newValue,
            };
            return (
                {
                    ...prev,
                    [name]: newValue,
                }
            );
        });
        applyDateFilters(newObj);
    }
    const handleCalendarClick = (key: string, value?: string) => {
        if (openedCalendarFilterKey === key) {
            setOpenedCalendarFilterKey('');
        } else {
            const vl = value ? value : key;
            setOpenedCalendarFilterKey(vl);
        }
    }
    const scrollTop = () => {
        if (tableHolderRef.current) {
            tableHolderRef.current.scrollIntoView();
        }
    };
    const onPageChange = ({selected}: { selected: number }) => {
        scrollTop();
        changePage(selected);
    };
    const setSearchTerm = (term: string) => {
        tempSearchKey.current = term;
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(
            () => {
                changePage(0);
                setSearch(tempSearchKey.current);
            }, 600
        );
    }

    const bulkRemindRequest = useMutation({
        mutationFn: async (params: number[]) => {
            return await requests.post(
                `api/companies/${companyId}/file83b/remind`,
                {ids: params}
            );
        },
        onSuccess: () => {
            resetBulkState();
            show({
                severity: "success",
                detail: "Remind sent to selected users successfully",
                life: successToastLife,
            });
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            resetBulkState();
            const errorData = {
                error: error,
                variables: variables,
                retryFn: remindRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const bulkRemindLoading = bulkRemindRequest?.isPending;

    const onBulkRemind = () => {
        bulkRemindRequest.mutate(bulkSelectedIds);
    }
    const openBulkRemindConfirmation = () => {
        setBulkRemindConfirmation(true);
    }
    const isZeroRecords = files.length === 0;
    return (
        <div className={styles.tableHolder} ref={tableHolderRef}>
            <div style={{display: 'flex', justifyContent: 'space-between'}} className={styles.tableHeadControl}>
                <div className={styles.headItem}>
                    <FieldWrapper isSearch>
                        <InputText
                            value={searchVal}
                            placeholder="Search User or Grant ID"
                            onChange={(e) => {
                                const newVal = e.target.value;
                                setSearchVal(newVal);
                                setSearchTerm(newVal);
                            }}
                        />
                    </FieldWrapper>
                </div>
                <div className={styles.headItem}>
                    {
                        bulkSelectMode && (
                            <ButtonPrimary
                                onClick={openBulkRemindConfirmation}
                                label="Send bulk reminder"
                                className={styles.actBtn}
                                disabled={bulkSelectedIds?.length === 0 || bulkRemindLoading}
                            />
                        )
                    }
                    <ButtonPrimary
                        onClick={onExportCSV}
                        label="Export data"
                        className={styles.actBtn}
                    />
                    <ButtonPrimary
                        onClick={onArchive}
                        label="Download PDFs"
                        style={{marginBottom: '12px'}}
                        className={styles.actBtn}
                        disabled={archiveIsLoading}
                    />
                    <button className={classNames(styles.bulkSelectModeBtn, {
                        [styles.btnActive]: bulkSelectMode,
                    })} onClick={toggleBulkMode}>
                        <i className="bi bi-pencil-square"></i>
                    </button>
                </div>
            </div>
            <div style={{width: "100%"}}>
                <table className={classNames(sharedStyles.tableTypeA, styles.customTable, {
                    [styles.tableLoading]: bulkRemindLoading
                })}>
                    <thead>
                    <tr className={sharedStyles.tableRow}>
                        <ThElement
                            columnName="companyUser.user.name"
                            label="User"
                            onSort={onSort}
                            isAscSorted={isSorted('companyUser.user.name', sort)?.isAscSorted}
                            isDescSorted={isSorted('companyUser.user.name', sort)?.isDescSorted}
                            colSpan={2}
                        >
                        </ThElement>
                        <ThElement
                            columnName="grantDate"
                            isFiltered={Boolean(grantDateStart) || Boolean(grantDateEnd)}
                        >
                            <CalendarFilter
                                name="grantDate"
                                dates={grantDate}
                                handleDateChange={handleDateChange}
                                placement="bottom-start"
                                isOpenedOutside={openedCalendarFilterKey === 'grantDate'}
                                handleClick={handleCalendarClick}
                                onClose={() => setOpenedCalendarFilterKey('')}
                            >
                                <>
                                    <span className={sharedStyles.headLabel}>Grant Date</span>
                                    <i className={classNames('bi bi-filter', sharedStyles.sortIcon)}/>
                                </>
                            </CalendarFilter>
                        </ThElement>
                        <ThElement
                            columnName="deadline"
                            isFiltered={Boolean(deadLineStart) || Boolean(deadLineEnd)}
                        >
                            <CalendarFilter
                                name="deadline"
                                dates={deadline}
                                handleDateChange={handleDateChange}
                                isOpenedOutside={openedCalendarFilterKey === 'deadline'}
                                handleClick={handleCalendarClick}
                                onClose={() => setOpenedCalendarFilterKey('')}
                            >
                                <>
                                    <span className={sharedStyles.headLabel}>Deadline</span>
                                    <i className={classNames('bi bi-filter', sharedStyles.sortIcon)}/>
                                </>
                            </CalendarFilter>
                        </ThElement>
                        <ThElement
                            columnName="status"
                            label="Status"
                            isFiltered={statuses.length > 0}
                        >
                            <MultiSelectWrapper
                                value={statuses}
                                options={possibleStatusesOptions}
                                onSelect={handleMultiOnSelect}
                                name="statuses"
                            >
                                <>
                                    <span className={sharedStyles.headLabel}>Status</span>
                                    <i className={classNames('bi bi-filter', sharedStyles.sortIcon)}/>
                                </>
                            </MultiSelectWrapper>
                        </ThElement>
                        <ThElement
                            columnName="updateDateTime"
                            label="Latest update"
                            onSort={onSort}
                            isAscSorted={isSorted('updateDateTime', sort)?.isAscSorted}
                            isDescSorted={isSorted('updateDateTime', sort)?.isDescSorted}
                        >
                        </ThElement>
                        <ThElement columnName=""/>
                    </tr>
                    </thead>
                    <tbody ref={tableBodyRef}>
                    {tableLoading ? (
                        <TableLoading/>
                    ) : (
                        <>
                            {
                                isZeroRecords ? (
                                    <tr>
                                        <td colSpan={7}>
                                            <p className={sharedStyles.noTableRecords}>
                                                No records found.
                                            </p>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {files.map((item: TFile83bData, index: number) => {
                                            const {
                                                activities,
                                                documents,
                                                deadLine,
                                                name,
                                                grantDate,
                                                id,
                                                status,
                                                trackingUrl,
                                                allowRemind,
                                                allowRemove,
                                                orderNumber,
                                                grantId,
                                                trackingNumber,
                                                cutOffPast,
                                                deadlinePast,
                                                updateDateTime,
                                            } = item;
                                            const isRowOpened = openedRowId === id;
                                            const isSelected = Boolean(bulkSelectedIds.find((selectedId) => selectedId === id));
                                            const isOnlyCutOffPast = cutOffPast && !deadlinePast;
                                            const isDeadlinePast = cutOffPast && deadlinePast;
                                            const deadlineColor = isOnlyCutOffPast ? 'orange' : isDeadlinePast ? 'red' : '';
                                            const txtCutOffPast = `The cut-off to file your 83(b) election with Corpora has passed, but you can still file it manually yourself within 30 days of the grant date.`;
                                            const txtDeadlinePast = `The deadline to file your 83(b) election has passed. We suggest consulting with your legal or tax advisor for any next steps.`;
                                            const toolTipText = isOnlyCutOffPast ? txtCutOffPast : isDeadlinePast ? txtDeadlinePast : '';
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr className={sharedStyles.tableRow}>
                                                        <TdElement className={styles.paddingLeft} colSpan={2}>
                                                            {
                                                                bulkSelectMode && (
                                                                    <Checkbox
                                                                        className={styles.checkbox}
                                                                        onChange={() => onBulkSelect(id)}
                                                                        checked={isSelected}
                                                                    />
                                                                )
                                                            }
                                                            <ToggleButton
                                                                isRowOpened={isRowOpened}
                                                                onClickHandler={() => toggleRow(id)}
                                                            />
                                                            <div style={{marginRight: "12px"}}>
                                                                <AvatarFrame fullName={name}/>
                                                            </div>
                                                            <p className={sharedStyles.dataLabel}>{name}</p>
                                                        </TdElement>
                                                        <TdElement value={formatToStandardDateUTC(grantDate, ' ')}/>
                                                        <TdElement>
                                                            <p
                                                                className={sharedStyles.dataLabel}
                                                                style={{ color: deadlineColor }}
                                                            >
                                                                {formatToStandardDateUTC(deadLine, ' ')}
                                                            </p>
                                                            {
                                                                toolTipText && (
                                                                    <TooltipAndLabel
                                                                        tooltipMessage={toolTipText}
                                                                    />
                                                                )
                                                            }
                                                        </TdElement>
                                                        <TdElement>
                                                            <StatusPill status={status}/>
                                                        </TdElement>
                                                        <TdElement
                                                            value={formatToStandardDateUTC(updateDateTime)}
                                                        />
                                                        <TdElement>
                                                            <RowActions
                                                                redirectToTrackPage={redirectToTrackPage}
                                                                remind={remind}
                                                                allowRemind={allowRemind}
                                                                uspsUrl={trackingUrl}
                                                                id={id}
                                                                remindLoading={remindLoading}
                                                            />
                                                        </TdElement>
                                                    </tr>
                                                    {isRowOpened && (
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <div className={styles.toggleTopRow}>
                                                                    <div className={styles.grantDetails}>
                                                                        {
                                                                            grantId && (
                                                                                <p className={styles.orderNumber}>
                                                                                    {`Grant ID: `}
                                                                                    <span>{grantId}</span>
                                                                                </p>
                                                                            )
                                                                        }
                                                                        {
                                                                            orderNumber && (
                                                                                <p className={styles.orderNumber}>
                                                                                    {`Order ID: `}
                                                                                    <span>{orderNumber}</span>
                                                                                </p>
                                                                            )
                                                                        }
                                                                        {
                                                                            trackingNumber && (
                                                                                <p className={styles.orderNumber}>
                                                                                    {`FedEx Tracking Number: `}
                                                                                    <span>{trackingNumber}</span>
                                                                                </p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        allowRemove && (
                                                                            <ButtonPrimary
                                                                                label="Remove"
                                                                                styleType="primaryOutline"
                                                                                onClick={() => setDeleteFileId(id)}
                                                                                isDanger
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                                <RowDetails
                                                                    activities={activities}
                                                                    documents={documents}
                                                                    companyId={companyId}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                )
                            }
                        </>
                    )}
                    </tbody>
                </table>
            </div>
            {
                !isZeroRecords && (
                    <div className={sharedStyles.paginationControl}>
                        <div className={sharedStyles.perPageRow} style={{...(tableLoading ? {opacity: 0} : {})}}>
                            {`Showing ${page * size + 1} to ${
                                page * size + files.length
                            } of ${totalElements} entries`}
                        </div>
                        <div className={sharedStyles.changeControl}>
                            <ReactPaginate
                                className={sharedStyles.paginationWrapper}
                                breakLabel="..."
                                nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                onPageChange={onPageChange}
                                pageRangeDisplayed={size}
                                pageCount={pageCount}
                                previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                renderOnZeroPageCount={null}
                                pageLabelBuilder={(page) => (
                                    <div className={sharedStyles.pItem}>{page}</div>
                                )}
                                activeClassName={sharedStyles.pItemActive}
                                forcePage={page}
                            />
                            <div className={sharedStyles.perPageHolder}>
                                <Dropdown
                                    options={perPageOptions}
                                    value={size}
                                    onChange={(e) => {
                                        changePerPage(e.value);
                                        scrollTop();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            <ConfirmDialogCustom
                header="Remind to sign"
                acceptLabel="Proceed"
                rejectLabel="Cancel"
                message={
                    hasSpouseFeature
                        ? `You’re about to send a reminder email to the signer(s) of this 83 election to sign it. Would you like to proceed?`
                        : `You're about to send a reminder email to this user to review and sign their 83(b) election.  Would you like to proceed?`
                }
                visible={Boolean(remindFileId)}
                onHide={() => setRemindFileId(0)}
                accept={acceptRemind}
            />
            <ConfirmDialogCustom
                header="Deleting a record"
                acceptLabel="Proceed"
                rejectLabel="Cancel"
                message="You are about to delete this record.  Do you wish to continue?"
                visible={Boolean(deleteFileId)}
                onHide={() => setDeleteFileId(0)}
                accept={acceptFileDelete}
                iconSrc={deleteIcon}
            />
            <ConfirmDialogCustom
                header="Remind to sign"
                acceptLabel="Proceed"
                rejectLabel="Cancel"
                message="You’re about to send an email to the signer(s) of the selected 83 election(s) reminding them to sign. Would you like to proceed?"
                visible={bulkRemindConfirmation}
                onHide={() => setBulkRemindConfirmation(false)}
                accept={onBulkRemind}
            />
        </div>
    );
};

export default TableData;
